import { DEFAULT_TAKE } from '../constants'
import { IApiClientGetFilter, IApiClientListFilter } from '../interfaces'
import { byAanvoerderNummerSort, getStringValue } from '../lib'
import { snackbarUtils } from '../utils'
import { IAanvoerder, IClient } from './client'

export const getAanvoerderAsync = async (
  apiClient: IClient,
  apiClientFilter: Partial<IApiClientGetFilter>
): Promise<Partial<IAanvoerder>> => {
  try {
    const result = (await apiClient.aanvoerders_Aanvoerder_GetAanvoerder2(
      apiClientFilter.key,
      undefined,
      undefined
    )) as any

    const cleanedResult: Partial<IAanvoerder> =
      process.env.REACT_APP_MOCK_API === 'true'
        ? result
        : result?.cleanedResponse

    return {
      aanvoerderNummer: cleanedResult.aanvoerderNummer,
      aanvoerderNaam: cleanedResult.aanvoerderNaam,
    }
  } catch (error: any) {
    snackbarUtils.error(`Aanvoerder ${apiClientFilter.key} niet gevonden`)
  }
}

export const getAanvoerdersAsync = async (
  apiClient: IClient,
  apiClientFilter: Partial<IApiClientListFilter>
): Promise<Partial<IAanvoerder>[]> => {
  try {
    const result = (await apiClient.aanvoerders_Aanvoerder_ListAanvoerder(
      DEFAULT_TAKE,
      undefined,
      undefined,
      apiClientFilter.filter,
      undefined,
      undefined,
      undefined,
      undefined
    )) as any

    const records: Partial<IAanvoerder>[] = result?.cleanedResponse?.value
    records.sort(byAanvoerderNummerSort)
    return records
  } catch (error: any) {
    snackbarUtils.error(getStringValue(error))
  }
}
