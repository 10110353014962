import { inputBaseClasses } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import './CustomDatepicker.css'

type CustomDatePickerProps = {
  label?: string
  value?: Date
  onChange: (date: Date) => void
  shouldDisableDate?: (newValue: Date) => boolean
  disablePast?: boolean
}

export default function CustomDatePicker({
  label = undefined,
  value,
  onChange,
  shouldDisableDate,
  disablePast,
}: Readonly<CustomDatePickerProps>): JSX.Element {
  return (
    <DatePicker
      disablePast={disablePast}
      label={label}
      onChange={onChange}
      shouldDisableDate={shouldDisableDate}
      value={value}
      slotProps={{
        textField: {
          sx: {
            [`.${inputBaseClasses.root}`]: {
              bgcolor: RfhColors.white,
              border: `1px solid ${RfhColors.lightGrey}`,
              pl: '0.5em',
              pr: '0.25em',
              '& fieldset': { border: 'none' },
            },
          },
        },
      }}
    />
  )
}
