import { FC, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/system/Box'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { Divider } from 'src/common/components'

type RuleTextFieldProps = {
  rule: string
}

const RuleTextField: FC<RuleTextFieldProps> = forwardRef(({ rule }, ref) => {
  const paperColor = RfhColors.white
  const { t } = useTranslation()

  return (
    <Paper elevation={10} square={false} sx={{ background: paperColor }}>
      <Box p={3} ref={ref}>
        <Grid container flexDirection={'column'}>
          <Grid item xs={12}>
            <Typography variant={'subheader'}>
              {t('auctionGroupAllocation.auctionGroupRuleModalTitle')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider {...{ marginTop: 16 }} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              whiteSpace: 'pre-line',
              wordWrap: 'break-word',
            }}
          >
            <Typography variant={'body-copy'}>{rule}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
})

export default RuleTextField
