import { useEffect, useState } from 'react'

import { Box, Paper, Table, TableBody, TableContainer } from '@mui/material'

import { ConnectivityIndicator } from 'src/common/components'
import { VestigingCode } from 'src/common/enums'
import { useWebSocket } from 'src/common/hooks'

import ClocksStatusTableHeader from './ClocksStatusTableHeader'
import ClocksStatusTableRow from './ClocksStatusTableRow'
import type { ClockStatusData } from './lib'
import rows from './mocks/data.json'

import { v4 as getRandomID } from 'uuid'

type Rows = ClockStatusData[]

export default function ClocksStatusGrid(): JSX.Element {
  const [statusData, setStatusData] = useState<Rows>(rows as Rows)
  const { wsData, connectionStatus } = useWebSocket<Rows>('klokstatus')

  useEffect(() => {
    if (wsData) {
      setStatusData(wsData)
    }
  }, [wsData])

  return (
    <Box position={'relative'}>
      <TableContainer
        component={Paper}
        elevation={8}
        style={{ borderRadius: '5px', marginBottom: '2em', overflowX: 'auto' }}
      >
        <Table
          aria-label='klokkenstatus'
          style={{ tableLayout: 'fixed', width: '100%', maxWidth: '100%' }}
        >
          <ClocksStatusTableHeader />
          <TableBody>
            {[
              VestigingCode.Aalsmeer,
              VestigingCode.Naaldwijk,
              VestigingCode.Rijnsburg,
              VestigingCode.Eelde,
              VestigingCode.VRM,
              VestigingCode.Concern,
            ].map(vestigingCode => {
              const rowsForVestiging = statusData.filter(
                r => r.vestigingCode === vestigingCode
              )
              const numberOfRows = rowsForVestiging.length
              return rowsForVestiging.map(
                (row: ClockStatusData, index: number) => (
                  <ClocksStatusTableRow
                    key={getRandomID()}
                    index={index}
                    row={row}
                    mainRow={index === numberOfRows - 1}
                  />
                )
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ConnectivityIndicator
        status={connectionStatus}
        sx={{ top: '5px', right: '33%', width: '16px' }}
      />
    </Box>
  )
}
