import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, NativeSelect, SxProps } from '@mui/material'
import { RfhTypography } from '@rfh/ui'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { ELEMENT_HEIGHT } from '../constants'
import { Location } from '../enums'
import { IAllSearchFilterFields } from '../interfaces'
import { LocationsListByEnum } from './LocationsListByEnum'

type AvailableLocationsProps = {
  handleChange: (
    name: keyof IAllSearchFilterFields,
    value: string,
    storeLocal?: boolean
  ) => void
  changeValid?: (value: boolean) => void
  labelVisible?: boolean
  required?: boolean
  inputValue: number
  sx?: SxProps
}

export default function AvailableLocations({
  handleChange,
  changeValid,
  labelVisible,
  required,
  inputValue,
  sx,
}: Readonly<AvailableLocationsProps>): JSX.Element {
  const { t } = useTranslation()

  const onClickSelectItemOrClear = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    if (!value || value === '') {
      handleChange('vestigingCode', undefined)
      if (changeValid) {
        changeValid(false)
      }
    } else {
      handleChange('vestigingCode', `${value}`)
      if (changeValid) {
        changeValid(true)
      }
    }
  }

  return (
    <FormControl sx={sx}>
      {labelVisible && (
        <RfhTypography variant={'subheader'}>
          {required
            ? `${t('common.auctionLocation')} *`
            : t('common.auctionLocation')}
        </RfhTypography>
      )}

      <NativeSelect
        required={required}
        data-testid='location'
        value={inputValue ? `${inputValue}` : ''}
        onChange={onClickSelectItemOrClear}
        sx={{
          marginTop: 2.1,
          height: ELEMENT_HEIGHT,
          bgcolor: RfhColors.white,
          'svg.MuiNativeSelect-icon': {
            pointerEvents: 'none',
          },
        }}
      >
        <LocationsListByEnum enumType={Location} />
      </NativeSelect>
    </FormControl>
  )
}
