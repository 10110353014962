import { ReactNode } from 'react'

import { FormControl, FormLabel } from '@mui/material'
import Grid from '@mui/material/Grid'

import { ThemeConfig } from 'src/common/config'

type FormInputProps = {
  label: string
  id: string
  input: ReactNode
}

export default function FormInput({
  label,
  input,
  id,
}: Readonly<FormInputProps>): JSX.Element {
  return (
    <Grid
      container
      sx={{ margin: ThemeConfig.spacing.xs }}
      alignItems={'center'}
    >
      <Grid item xs={12} sm={4}>
        <FormLabel htmlFor={id}>{label}</FormLabel>
      </Grid>
      <Grid item xs={12} sm={8}>
        <FormControl
          sx={{
            margin: 0,
            width: '100%',
          }}
          id={id}
        >
          {input}
        </FormControl>
      </Grid>
    </Grid>
  )
}
