import { useEffect } from 'react'

import { useStore } from 'zustand'

import { buildVersion } from 'src/common/config/buildVersion.json'

import * as packageJson from '../../../package.json'
import { featuresList } from '../config'
import { useDevToolsStore } from '../stores'

type UseDevToolsReturn = {
  forceMajeur: boolean
  initFeatures: () => void
  version: string
}

const useDevTools = (): UseDevToolsReturn => {
  const { features, setFeatures } = useStore(useDevToolsStore, state => ({
    features: state.features,
    setFeatures: state.setFeatures,
  }))
  const forceMajeur = window.sessionStorage.getItem('dev-tools') === 'false'
  const version = getBuildVersion()

  function initFeatures() {
    const initState = { ...features }
    const keys = Object.keys(initState)
    featuresList.forEach(featureName => {
      if (!keys.includes(featureName)) {
        initState[featureName] = false
      }
      keys.forEach(key => {
        if (!featuresList.includes(key)) {
          delete initState[key]
        }
      })
    })
    setFeatures(initState, true) // hard reset
  }

  useEffect(() => {
    initFeatures()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { forceMajeur, initFeatures, version }
}

export default useDevTools

function getBuildVersion(): string {
  const devVersion = packageJson.version
  const prdVersion = packageJson.version + (buildVersion || '')
  const version =
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
      ? devVersion
      : prdVersion
  return version
}
