import { isArray } from './isOfType'

import { v4 as getRandomID } from 'uuid'

interface ILocalStorageItem {
  id: number
}

interface ISessionStorageItem {
  id: number
}

export interface IOfflineEvent {
  id: string
  name: string
  args: any[]
}

/**
 * Check if the user has an active internet connection.
 */
export const isOnline = (): boolean => navigator.onLine

/**
 * Get an item stored in the offline store.
 * @param key The key with which the item is saved
 * @param id Get the item by id. This will be ignored when the stored data is not an array
 */
export function getLocalItem<T>(key: string, id?: number | string): T {
  const item: any = JSON.parse(localStorage.getItem(key))

  return id && isArray(item)
    ? item.find(
        (i: Partial<ILocalStorageItem>): boolean =>
          i.id === parseInt(id as string, 10)
      )
    : item
}

/**
 * Get an item stored in the offline store.
 * @param key The key with which the item is saved
 * @param id Get the item by id. This will be ignored when the stored data is not an array
 */
export function getSessionItem<T>(key: string, id?: number | string): T {
  const item: any = JSON.parse(sessionStorage.getItem(key))

  return id && isArray(item)
    ? item.find(
        (i: Partial<ISessionStorageItem>): boolean =>
          i.id === parseInt(id as string, 10)
      )
    : item
}

/**
 * Store an item in the offline store.
 * @param key The key with which the item is saved
 * @param item Item to be stored
 */
export function setLocalItem<T>(key: string, item: T): T {
  localStorage.setItem(key, JSON.stringify(item))

  return item
}

/**
 * Store an item in the offline store.
 * @param key The key with which the item is saved
 * @param item Item to be stored
 */
export function setSessionItem<T>(key: string, item: T): T {
  sessionStorage.setItem(key, JSON.stringify(item))

  return item
}

/**
 * Add an item to the offline store.
 * @param key The key with which the item is saved
 * @param item Item to be stored
 */
export function addLocalItem<T>(key: string, item: T): T {
  let localItem: any = getLocalItem<T>(key)

  localItem = isArray(localItem) ? [...localItem, item] : item

  setLocalItem<T>(key, localItem)

  return item
}

/**
 * Add an item to the offline store.
 * @param key The key with which the item is saved
 * @param item Item to be stored
 */
export function addSessionItem<T>(key: string, item: T): T {
  let sessionItem: any = getSessionItem<T>(key)

  sessionItem = isArray(sessionItem) ? [...sessionItem, item] : item

  setSessionItem<T>(key, sessionItem)

  return item
}

/**
 * Update an item in the offline store.
 * @param key The key with which the item is saved
 * @param item Item to be stored
 * @param id Update a item by id. This will be ignored when the stored data is not an array
 */
export function updateLocalItem<T>(
  key: string,
  item: T,
  id?: string | number
): T {
  let localItem: any = getLocalItem<T>(key)

  localItem =
    id && isArray(localItem)
      ? localItem.map(
          (i: Partial<ILocalStorageItem>): Partial<ILocalStorageItem> =>
            i.id === parseInt(id as string, 10) ? item : i
        )
      : item

  setLocalItem<T>(key, localItem)

  return item
}

export function updateSessionItem<T>(
  key: string,
  item: T,
  id?: string | number
): T {
  let sessionItem: any = getSessionItem<T>(key)

  sessionItem =
    id && isArray(sessionItem)
      ? sessionItem.map(
          (i: Partial<ISessionStorageItem>): Partial<ISessionStorageItem> =>
            i.id === parseInt(id as string, 10) ? item : i
        )
      : item

  setSessionItem<T>(key, sessionItem)

  return item
}

/**
 * Remove item from the offline store.
 * @param key The key with which the item is saved
 * @param id Get a item by id. This will be ignored when the stored data is not an array
 */
export function removeLocalItem<T>(
  key: string,
  id?: number | string
): T | void {
  let item: any = getLocalItem<T>(key)

  if (id && isArray(item)) {
    item = item.filter((i: Partial<ILocalStorageItem>): boolean => i.id !== id)
    return setLocalItem<T>(key, item)
  }

  return localStorage.removeItem(key)
}

/**
 * Remove item from the offline store.
 * @param key The key with which the item is saved
 * @param id Get a item by id. This will be ignored when the stored data is not an array
 */
export function removeSessionItem<T>(
  key: string,
  id?: number | string
): T | void {
  let item: any = getSessionItem<T>(key)

  if (id && isArray(item)) {
    item = item.filter(
      (i: Partial<ISessionStorageItem>): boolean => i.id !== id
    )
    return setSessionItem<T>(key, item)
  }

  return sessionStorage.removeItem(key)
}

/**
 * Add an event to be processed when the user will be back online.
 * @param name Name of the event, preferably the name of the function to be called
 * @param args Any arguments which the function needs to be executed with
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function addOfflineEvent(name: string, ...args: any): void {
  const event: IOfflineEvent = {
    id: getRandomID(),
    name,
    args: [...args],
  }

  addLocalItem<IOfflineEvent>('offlineEvents', event)
}
