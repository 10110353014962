import { ChangeEvent, useEffect, useState } from 'react'

import { Checkbox, TableCell, TableRow } from '@mui/material'

import { Item } from 'src/common/types'

type MultiSelectItemProps = {
  item: Item<number>
  changeChecked: (item: Item<number>) => void
}

export default function MultiSelectItem({
  item,
  changeChecked,
}: Readonly<MultiSelectItemProps>): JSX.Element {
  const [selected, setSelected] = useState<boolean>(false)

  useEffect(() => {
    setSelected(item.selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TableRow
      key={item.code}
      hover={true}
      onClick={handleRowClick}
      style={{ cursor: 'pointer' }}
    >
      <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
        {item.code}
      </TableCell>
      <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
        {item.name}
      </TableCell>
      <TableCell
        style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}
      >
        <Checkbox
          checked={selected}
          onChange={handleCheckboxClick}
          sx={{ mr: 0.5 }}
        />
      </TableCell>
    </TableRow>
  )

  function handleClick(checked: boolean) {
    setSelected(checked)
    item.selected = checked
    changeChecked(item)
  }

  function handleRowClick() {
    const checked = !selected
    handleClick(checked)
  }

  function handleCheckboxClick(event: ChangeEvent<HTMLInputElement>) {
    const checked = event.target.checked
    handleClick(checked)
  }
}
