import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'
import { DataGrid, LoadingIcon, RfhTypography } from '@rfh/ui'
import { Row } from '@rfh/ui/components/DataGrid/DataGrid'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import BooleanTag from 'src/common/components/BooleanTag'
import { StyledGridCard } from 'src/common/components/StyledGridCard'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { IAanvoerderProductgroepRegelmatigView } from 'src/common/services/client'
import type { ColumnsLayout } from 'src/common/types'
import { formatDate } from 'src/common/utils/DateFunctions'

import { v4 as getRandomID } from 'uuid'

type ProductGroupRegularityHistoryGridProps = {
  history: IAanvoerderProductgroepRegelmatigView[]
  isLoading: boolean
}

export default function ProductGroupRegularityHistoryGrid({
  history,
  isLoading,
}: Readonly<ProductGroupRegularityHistoryGridProps>): JSX.Element {
  const layout: ColumnsLayout[] = [
    { ratio: 0.6, align: 'right' },
    { ratio: 0.8, align: 'right' },
    { ratio: 0.8, align: 'right' },
    { ratio: 0.8, align: 'right' },
    { ratio: 0.8, align: 'right' },
    { ratio: 0.8, align: 'right' },
    { ratio: 1.5, align: 'left' },
    { ratio: 1.5, align: 'left' },
  ]

  /**
   * Hooks
   */
  const { t } = useTranslation()

  /*
    Methods
  */

  const transformToRow = (
    item: IAanvoerderProductgroepRegelmatigView
  ): Row => ({
    values: [
      formatDate(item.datumEindeWeek, t('common.dateFormatShort')),
      item.aantalDagenGeveild ?? 0,
      item.aantalDagenUitzondering ?? 0,
      <BooleanTag value={item.regelmatig} key={getRandomID()} />,
      item.aantalDagenUitzonderingWeek ?? 0,
      <BooleanTag
        value={item.uitzonderingRedenOmschrijving !== ''}
        key={getRandomID()}
      />,
      item.uitzonderingRedenOmschrijving,
      item.uitzonderingRedenTekst,
    ],
  })

  const noResultsFallback: Row[] = [
    {
      values: [t('common.noResults')],
    },
  ]
  const headerRow: Row = {
    values: [
      t('regularity.datumEindeWeek'),
      t('overviews.auctionDays'),
      t('overviews.exceptionDaysPerWeek'),
      t('overviews.regularity'),
      t('overviews.exceptionWeeks'),
      t('regularity.exceptionWeek'),
      t('regularity.uitzonderingRedenOmschrijving'),
      t('regularity.uitzonderingRedenTekst'),
    ],
    header: true,
  }

  const rows =
    history.length > 0 ? [...history].map(transformToRow) : noResultsFallback
  rows.unshift(headerRow)

  return (
    <Grid
      container
      sx={{
        marginLeft: 0,
        marginTop: ThemeConfig.spacing.xs,
        width: '100%',
        '& .MuiGrid-root.MuiGrid-item': {
          flexGrow: 1,
          maxWidth: '100%',
          paddingLeft: 0,
          '& > .MuiBox-root': {
            paddingLeft: ThemeConfig.spacing.s,
          },
        },
      }}
      spacing={2}
    >
      <Grid
        item
        xs={12}
        sx={{
          padding: '0 !important',
          my: ThemeConfig.spacing.sm,
        }}
      >
        <RfhTypography variant={'h2'} style={{ fontSize: '34px' }}>
          {t('common.titles.regularityHistory')}
        </RfhTypography>
      </Grid>
      {!isLoading ? (
        <StyledGridCard lg={10} xs={18} aligns={layout.map(l => l.align)}>
          <DataGrid
            headColor={RfhColors.leafGreen}
            label={t('common.titles.regularityHistory')}
            rows={rows}
            layout={layout.map(l => l.ratio)}
          />
        </StyledGridCard>
      ) : (
        <LoadingIcon />
      )}
    </Grid>
  )
}
