import { FC } from 'react'

import FormControl from '@mui/material/FormControl'
import TextField from '@rfh/ui/components/Inputs/TextField'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

type TextInputProps = {
  ariaLabel?: string
  label?: string
  name?: string
  onBlur?: any
  onChange?: any
  required?: boolean
  sx?: any
  value?: string
}

export const TextInput: FC<TextInputProps> = ({
  ariaLabel,
  label,
  name,
  onBlur,
  onChange,
  sx,
  required = false,
  value = '',
}) => (
  <FormControl>
    {label && <Typography variant={'subheader'}>{label}</Typography>}
    <TextField
      aria-label={ariaLabel}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      required={required}
      sx={{
        mt: 0.5,
        bgcolor: RfhColors.white,
        width: 240,
        maxWidth: 240,
        ...sx,
      }}
      value={value}
    />
  </FormControl>
)
