import Grid from '@mui/material/Grid'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { ELEMENT_HEIGHT } from 'src/common/constants'
import { noOp } from 'src/common/lib'

type LabelDateProps = {
  disabled?: boolean
  fieldName: string
  label: string
  value?: Date
  onChange?: (date: Date) => void
}

export default function LabelDate({
  disabled = false,
  fieldName,
  label,
  value = null,
  onChange = noOp,
}: Readonly<LabelDateProps>): JSX.Element {
  return (
    <Grid
      container
      item
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      <Grid item>
        <Typography
          aria-label={`id-${fieldName}`}
          variant={'body-copy'}
          sx={{
            color: RfhColors.black,
            whiteSpace: 'nowrap',
            minWidth: 170,
            py: 0,
          }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <DatePicker
          aria-label={`date picker for ${label.toLowerCase()}`}
          defaultValue={null}
          disabled={disabled}
          value={value}
          onChange={onChange}
          slotProps={{
            textField: {
              sx: {
                bgcolor: RfhColors.white,
                maxWidth: 160,
                svg: { color: RfhColors.darkOrange },
                '.MuiInputBase-root': { paddingX: 1 },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${RfhColors.lightGrey} !important`,
                },
              },
            },
          }}
          sx={{
            color: RfhColors.darkOrange,
            minHeight: ELEMENT_HEIGHT,
          }}
        />
      </Grid>
    </Grid>
  )
}
