import { useState } from 'react'

import { TableCell, TableRow } from '@mui/material'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { VestigingCode } from 'src/common/enums'
import { usePrevProps } from 'src/common/hooks'
import { BloemenPlanten } from 'src/common/types'

import type { LogisticsDashboardRowData } from './lib'
import { getCellStyles, getClockSort } from './lib'

import isEqual from 'lodash/isEqual'

type LogisticsDashboardGridRowProps = {
  row: LogisticsDashboardRowData
  index: number
}

export default function LogisticsDashboardGridRow({
  row,
  index,
}: Readonly<LogisticsDashboardGridRowProps>): JSX.Element {
  const prevRow = usePrevProps(row)
  const [updated, setUpdated] = useState(false)

  if (prevRow && !isEqual(prevRow, row) && !updated) {
    setUpdated(true)
    setTimeout(() => {
      setUpdated(false)
    }, 1000)
  }

  return (
    <TableRow
      key={index}
      style={{
        backgroundColor: index % 2 === 0 ? RfhColors.white : RfhColors.fogWhite,
        paddingRight: '-0.5em',
      }}
    >
      <TableCell style={getCellStyles('left')}>
        {VestigingCode[row.vestigingCode]}
      </TableCell>
      <TableCell style={getCellStyles('left')}>
        {getClockSort(row.klokSoort as BloemenPlanten)}
      </TableCell>
      <TableCell style={getCellStyles()}>{row.aantalStapelwagens}</TableCell>
      <TableCell style={getCellStyles()}>
        {row.aantalStapelwagensKowala}
      </TableCell>
      <TableCell style={getCellStyles()}>
        {Math.max(row.aantalStapelwagens - row.aantalStapelwagensKowala, 0)}
      </TableCell>
      <TableCell style={getCellStyles()}>
        {row.aantalStapelwagensScannenBijOpstellen}
      </TableCell>
      <TableCell style={getCellStyles()}>
        {Math.max(
          row.aantalStapelwagensKowala -
            row.aantalStapelwagensScannenBijOpstellen,
          0
        )}
      </TableCell>
    </TableRow>
  )
}
