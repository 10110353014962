import { ChangeEvent, useState } from 'react'

import { Checkbox, TableCell, TableRow } from '@mui/material'

import { IProductgroepenReport } from 'src/common/services/client'

type ProductGroupsItemProps = {
  changeChecked: (pg: IProductgroepenReport) => void
  productGroep: IProductgroepenReport
}

export default function ProductGroupsItem({
  changeChecked,
  productGroep,
}: Readonly<ProductGroupsItemProps>): JSX.Element {
  const [selected, setSelected] = useState<boolean>(
    productGroep?.productGroepGeselecteerd ?? false
  )

  const handleRowClick = () => {
    const checked = !selected
    handleClick(checked)
  }

  const handleCheckboxClick = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    handleClick(checked)
  }

  return (
    <TableRow
      key={productGroep.productGroepCode}
      hover={true}
      onClick={handleRowClick}
      style={{ cursor: 'pointer' }}
    >
      <TableCell>{productGroep.productGroepCode}</TableCell>
      <TableCell>{productGroep.productGroepOmschrijving}</TableCell>
      <TableCell style={{ textAlign: 'right' }}>
        <Checkbox
          checked={selected}
          onChange={handleCheckboxClick}
          sx={{ mr: 0.5 }}
        />
      </TableCell>
    </TableRow>
  )

  function handleClick(checked: boolean) {
    setSelected(checked)
    productGroep.productGroepGeselecteerd = checked
    changeChecked(productGroep)
  }
}
