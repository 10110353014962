/* eslint-disable max-len */
import {
  IClient,
  IVeilgroepIndelingRegelView,
} from 'src/common/services/client'
import { IClientExtra } from 'src/common/services/clientExtra'

import { byVolgordeNummer } from '../lib'

/**
 * getAllocationRulesAsync returns all regelviews for the given
 * productclusterID and vestigingCode
 * @param apiClient
 * @param productclusterID
 * @param vestigingCode
 * @returns Promise<IVeilgroepIndelingRegelView[]>
 */
export const getAllocationRulesAsync = async (
  apiClient: IClient,
  productclusterID: number,
  vestigingCode: number
): Promise<IVeilgroepIndelingRegelView[]> => {
  const result =
    (await apiClient.veilgroepIndelingRegelViews_GetByProductclusterVestiging2(
      productclusterID,
      vestigingCode
    )) as any

  const records: IVeilgroepIndelingRegelView[] =
    result?.cleanedResponse?.value || []
  records.sort(byVolgordeNummer) // sort in place

  return records || []
}

export const deleteAllocationRuleAsync = async (
  apiClient: IClient,
  idToBeDeleted: number
): Promise<void> => {
  await apiClient.veilgroepIndelingRegelViews_VeilgroepIndelingRegelView_DeleteVeilgroepIndelingRegelView2(
    idToBeDeleted,
    '*' //Fix: IF-Match header Bug, alle resources mogen nu matchen.
  )
}

export const updateAllocationRulesAsync = async (
  apiClient: IClientExtra,
  regelViews: IVeilgroepIndelingRegelView[]
): Promise<void> => {
  const version = '1'
  await apiClient.updateMultiple(version, { regelViews })
}
