/* eslint-disable max-len */
import { VestigingCode } from 'src/common/enums'
import { IClient, IVeilgroepIndelingOverview } from 'src/common/services/client'
import type { BloemenPlanten } from 'src/common/types'
import snackbarUtils from 'src/common/utils/snackbar/SnackbarUtils'

const maxTop = 300

export async function getByBloemenPlantenVestiging(
  apiClient: IClient,
  bloemenPlantenCode: BloemenPlanten,
  vestigingCode: VestigingCode
): Promise<IVeilgroepIndelingOverview[]> {
  const result: any =
    await apiClient.veilgroepIndelingen_GetByBloemenPlantenVestiging2(
      `'${bloemenPlantenCode}'`,
      vestigingCode
    )
  const count = result?.['@odata.count'] ?? 0
  const records: IVeilgroepIndelingOverview[] =
    result?.cleanedResponse?.value || []

  if (count > maxTop) {
    snackbarUtils.warning(
      `Er zijn er meer dan ${maxTop} productcluster veilgroep regels. Waarschuw de IT-afdeling.`
    )
  }

  return records || []
}
