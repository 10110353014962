import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import { Divider } from 'src/common/components'

import { BatchesStatusFilter, BatchesStatusGrid } from './components'

export default function BatchesStatus(): JSX.Element {
  const { t } = useTranslation()

  return (
    <Container maxWidth='xl'>
      <Grid item xs={12} mt={4} mb={2}>
        <Typography variant='h3' style={{ fontWeight: 'bold' }}>
          {t('common.titles.searchBatchesStatus')}
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <BatchesStatusFilter />
      </Grid>
      <Grid item sm={12}>
        <Divider {...{ marginTop: 16 }} />
      </Grid>
      <Grid item sm={12}>
        <BatchesStatusGrid />
      </Grid>
    </Container>
  )
}
