// eslint-disable-next-line
import { useTranslation } from 'react-i18next'

import { Container, Grid } from '@mui/material'
import { CardTag, RfhTypography } from '@rfh/ui'

import BooleanTag from 'src/common/components/BooleanTag'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { useUser } from 'src/common/providers/UserProvider'

import {
  StyledRfhTypographyContent,
  StyledRfhTypographyTitle,
} from './ProfilePage.styles'

export default function ProfilePage(): JSX.Element {
  /*
   * Hooks
   */
  const user = useUser()
  const { t } = useTranslation()

  /*
   * Methods
   */
  const showLoginAccount = () => {
    if (user.preferredUsername && user.sub) {
      return user.preferredUsername === user.sub ? (
        user.preferredUsername
      ) : (
        <>
          {user.preferredUsername}
          <CardTag tagColor='resolved'>{user.sub}</CardTag>
        </>
      )
    }
    return t('common.loading')
  }

  /*
   * Render
   */
  return (
    <Container
      maxWidth='lg'
      sx={{
        padding: ThemeConfig.spacing.sm,
        minHeight: 'calc(100vh - 64px - 8px)',
      }}
    >
      <Grid item xs={12}>
        <RfhTypography variant={'h1'} style={{ fontSize: '34px' }}>
          {t('common.titles.profilePage')}
        </RfhTypography>
      </Grid>

      <Grid
        container
        sx={{
          marginTop: ThemeConfig.spacing.sm,
          marginBottom: ThemeConfig.spacing.sm,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: 'white',
            padding: ThemeConfig.spacing.sm,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyTitle variant={'subheader'}>
                {t('profilePage.name')}
              </StyledRfhTypographyTitle>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyContent
                data-testid='name'
                variant={'body-copy'}
              >
                {user.name ? user.name : t('common.loading')}
              </StyledRfhTypographyContent>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyTitle variant={'subheader'}>
                {t('profilePage.account')}
              </StyledRfhTypographyTitle>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyContent
                data-testid='account'
                variant={'body-copy'}
              >
                {showLoginAccount()}
              </StyledRfhTypographyContent>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyTitle variant={'subheader'}>
                {t('profilePage.email')}
              </StyledRfhTypographyTitle>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyContent
                data-testid='email'
                variant={'body-copy'}
              >
                {user.email ? user.email : t('common.loading')}
              </StyledRfhTypographyContent>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <RfhTypography variant={'h1'} style={{ fontSize: '34px' }}>
          {t('profilePage.permissions')}
        </RfhTypography>
      </Grid>

      <Grid
        container
        sx={{
          marginTop: ThemeConfig.spacing.sm,
          marginBottom: ThemeConfig.spacing.sm,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: 'white',
            padding: ThemeConfig.spacing.sm,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyTitle variant={'subheader'}>
                {t('profilePage.consulterRole')}
              </StyledRfhTypographyTitle>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyContent
                data-testid='consulter'
                variant={'body-copy'}
              >
                <BooleanTag value={user.isConsulter} />
              </StyledRfhTypographyContent>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyTitle variant={'subheader'}>
                {t('profilePage.auctionCoordinatorRole')}
              </StyledRfhTypographyTitle>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyContent
                data-testid='auctionCoordinator'
                variant={'body-copy'}
              >
                <BooleanTag value={user.isAuctionCoordinator} />
              </StyledRfhTypographyContent>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
