/* eslint-disable id-blacklist */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { Box } from '@mui/material'
import {
  GridCellParams,
  GridColDef,
  GridInitialState,
  GridPaginationInitialState,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-premium'
import { RfhAlert } from '@rfh/ui'
import { InfoIcon } from '@rfh/ui/shared/floriday-icons'
import { QueryObserverResult } from '@tanstack/react-query'

import { BooleanTag, CustomDataGrid } from 'src/common/components'
import { GRID_MARGIN_TOP, MAX_NUMBER_COLS_SORTED } from 'src/common/constants'
import { Entity } from 'src/common/hooks'
import type { ListAndCount } from 'src/common/types'
import { formatDate, snackbarUtils } from 'src/common/utils'

import ProductRegularityDetails from './ProductRegularityDetails'

type ProductRegelmatig = typeof Entity.ProductRegelmatig

type ProductRegularityGridProps = {
  cacheKey: string
  rows: ProductRegelmatig[]
  isLoading: boolean
  paginationStateChange: (value: GridPaginationInitialState) => void
  refresh: () => Promise<
    QueryObserverResult<ListAndCount<ProductRegelmatig>, unknown>
  >
  rowCount: number
  sortModelChange: (value: GridSortModel) => void
}

export default function ProductRegularityGrid({
  cacheKey,
  isLoading,
  paginationStateChange,
  refresh,
  rowCount,
  rows,
  sortModelChange,
  ...rest
}: Readonly<ProductRegularityGridProps>): JSX.Element {
  const { i18n, t } = useTranslation()
  const history = useHistory()
  const { aanvoerderProductRegelmatigId }: any = useParams()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [hideAlertContainer, setHideAlertContainer] = useState(false)
  // Because we use server side sorting we only allow sorting when there are no more than 300.000 rows
  const maxSortedRows = 300000
  const sortingAllowed = rowCount <= maxSortedRows

  const initialState: GridInitialState = {
    columns: {
      columnVisibilityModel: {
        aanvoerderNummer: true,
        AanvoerderNaam: true,
        ProductCode: true,
        ProductNaam: true,
        vestigingCode: true,
        regelmatig: true,
        UitzonderingDatumVanaf: true,
        UitzonderingDatumTtm: true,
        UitzonderingRedenOmschrijving: true,
        UitzonderingRedenTekst: true,
      },
    },
  }

  const changeLocalStorageSortingModel = (value: GridSortModel) => {
    if (value?.length <= MAX_NUMBER_COLS_SORTED) {
      sortModelChange(value)
    } else {
      // To prevent abuse the maximum number of sorted columns is set to five in the backend
      // we catch this so we can show a friendlier message
      snackbarUtils.warning(t('overviews.maximumSorting'))
    }
  }

  const changePaginationState = (value: GridPaginationInitialState) => {
    paginationStateChange(value)
  }

  const goToRegularityDetails = useCallback(
    (params: GridCellParams) => {
      const id = params?.row?.aanvoerderProductRegelmatigId
      id && history.push(`/dashboard/supplier-product-regularity/${id}`)
      setDialogOpen(true)
    },
    [history]
  )

  const closeRegularityDetails = useCallback(() => {
    history.push('/dashboard/supplier-product-regularity')
    setDialogOpen(false)
  }, [history])

  useEffect(() => {
    aanvoerderProductRegelmatigId &&
      goToRegularityDetails({
        row: { aanvoerderProductRegelmatigId },
      } as any)
  }, [aanvoerderProductRegelmatigId, goToRegularityDetails])

  const columns = useMemo((): GridColDef[] => {
    const newColumns: GridColDef[] = []
    newColumns.push({
      field: 'vestigingNaam',
      headerName: t('common.auctionLocation'),
      align: 'left',
      headerAlign: 'left',
      minWidth: 90,
      sortable: sortingAllowed,
      valueFormatter: ({ value }: { value: string }) =>
        value ? value.charAt(0).toUpperCase() + value.slice(1) : '',
    })
    newColumns.push({
      field: 'aanvoerderNummer',
      headerName: t('overviews.supplierNumber'),
      sortable: sortingAllowed,
      align: 'right',
      headerAlign: 'right',
      minWidth: 130,
    })
    newColumns.push({
      field: 'aanvoerderNaam',
      headerName: t('overviews.supplierName'),
      minWidth: 160,
      align: 'left',
      headerAlign: 'left',
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'productCode',
      headerName: t('overviews.productCode'),
      align: 'right',
      headerAlign: 'right',
      minWidth: 120,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'productNaam',
      headerName: t('overviews.productDescription'),
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      minWidth: 180,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'regelmatig',
      headerName: t('overviews.regularity'),
      sortable: sortingAllowed,
      align: 'center',
      headerAlign: 'left',
      minWidth: 110,
      aggregable: false,
      renderCell: (params: GridRenderCellParams) => (
        <BooleanTag value={params.row.regelmatig} />
      ),
    })
    newColumns.push({
      field: 'aantalWekenLimiet',
      headerName: t('overviews.weekLimit'),
      type: 'number',
      align: 'center',
      headerAlign: 'left',
      minWidth: 160,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'aantalDagenGeveild',
      headerName: t('overviews.auctionDays'),
      type: 'number',
      align: 'center',
      headerAlign: 'left',
      minWidth: 130,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'aantalDagenGeveildWeek',
      headerName: t('overviews.auctionDaysWeek'),
      type: 'number',
      align: 'center',
      headerAlign: 'left',
      minWidth: 140,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'aantalWekenUitzondering',
      headerName: t('overviews.exceptionWeeks'),
      type: 'number',
      align: 'center',
      headerAlign: 'left',
      minWidth: 190,
      sortable: sortingAllowed,
      valueFormatter: ({ value }) => value ?? 0,
    })
    newColumns.push({
      field: 'datumEindeWeek',
      headerName: t('overviews.dateEndOfWeek'),
      align: 'left',
      headerAlign: 'left',
      minWidth: 160,
      sortable: sortingAllowed,
      valueFormatter: row =>
        row.value && formatDate(row, t('common.dateFormatShort')),
      groupingValueGetter: row =>
        row.value && formatDate(row, t('common.dateFormatShort')),
    })

    return newColumns
  }, [sortingAllowed, t])

  return (
    <>
      {!sortingAllowed && !hideAlertContainer && (
        <Box
          sx={{
            marginBottom: 2,
            width: '100%',
          }}
        >
          <RfhAlert
            bgColorVariant='dutchOrange'
            icon={<InfoIcon />}
            messageText={t('overviews.sortingDisabledAlertMessage')}
            title={t('overviews.sortingDisabledAlertTitle')}
            onClose={() => setHideAlertContainer(true)}
          />
        </Box>
      )}
      <Box position={'relative'} marginTop={GRID_MARGIN_TOP} {...rest}>
        <CustomDataGrid
          cacheKey={cacheKey}
          changeGridPaginationInitialState={changePaginationState}
          changeSortModel={changeLocalStorageSortingModel}
          columns={columns}
          rows={rows}
          initialState={initialState}
          language={i18n.language}
          getRowId={(row: ProductRegelmatig) =>
            row.aanvoerderProductRegelmatigId
          }
          gridProps={{
            rowCount,
            onCellClick: goToRegularityDetails,
            loading: isLoading,
          }}
        />
      </Box>
      <ProductRegularityDetails
        open={dialogOpen}
        aanvoerderProductRegelmatigId={aanvoerderProductRegelmatigId}
        onClose={closeRegularityDetails}
      ></ProductRegularityDetails>
    </>
  )
}
