import type {
  IClient,
  IVeilgroepIndelingRegelView,
} from 'src/common/services/client'
import type {
  IClientExtra,
  IRegelCodesViewsBodyData,
} from 'src/common/services/clientExtra'

export const getAllocationRuleByRuleNumberAsync = async (
  apiClient: IClient,
  ruleNumber: number
): Promise<IVeilgroepIndelingRegelView> => {
  const result = (await apiClient.veilgroepRegels_GetByRegelnummer2(
    ruleNumber
  )) as any
  const record: IVeilgroepIndelingRegelView = result?.value?.[0]
  return record
}

export async function upsertAuctionGroupRuleAsync(
  apiClient: IClientExtra,
  body: IRegelCodesViewsBodyData
): Promise<IVeilgroepIndelingRegelView> {
  const version = '1'
  return (await apiClient.upsertSingleWithCodes(
    version,
    body
  )) as IVeilgroepIndelingRegelView
}
