import { useCallback, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'

import { Divider } from 'src/common/components'
import { initialState, refreshReducer } from 'src/common/lib'
import { useApiClient } from 'src/common/providers'
import { snackbarUtils } from 'src/common/utils'

import { SearchFilter, SearchInfo } from '../components'
import { getProductClusterInfo } from '../services/searchProductServices'
import { useClusterRulesStore } from '../stores'

export default function SearchProduct(): JSX.Element {
  const { t } = useTranslation()
  const { apiClient } = useApiClient()
  const {
    mustRefetch,
    productCode,
    productGroepCode,
    setMustRefetch,
    setClusterView,
    clusterView,
  } = useClusterRulesStore()
  const [state, dispatch] = useReducer(refreshReducer, initialState)

  const fetchData = useCallback(async () => {
    if (!apiClient || state.loading) {
      return Promise.resolve()
    }
    try {
      dispatch({ type: 'PENDING' })
      const info = await getProductClusterInfo(
        apiClient,
        productCode,
        productGroepCode
      )
      setClusterView(info)
    } catch (error) {
      dispatch({ type: 'ERROR' })
      snackbarUtils.error(`${error}`)
    } finally {
      dispatch({ type: 'RESOLVED' })
    }
  }, [apiClient, productCode, productGroepCode, setClusterView, state.loading])

  useEffect(() => {
    if (mustRefetch) {
      fetchData()
      setMustRefetch(false)
    }
  }, [apiClient, fetchData, mustRefetch, setMustRefetch])

  return (
    <Container maxWidth='xl'>
      <Grid item xs={12} mt={4} mb={2}>
        <Typography variant='h3' sx={{ fontWeight: 'bold' }}>
          {t('common.titleDescriptions.searchProduct')}
        </Typography>
      </Grid>
      <Grid item>
        <SearchFilter />
      </Grid>
      <Divider {...{ marginTop: 16 }} />
      <Grid item xs={12} mt={4} mb={2}>
        {clusterView ? <SearchInfo /> : null}
      </Grid>
    </Container>
  )
}
