import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomDataGrid } from 'src/common/components'
import { useSearchBatches } from 'src/common/hooks'

import { getStaticColumns } from '../constants'
import { toBatchesStatus } from '../lib'
import { useFilterStore } from '../stores'

import { v4 as getRandomID } from 'uuid'

export default function BatchesStatusGrid(): JSX.Element {
  const CACHE_KEY = 'batches-status-datagrid'
  const ENABLED = true
  const { i18n } = useTranslation()
  const { filter } = useFilterStore()

  const {
    data: { records: batches },
    isFetching,
  } = useSearchBatches(filter, ENABLED, { staleTime: Infinity })

  const rows = useMemo(() => batches.map(toBatchesStatus), [batches])

  const columns = useMemo(
    () => getStaticColumns(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getStaticColumns, i18n.language]
  )

  return (
    <CustomDataGrid
      cacheKey={CACHE_KEY}
      columns={columns}
      getRowId={() => getRandomID()}
      gridProps={{
        headerHeight: 80,
        pagination: false,
        paginationMode: 'client',
        sortingMode: 'client',
        loading: isFetching,
      }}
      language={i18n.language}
      rows={isFetching ? [] : rows}
      sortingMode={'client'}
    />
  )
}
