/* eslint-disable @typescript-eslint/no-var-requires */
import { StrictMode } from 'react'
import { render } from 'react-dom'

import { setMuiXProLicenseKey } from '@rfh/ui/shared/assets/helpers/setMuiXProLicenseKey'

import 'src/i18n'

import App from './App'
import { loadDevTools } from './dev-tools'
import './index.css'

setMuiXProLicenseKey()

const callbackToRun = (): void =>
  render(
    <StrictMode>
      <App />
    </StrictMode>,
    document.getElementById('root')
  )

loadDevTools(callbackToRun)
