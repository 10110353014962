import { useCallback, useMemo } from 'react'

import { usePathname } from 'src/common/hooks/usePathname'
import { IProductclusterView } from 'src/common/services/client'

import { FEATURE_ROOT_PATH } from '../constants'
import { useProductClusterStore, useProductClustersStore } from '../stores'

type UseProductClustersReturn = {
  getClusterByClusterID: (clusterNummer: number) => IProductclusterView
  isDataDirty: boolean
}

export const useProductClusters = (): UseProductClustersReturn => {
  const { ADDING } = usePathname(FEATURE_ROOT_PATH)
  const { cluster, oldCluster, productgroepenHaveChanged } =
    useProductClusterStore()
  const { clusters } = useProductClustersStore((state: any) => ({
    clusters: state.clusters,
  }))

  const isDataDirty = useMemo(() => {
    const newOne: IProductclusterView = { ...cluster }
    const oldOne: IProductclusterView = { ...oldCluster }
    if (ADDING) {
      // upsertMode: 'ADD'
      return Boolean(cluster?.productclusterOmschrijving)
    }
    // upsertMode: 'EDIT'
    return (
      JSON.stringify(newOne) !== JSON.stringify(oldOne) ||
      productgroepenHaveChanged
    )
  }, [cluster, oldCluster, productgroepenHaveChanged, ADDING])

  const getClusterByClusterID = useCallback(
    (clusterNummer: number) =>
      clusters.find(
        (c: IProductclusterView) => c.productclusterID === clusterNummer
      ) as IProductclusterView,
    [clusters]
  )

  return {
    getClusterByClusterID,
    isDataDirty,
  }
}
