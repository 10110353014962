/* eslint-disable import/no-unresolved */
import { ChangeEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { RfhTypography, TextField } from '@rfh/ui'
import { Button } from '@rfh/ui/components/RfhButton'
import { SearchIcon } from '@rfh/ui/shared/floriday-icons'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { useStore } from 'zustand'

import { ThemeConfig } from 'src/common/config'
import { byBloemenPlantenFilter } from 'src/common/lib'
import { IClient, IProductgroepenReport } from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'

import { byExistingCodeFilter, getExtraReportsFromDeleted } from '../../lib'
import { getAllowedProductGroupsAsync } from '../../services'
import { useProductClusterStore, useProductClustersStore } from '../../stores'
import ProductGroupsItem from './ProductGroupsItem'

type ProductGroupsAddDialogProps = {
  apiClient: IClient
  buttonSx?: SxProps
  disabled?: boolean
  onChange: (productgroepen: IProductgroepenReport[]) => void
  showNumber?: boolean
  trigger?: 'iconButton' | 'button'
}

export default function ProductGroupsAddDialog({
  apiClient,
  buttonSx,
  disabled = false,
  onChange,
  showNumber = false,
  trigger = 'iconButton',
}: Readonly<ProductGroupsAddDialogProps>): JSX.Element {
  const { t } = useTranslation()
  const { handleSubmit } = useForm()
  const bloemenPlanten = useStore(
    useProductClustersStore,
    state => state.bloemenPlanten
  )
  const { deletedProductgroepen, productclusterProductgroepen } =
    useProductClusterStore()
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [allowedFromApi, setAllowedFromApi] = useState<IProductgroepenReport[]>(
    []
  )
  const [allowedProductgroepen, setAllowedProductgroepen] = useState<
    IProductgroepenReport[]
  >([])
  const [searchByCodePattern, setSearchByCodePattern] = useState<string>('')
  const [searchByNamePattern, setSearchByNamePattern] = useState<string>('')

  const openDialog = () => {
    setIsOpen(true)
    fetchData()
  }

  const closeDialog = () => {
    setIsOpen(false)
  }

  const changeChecked = (clickedProductGroep: IProductgroepenReport) => {
    const index = allowedProductgroepen.findIndex(
      pg => pg.productGroepCode === clickedProductGroep.productGroepCode
    )
    const updatedProductgroepen = [
      ...allowedProductgroepen.slice(0, index),
      clickedProductGroep,
      ...allowedProductgroepen.slice(index + 1),
    ]
    setAllowedProductgroepen(updatedProductgroepen)
  }

  const resetAllowedProductgroepenFromApi = () =>
    setAllowedProductgroepen([...allowedFromApi])

  const handleSearch =
    (searchPattern?: string, searchType?: 'code' | 'name') => () => {
      if (!searchPattern) {
        resetAllowedProductgroepenFromApi()
        return
      }

      const pattern = new RegExp(searchPattern, 'i')
      if (searchType === 'code') {
        setAllowedProductgroepen([
          ...allowedProductgroepen.filter(pg =>
            pattern.exec(pg.productGroepCode.toString())
          ),
        ])
      } else if (searchType === 'name') {
        setAllowedProductgroepen([
          ...allowedProductgroepen.filter(pg =>
            pattern.exec(pg.productGroepOmschrijving)
          ),
        ])
      } else {
        resetAllowedProductgroepenFromApi()
      }
    }

  const handleChange = () => {
    const selected = allowedProductgroepen.filter(
      pg => pg.productGroepGeselecteerd
    )
    if (selected.length > 0) {
      onChange(selected)
    }
    closeDialog()
  }

  const handleFilterProductGroupCode = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const searchPattern = event.target.value
    setSearchByCodePattern(searchPattern)
    setSearchByNamePattern('')
    handleSearch(searchPattern, 'code')()
  }

  const handleFilterProductGroupName = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const searchPattern = event.target.value
    setSearchByNamePattern(event.target.value)
    setSearchByCodePattern('')
    handleSearch(searchPattern, 'name')()
  }

  const fetchData = async () => {
    if (inProgress) {
      return Promise.resolve()
    }

    try {
      setInProgress(true)
      const allowed = await getAllowedProductGroupsAsync(
        apiClient,
        `${bloemenPlanten}`
      )
      setAllowedFromApi(allowed)
      const extraReportsFromDeleted: IProductgroepenReport[] =
        getExtraReportsFromDeleted(allowed, deletedProductgroepen)
      const allAllowed = [...allowed, ...extraReportsFromDeleted]
      const filteredAllowed = allAllowed.filter(
        byExistingCodeFilter(productclusterProductgroepen)
      )
      setAllowedProductgroepen(filteredAllowed)
    } catch (error: any) {
      snackbarUtils.error(String(error))
    } finally {
      setInProgress(false)
    }
  }

  return (
    <>
      {trigger === 'iconButton' ? (
        <IconButton
          aria-label={'lookup icon button'}
          onClick={openDialog}
          size='large'
        >
          <SearchIcon sx={{ color: RfhColors.darkOrange, height: 20, py: 0 }} />
        </IconButton>
      ) : (
        <Button
          disabled={disabled}
          onClick={openDialog}
          size='small'
          sx={{ pointerEvents: 'auto', ...buttonSx }}
          variant='block--contained'
        >
          {t('common.add')}
        </Button>
      )}
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogContent
          sx={{
            width: '600px',
            maxWidth: '600px',
          }}
        >
          <RfhTypography style={{ marginBottom: '16px' }} variant={'h5'}>
            {t('productCluster.searchProductGroups')}
          </RfhTypography>
          <form
            onSubmit={handleSubmit(handleSearch())}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: ThemeConfig.spacing.l,
            }}
          >
            {showNumber && (
              <TextField
                id='productGroupCode'
                name='productGroupCode'
                value={searchByCodePattern}
                placeholder={t('common.code')}
                onChange={handleFilterProductGroupCode}
                fieldColor='dark'
              />
            )}
            <TextField
              id='productGroupName'
              name='productGroupName'
              value={searchByNamePattern}
              placeholder={t('productCluster.productGroupDescription')}
              fullWidth
              onChange={handleFilterProductGroupName}
              fieldColor='dark'
            />
            <Button
              variant='block--contained'
              onClick={handleSubmit(handleChange)}
            >
              {t('common.ok')}
            </Button>
          </form>
          <Table
            style={{
              minHeight: '100px',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    textAlign: 'left',
                    verticalAlign: 'top',
                  }}
                >
                  {t('productCluster.productGroupCode')}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'left',
                    verticalAlign: 'top',
                  }}
                >
                  {t('productCluster.productGroupDescription')}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'right',
                    verticalAlign: 'top',
                  }}
                >
                  {t('productCluster.productGroupSelected')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!allowedProductgroepen.length && (
                <TableRow>
                  <TableCell colSpan={3}>{t('common.noResults')}</TableCell>
                </TableRow>
              )}
              {allowedProductgroepen
                .filter(byBloemenPlantenFilter(bloemenPlanten))
                .map((pg: IProductgroepenReport) => (
                  <ProductGroupsItem
                    changeChecked={changeChecked}
                    key={pg.productGroepCode}
                    productGroep={pg}
                  />
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            variant='text--underlined'
            autoFocus
            onClick={closeDialog}
            sx={{
              marginBottom: ThemeConfig.spacing.xs * 2,
              marginRight: ThemeConfig.spacing.sm + ThemeConfig.spacing.xs,
            }}
          >
            {t('common.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
