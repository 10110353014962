/* eslint-disable id-blacklist */
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ActionButton,
  AvailableLocations,
  AvailableProductGroupInput,
  AvailableSupplierInput,
  FilterButtonsStack,
  FilterFieldsStack,
  FilterStack,
} from 'src/common/components'
import { useFilter } from 'src/common/hooks'
import { IAllSearchFilterFields, IOverviewsFilter } from 'src/common/interfaces'
import { noOp } from 'src/common/lib'
import {
  constructFilterString,
  isFilterEmpty,
  pushNewFilterRule,
} from 'src/common/utils'

export default function OverviewsFilter({
  changeFilterString,
  resetSearch: resetFilter,
  ...rest
}: Readonly<IOverviewsFilter>): JSX.Element {
  const CACHE_KEY = 'product-group-regularity-filter'
  const defaultValues: IAllSearchFilterFields = {
    aanvoerderNummer: undefined,
    productgroepCode: undefined,
    vestigingCode: undefined,
  }

  const setFilterString = (newValues: IAllSearchFilterFields) => {
    if (isFilterEmpty(newValues)) {
      changeFilterString(undefined)
      return
    }
    const newFilterString = constructFilterString(newValues, pushNewFilterRule)
    changeFilterString(newFilterString)
  }

  const { t } = useTranslation()

  const {
    filter,
    clear,
    handleChange,
    handleRequest: search,
  } = useFilter({
    cacheKey: CACHE_KEY,
    defaultFilter: defaultValues,
    resetFilter,
    setFilterString,
  })

  useEffect(() => {
    clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FilterStack {...rest}>
      <FilterFieldsStack>
        <AvailableSupplierInput
          changeSupplierNumber={value =>
            value > 0
              ? handleChange('aanvoerderNummer', value)
              : handleChange('aanvoerderNummer', undefined)
          }
          changeValid={noOp}
          value={filter.aanvoerderNummer ? filter.aanvoerderNummer : undefined}
          sx={{ maxWidth: 160 }}
        />
        <AvailableProductGroupInput
          onChange={value =>
            value > 0
              ? handleChange('productgroepCode', value)
              : handleChange('productgroepCode', undefined)
          }
          changeValid={noOp}
          value={filter.productgroepCode ? filter.productgroepCode : undefined}
          values={filter}
          sx={{ maxWidth: 160 }}
        />
        <AvailableLocations
          handleChange={handleChange}
          inputValue={filter.vestigingCode}
          labelVisible
          sx={{ ml: 0.2, width: 160 }}
        />
      </FilterFieldsStack>
      <FilterButtonsStack>
        <ActionButton variant='block--outlined' onClick={clear}>
          {t('common.clear')}
        </ActionButton>
        <ActionButton type='submit' onClick={search}>
          {t('common.search')}
        </ActionButton>
      </FilterButtonsStack>
    </FilterStack>
  )
}
