import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Box, Container, FormControl, Grid } from '@mui/material'
import { LoadingPage, RfhTypography, TextField } from '@rfh/ui'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'

import {
  AvailableLocations,
  AvailableProductGroupInput,
  AvailableReasonsInput,
  AvailableSupplierInput,
  CustomDatePicker,
} from 'src/common/components'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { HttpCode } from 'src/common/constants'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { addSupplierProductGroupRegularityException } from 'src/common/services'
import {
  AanvoerderProductgroepRegelmatigUitzondering,
  IAanvoerderProductgroepRegelmatigUitzondering,
} from 'src/common/services/client'
import {
  disableNonFridays,
  disableNonMondays,
  getNextFriday,
  getNextMonday,
  getUTCDate,
  isUndefinedOrEmpty,
  isUndefinedOrNull,
  snackbarUtils,
} from 'src/common/utils'

import isEqual from 'lodash/isEqual'

export default function AddProductGroupException(): JSX.Element {
  const MONDAY = 1
  const FRIDAY = 5
  const { t } = useTranslation()
  const defaultValues: IAanvoerderProductgroepRegelmatigUitzondering = {
    aanvoerderNummer: 0,
    productgroepCode: 0,
    vestigingCode: 0,
    aanmaakDatumTijd: new Date(),
    uitzonderingDatumVanaf: getNextMonday(),
    uitzonderingDatumTtm: getNextFriday(getNextMonday()),
    uitzonderingRedenCode: 0,
    uitzonderingRedenTekst: '',
  }

  /*
  State
  */
  const [inProgress, setInProgress] = useState(false)
  const [values, setValues] =
    useState<IAanvoerderProductgroepRegelmatigUitzondering>(defaultValues)
  const [isValidSupplierNumber, setIsValidSupplierNumber] =
    useState<boolean>(false)
  const [isValidProductGroupCode, setIsValidProductGroupCode] =
    useState<boolean>(false)
  const [isValidLocation, setIsValidLocation] = useState<boolean>(false)
  const [isValidReason, setIsValidReason] = useState<boolean>(false)
  const [isValidReasonText, setIsValidReasonText] = useState<boolean>(true)

  /*
 Hooks
 */
  const history = useHistory()
  const { apiClient } = useApiClient()
  const user = useUser()

  const handleChange = (
    changedValues: Partial<IAanvoerderProductgroepRegelmatigUitzondering>
  ): void => {
    const newValues = {
      ...values,
      ...changedValues,
    }
    !isEqual(values, newValues) && setValues(newValues)
  }

  const addException = async () => {
    try {
      if (inProgress) {
        return Promise.resolve()
      }

      setInProgress(true)

      const newEntity = new AanvoerderProductgroepRegelmatigUitzondering({
        ...values,
        aanmaakGebruiker: user.sub,
        mutatieDatumTijd: new Date(),
        mutatieGebruiker: user.sub,
        mutatieOmschrijving: '',
      })

      if (!validateInput(newEntity)) {
        setInProgress(false)
        return
      }

      const result =
        // eslint-disable-next-line max-len
        (await addSupplierProductGroupRegularityException(
          apiClient,
          newEntity
        )) as any
      if (result === HttpCode.CREATED) {
        snackbarUtils.success('Success')
        setValues(defaultValues)
      }
    } catch (error: any) {
      snackbarUtils.error(String(error))
    }
    setInProgress(false)
  }

  const validateInput = (
    input: AanvoerderProductgroepRegelmatigUitzondering
  ): boolean => {
    if (isUndefinedOrNull(input.vestigingCode)) {
      snackbarUtils.error(t('exceptions.addExceptions.locationEmptyError'))
      return false
    }
    if (isUndefinedOrNull(input.uitzonderingRedenCode)) {
      snackbarUtils.error(t('exceptions.addExceptions.reasonEmptyError'))
      return false
    }
    if (
      input.uitzonderingRedenCode === 1 &&
      isUndefinedOrEmpty(input.uitzonderingRedenTekst)
    ) {
      snackbarUtils.error(t('exceptions.addExceptions.reasonInputError'))
      return false
    }
    if (input.uitzonderingDatumVanaf.getDay() !== MONDAY) {
      snackbarUtils.error(t('exceptions.addExceptions.startdateDayError'))
      return false
    }
    if (input.uitzonderingDatumTtm.getDay() !== FRIDAY) {
      snackbarUtils.error(t('exceptions.addExceptions.enddateDayError'))
      return false
    }
    if (input.uitzonderingDatumTtm < input.uitzonderingDatumVanaf) {
      snackbarUtils.error(t('exceptions.addExceptions.daySwitch'))
      return false
    }
    return true
  }

  if (inProgress) {
    return <LoadingPage />
  }

  return (
    <Container
      maxWidth='xl'
      sx={{
        padding: ThemeConfig.spacing.sm,
        minHeight: 'calc(100vh - 64px - 8px)',
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          width: '100%',
          margin: '0',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            padding: '0 !important',
          }}
        >
          <RfhTypography
            variant='h1'
            style={{
              fontSize: '35px',
              margin: `${ThemeConfig.spacing.sm * 8} 0`,
            }}
          >
            {t('common.titles.addExceptions')}
          </RfhTypography>
        </Grid>
        <Box
          component='form'
          onSubmit={addException}
          sx={{
            width: '100%',
          }}
        >
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormControl
                sx={{
                  margin: 0,
                  width: '100%',
                }}
              >
                <AvailableSupplierInput
                  changeValid={setIsValidSupplierNumber}
                  changeSupplierNumber={value =>
                    handleChange({ aanvoerderNummer: value })
                  }
                  value={values.aanvoerderNummer}
                  required
                  sx={{
                    marginTop: ThemeConfig.spacing.s,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormControl
                sx={{
                  margin: 0,
                  width: '100%',
                }}
              >
                <AvailableProductGroupInput
                  values={values}
                  value={values.productgroepCode}
                  changeValid={setIsValidProductGroupCode}
                  onChange={value => handleChange({ productgroepCode: value })}
                  required
                  sx={{
                    marginTop: ThemeConfig.spacing.s,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <AvailableLocations
                changeValid={setIsValidLocation}
                handleChange={(_name, value) =>
                  handleChange({ vestigingCode: Number(value) })
                }
                inputValue={values.vestigingCode}
                labelVisible
                required
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormControl
                sx={{
                  margin: 0,
                  width: '100%',
                  zIndex: 99,
                }}
              >
                {/* Workaroud because of missing functionallity in RfhUi datepicker (disablePast, shouldDisableDate, shouldDisableMonth, shouldDisableYear) */}
                <CustomDatePicker
                  label={t('exceptions.startDate')}
                  value={values.uitzonderingDatumVanaf}
                  onChange={value => {
                    handleChange({
                      uitzonderingDatumVanaf: getUTCDate(value),
                    })
                  }}
                  shouldDisableDate={disableNonMondays}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <RfhTypography variant={'subheader'}>
                {`${t('overviews.reason')} *`}
              </RfhTypography>
              <FormControl
                sx={{
                  margin: 0,
                  width: '100%',
                  marginTop: 2,
                }}
              >
                <AvailableReasonsInput
                  input={values.uitzonderingRedenCode}
                  changeValid={setIsValidReason}
                  onChangeReason={(_name, value) => {
                    if (value === 1) {
                      setIsValidReasonText(false)
                    } else {
                      setIsValidReasonText(true)
                    }
                    handleChange({ uitzonderingRedenCode: Number(value) })
                  }}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormControl
                sx={{
                  margin: 0,
                  width: '100%',
                  zIndex: 99,
                }}
              >
                {/* Workaroud because of missing functionallity in RfhUi datepicker (disablePast, shouldDisableDate, shouldDisableMonth, shouldDisableYear) */}
                <CustomDatePicker
                  label={t('exceptions.endDate')}
                  value={values.uitzonderingDatumTtm}
                  onChange={value => {
                    handleChange({
                      uitzonderingDatumTtm: getUTCDate(value),
                    })
                  }}
                  shouldDisableDate={disableNonFridays()}
                />
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormControl
                sx={{
                  margin: 0,
                  width: '100%',
                }}
              >
                <TextField
                  id='vrijeTekst'
                  label={
                    values.uitzonderingRedenCode === 1
                      ? `${t('exceptions.freeText')} *`
                      : t('exceptions.freeText')
                  }
                  name='uitzonderingRedenTekst'
                  type='text'
                  placeholder={t('exceptions.freeText')}
                  value={values.uitzonderingRedenTekst}
                  onChange={evnt => {
                    if (evnt.target.value !== '') {
                      setIsValidReasonText(true)
                    } else {
                      setIsValidReasonText(false)
                    }
                    handleChange({
                      [evnt.target.name]: evnt.target.value,
                    })
                  }}
                  fullWidth
                ></TextField>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: ThemeConfig.spacing.s,
                marginBottom: ThemeConfig.spacing.sm,
              }}
            >
              <RfhButton
                variant='block--outlined'
                sx={{
                  marginRight: ThemeConfig.spacing.s,
                }}
                onClick={() => history.goBack()}
              >
                {t('common.back')}
              </RfhButton>
              <RfhButton
                size='small'
                variant='block--contained'
                disabled={
                  !isValidSupplierNumber ||
                  !isValidProductGroupCode ||
                  !isValidLocation ||
                  !isValidReason ||
                  !isValidReasonText
                }
                isLoading={inProgress}
                onClick={addException}
              >
                {t('common.add')}
              </RfhButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  )
}
