import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  FormControl,
  InputAdornment,
  SxProps,
  buttonBaseClasses,
  filledInputClasses,
  formHelperTextClasses,
} from '@mui/material'
import { TextField } from '@rfh/ui'

import { ELEMENT_HEIGHT, MAX_VALID_VALUE } from 'src/common/constants'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { getAanvoerderAsync } from 'src/common/services/aanvoerderService'
import { IAanvoerder } from 'src/common/services/client'

import AvailableSuppliersDialog from './AvailableSuppliersDialog'

import debounce from 'lodash.debounce'

type AvailableSupplierInputProps = {
  changeSupplierNumber: (newNumber: number) => void
  changeValid?: (newValue: boolean) => void
  disabled?: boolean
  value?: number
  required?: boolean
  showNumberInDialog?: boolean
  sx?: SxProps
}

export default function AvailableSupplierInput({
  changeSupplierNumber,
  changeValid,
  disabled = false,
  value,
  required = false,
  showNumberInDialog = false,
  sx,
}: Readonly<AvailableSupplierInputProps>): JSX.Element {
  /*
   * Hooks
   */
  const { t } = useTranslation()
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined)
  const [supplierNumber, setSupplierNumber] = useState<number>(0)
  const [supplierName, setSupplierName] = useState<string>('')
  const { apiClient } = useApiClient()

  const fetchData = useCallback(
    async (newValue: number, emitUpdate = true) => {
      if (!apiClient || inProgress) {
        return Promise.resolve()
      }

      if (newValue > 0) {
        setSupplierName('')
        setInProgress(true)
        const aanvoerder = await getAanvoerderAsync(apiClient, {
          key: newValue,
        })
        setSupplierNumber(newValue)
        setSupplierName(aanvoerder ? aanvoerder.aanvoerderNaam : undefined)
        setIsValid(Boolean(aanvoerder))
        if (emitUpdate) {
          changeSupplierNumber(newValue)
          changeValid(Boolean(aanvoerder))
        }
        setInProgress(false)
      } else {
        setSupplierNumber(0)
        setSupplierName('')
        setIsValid(false)
        if (emitUpdate) {
          changeSupplierNumber(0)
          changeValid(false)
        }
      }
    },
    [apiClient, inProgress, changeSupplierNumber, changeValid]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedNewInput = useCallback(debounce(fetchData, 1000), [fetchData])

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = Number(event.target.value)
    if (newValue >= 0 && newValue < MAX_VALID_VALUE) {
      setSupplierNumber(newValue)
      debouncedNewInput(newValue)
    }
  }

  const handleChangeSupplier = (aanvoerder: IAanvoerder) => {
    setSupplierNumber(aanvoerder.aanvoerderNummer)
    setSupplierName(aanvoerder.aanvoerderNaam)
    changeSupplierNumber(aanvoerder.aanvoerderNummer)
    setIsValid(true)
    changeValid(true)
  }

  const helperText = useCallback((): string => {
    if (supplierNumber && !inProgress) {
      return supplierName
    }
    return ''
  }, [inProgress, supplierName, supplierNumber])

  const isError =
    typeof isValid === 'undefined' || !supplierNumber ? false : !isValid

  useEffect(() => {
    if (!(value in [0, supplierNumber])) {
      fetchData(value, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, value])

  return (
    <FormControl fullWidth sx={sx}>
      <TextField
        label={t('exceptions.supplierNumber')}
        placeholder={t('exceptions.addExceptions.selectSupplier')}
        name='aanvoerderNummer'
        required={required}
        data-testid='aanvoerder'
        disabled={disabled}
        error={isError}
        value={supplierNumber !== 0 ? supplierNumber : ''}
        onChange={handleChange}
        helperText={helperText()}
        suffixTextOrIcon={
          !disabled && (
            <InputAdornment position='end'>
              <AvailableSuppliersDialog
                apiClient={apiClient}
                onChange={handleChangeSupplier}
                showNumber={showNumberInDialog}
              />
            </InputAdornment>
          )
        }
        sx={{
          [`& .${filledInputClasses.root}`]: {
            pr: 0,
          },
          [`& .${filledInputClasses.root}`]: { pr: 0 },
          [`& .${buttonBaseClasses.root}`]: { pr: '0.2rem' },
          [`& p.${formHelperTextClasses.root}`]: {
            position: 'absolute',
            bottom: '-2.5rem',
            left: '0.6rem',
            height: ELEMENT_HEIGHT,
          },
        }}
      />
    </FormControl>
  )
}
