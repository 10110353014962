import { ThemeConfig } from 'src/common/config'
import { IVeilgroepRegel } from 'src/common/services/client'

import { DateInput } from '../types'

export const calcMarginRight = (): number => ThemeConfig.spacing.l * 4.64

export const validateAuctionPeriod = (input: DateInput): boolean => {
  if (Object.values(input).every(el => el == null)) {
    return true
  }
  const keys: (keyof DateInput)[] = [
    'dagTtm',
    'dagVanaf',
    'weekTtm',
    'weekVanaf',
  ]

  for (const key of keys) {
    if (input[key] === undefined || input[key] === null) {
      return false
    }
  }

  return true
}

export const validateDescription = (input: IVeilgroepRegel): boolean => {
  if (!input) return false
  return (
    input?.veilgroepRegelOmschrijving?.length > 0 &&
    input?.veilgroepRegelOmschrijving?.length <= 40
  )
}
