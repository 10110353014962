import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch } from 'react-router-dom'

import Box from '@mui/material/Box'
import { LoginCallback } from '@okta/okta-react'
import { Cookiebar, OnlineOfflineAlert, RfhAlert } from '@rfh/ui'
import LoadingIconText from '@rfh/ui/components/LoadingStates/LoadingIconText'
import NotificationsIcon from '@rfh/ui/shared/floriday-icons/icons/NotificationsIcon'

import GenericError from 'src/common/components/GenericError'
import { snackbarUtils } from 'src/common/utils'
import * as serviceWorkerRegistration from 'src/serviceWorkerRegistration'
import NotFound from 'src/views/notfound/NotFound'

import { CallbackRoute } from '../guards/CallbackRoute'
import { InternalRoutes } from '../internalRoutes'

import { useSnackbar } from 'notistack'

export const Routes: FC = () => {
  /*
   * Hooks
   */
  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [newVersionAvailable, setNewVersionAvailable] = useState<boolean>(false)

  /*
   * Methods
   */
  const onServiceWorkerUpdate = () => {
    setNewVersionAvailable(true)
  }

  /*
   * Side Effects
   */
  useEffect(() => {
    snackbarUtils.setSnackBar(enqueueSnackbar, closeSnackbar)
    serviceWorkerRegistration.register({
      onUpdate: onServiceWorkerUpdate,
    })
  }, [closeSnackbar, enqueueSnackbar])

  /*
   * Render
   */
  return (
    <Box flexGrow={1} pt={14}>
      {newVersionAvailable && (
        <RfhAlert
          title={t('newVersionAlert.title')}
          icon={<NotificationsIcon />}
          messageText={t('newVersionAlert.text')}
        />
      )}
      <OnlineOfflineAlert
        offlineMessage={t('onlineOfflineAlert.offline')}
        onlineMessage={t('onlineOfflineAlert.online')}
      />
      <Switch>
        {/* Workaround for Navigate while keeping the query params intact */}
        <Route
          path='/'
          exact
          component={({ location }: any) => (
            <Redirect
              to={{
                ...location,
                pathname: location.pathname.replace('/', '/dashboard'),
              }}
            />
          )}
        />
        <Route path='/logout' />
        <CallbackRoute
          path='/callback'
          component={(props: JSX.IntrinsicAttributes) => (
            <LoginCallback
              {...props}
              errorComponent={GenericError}
              loadingElement={
                <LoadingIconText
                  loadingText={t('common.loading')}
                  positionCenter
                />
              }
            />
          )}
        />
        <Route path='/dashboard' component={InternalRoutes} />
        <Route component={NotFound} />
      </Switch>
      <Cookiebar
        title={t('cookieBar.title')}
        messageText={t('cookieBar.text')}
        linkHref={t('cookieBar.link')}
        linkText={t('cookieBar.linkText')}
      />
    </Box>
  )
}
