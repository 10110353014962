import Box from '@mui/material/Box'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { ELEMENT_HEIGHT } from 'src/common/constants'

type HeaderTextInfoFieldProps = { title: string; text: string }

export default function HeaderTextInfoFieldx({
  title,
  text,
}: Readonly<HeaderTextInfoFieldProps>): JSX.Element {
  return (
    <Box>
      <Typography variant={'subheader'}>{title}</Typography>
      <Box
        aria-label={title}
        sx={{
          p: 1,
          mt: 1.5,
          height: ELEMENT_HEIGHT,
          minWidth: 120,
          maxWidth: 240,
          bgcolor: RfhColors.white,
        }}
      >
        {text}
      </Box>
    </Box>
  )
}
