import { useMemo } from 'react'

import { keyframes } from '@emotion/react'
import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { WSReadyState } from 'src/common/enums'

type ConnectivityIndicatorProps = {
  status?: WSReadyState
  sx?: SxProps
}

export default function ConnectivityIndicator({
  status,
  sx,
}: Readonly<ConnectivityIndicatorProps>): JSX.Element {
  const backgroundColor = useMemo(() => {
    if (status === WSReadyState.OPEN) {
      return RfhColors.hydroGreen
    }
    if (status === WSReadyState.CLOSED) {
      return RfhColors.alertRed
    }
    if (status === WSReadyState.CONNECTING) {
      return RfhColors.dutchOrange
    }
    return RfhColors.fogWhite
  }, [status])

  const switchValue = 33

  const colorChange = keyframes`
    0%, ${switchValue}% { background-color: ${RfhColors.fogWhite}; }
    ${switchValue}%, 50% { background-color: ${backgroundColor} }
    50%, ${100 - switchValue}% { background-color: ${backgroundColor} }
    ${100 - switchValue}%, 100% { background-color: ${RfhColors.fogWhite}; }
  `

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '24px',
        aspectRatio: '1 / 1',
        animation: `${colorChange} 2.5s ease-out infinite`,
        borderRadius: '50%',
        ...sx,
      }}
    />
  )
}
