import { Grid } from '@mui/material'
import { RfhTypography } from '@rfh/ui'

import {
  StyledGridSmall,
  StyledRfhTypographyText,
} from './ProductRegularityDetails.styles'

type ProductRegularityDetailsItemProps = {
  label: string
  value: any
}

export default function ProductRegularityDetailsItem({
  label,
  value,
}: Readonly<ProductRegularityDetailsItemProps>): JSX.Element {
  return (
    <StyledGridSmall container>
      <Grid item xs={12} sm={6}>
        <RfhTypography variant='subheader'>{label}</RfhTypography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <StyledRfhTypographyText variant='body-copy'>
          {value}
        </StyledRfhTypographyText>
      </Grid>
    </StyledGridSmall>
  )
}
