import { ChangeEvent, KeyboardEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  SxProps,
} from '@mui/material'
import { TextField } from '@rfh/ui/components/Inputs'
import { Button } from '@rfh/ui/components/RfhButton'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'
import SearchIcon from '@rfh/ui/shared/floriday-icons/icons/SearchIcon'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { Divider } from 'src/common/components'
import type { Item } from 'src/common/types'

import LookupGrid from './LookupGrid'

type LookupDialogProps = {
  getItems: () => Promise<Item<number>[]>
  buttonName?: string
  buttonSx?: SxProps
  disabled?: boolean
  searchTitle?: string
  trigger?: 'iconButton' | 'button'
  onChange?: (item: Item<number>) => void
}

export default function LookupDialog({
  getItems,
  buttonName = 'ADD',
  buttonSx,
  disabled = false,
  searchTitle,
  trigger = 'iconButton',
  onChange,
}: Readonly<LookupDialogProps>): JSX.Element {
  const { t } = useTranslation()
  const { handleSubmit } = useForm()
  const [lookup, setLookup] = useState<string>('')
  const [items, setItems] = useState<Item<number>[]>([])
  const [filteredItems, setFilteredItems] = useState<Item<number>[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)

  async function openDialog() {
    const fetchedItems = await getItems()
    setItems(fetchedItems)
    setFilteredItems(fetchedItems)
    setIsOpen(true)
  }

  function closeDialog() {
    setLookup('')
    setFilteredItems([] as Item<number>[])
    setIsOpen(false)
  }

  function onChangeFilter() {
    setFilteredItems(
      items.filter(item => {
        const testValue = isNaN(Number(lookup))
          ? item.name
          : item.code.toString()
        return testValue.toLowerCase().includes(lookup.toLowerCase())
      })
    )
  }

  function changeLookup(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    setLookup(event.target.value)
  }

  function handleUserKeyPress(event: KeyboardEvent<HTMLDivElement>): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmit(onChangeFilter)()
    }
  }

  function selectItem(item: Item<number>): void {
    onChange?.(item)
    closeDialog()
  }

  return (
    <>
      {trigger === 'iconButton' ? (
        <IconButton
          aria-label={'lookup icon button'}
          onClick={openDialog}
          sx={{ paddingX: 0.5 }}
        >
          <SearchIcon sx={{ color: RfhColors.darkOrange, height: 20, py: 0 }} />
        </IconButton>
      ) : (
        <Button
          disabled={disabled}
          onClick={openDialog}
          size='small'
          sx={buttonSx}
          variant='block--contained'
        >
          {buttonName}
        </Button>
      )}
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogContent
          sx={{
            width: '600px',
            maxWidth: '600px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              left: '1.5em',
              right: '2em',
              clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
              backgroundColor: 'white',
              paddingTop: '2em',
              zIndex: 1,
            }}
          >
            {/*TITLE*/}
            <Typography variant={'h5'} sx={{ marginBottom: '16px' }}>
              {searchTitle}
            </Typography>
            {/*SEARCH BAR*/}
            <form
              onSubmit={handleSubmit(onChangeFilter)}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <TextField
                id='lookup-field'
                name='item-name'
                placeholder={'code of naam'}
                autoFocus
                fullWidth
                onChange={changeLookup}
                onKeyPress={handleUserKeyPress}
                fieldColor='dark'
              />
              <Button
                variant='block--contained'
                onClick={handleSubmit(onChangeFilter)}
                isLoading={false}
              >
                {t('common.search')}
              </Button>
            </form>
            <Divider
              {...{
                marginTop: '1em',
                marginBottom: 0,
              }}
            />
          </Box>
          {/*GRID WITH LOOKUP DATA*/}
          <LookupGrid items={filteredItems} selectItem={selectItem} />
        </DialogContent>
        {/*BACK BUTTON*/}
        <DialogActions>
          <Button
            variant='text--underlined'
            onClick={closeDialog}
            sx={{
              marginBottom: 2,
              marginRight: 2.4,
            }}
          >
            {t('common.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
