import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useStore } from 'zustand'

import {
  ActionButton,
  FilterButtonsStack,
  FilterFieldsStack,
  FilterStack,
  HeaderTextInfoField,
} from 'src/common/components'
import { allLocations } from 'src/common/constants'
import { noOp } from 'src/common/lib'
import type { BloemenPlanten } from 'src/common/types'

import { useAllocationStore } from '../stores'

type CommonFilterProps = {
  saveDisabled?: boolean
  onClick?: () => void
}

export default function CommonFilter({
  saveDisabled = false,
  onClick = noOp,
  ...rest
}: Readonly<CommonFilterProps>): JSX.Element {
  const { t } = useTranslation()
  const history = useHistory()
  const allocation = useStore(useAllocationStore, state => state.allocation)

  function bloemenPlantenCode(): string {
    if (!allocation?.bloemenPlantenCode) return ''
    return allocation.bloemenPlantenCode === ('B' as BloemenPlanten)
      ? t('productCluster.flowers')
      : t('productCluster.plants')
  }

  function vestigingNaam(): string {
    if (!allocation?.vestigingCode) return ''
    const indelingView = allLocations.find(
      loc => loc.code === allocation.vestigingCode
    )
    return indelingView?.name
  }

  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <FilterStack {...rest}>
      <FilterFieldsStack>
        <HeaderTextInfoField
          title={t('productCluster.flowersPlants')}
          text={bloemenPlantenCode()}
        />
        <HeaderTextInfoField
          title={t('common.productCluster')}
          text={allocation?.productclusterOmschrijving}
        />
        <HeaderTextInfoField
          title={t('common.auctionLocation')}
          text={vestigingNaam()}
        />
      </FilterFieldsStack>
      <FilterButtonsStack>
        <ActionButton variant={'block--outlined'} onClick={handleGoBack}>
          {t('common.back')}
        </ActionButton>
        <ActionButton type='submit' disabled={saveDisabled} onClick={onClick}>
          {t('common.save')}
        </ActionButton>
      </FilterButtonsStack>
    </FilterStack>
  )
}
