import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query'

import { useApiClient } from 'src/common/providers'
import type { ListAndCount } from 'src/common/types'

import type { SearchHistoryFilterType, SearchHistoryFuncType } from './lib'
import {
  Entity,
  FuncSort,
  getFuncByEntity,
  hasPropertyAndNotNull,
  placeholderData,
} from './lib'

export default function useSearchHistory(
  filter: SearchHistoryFilterType,
  enabled = true,
  extraOptions?: Partial<UseQueryOptions>
): Readonly<
  UseQueryResult<ListAndCount<typeof Entity.ProductRegelmatig>, unknown>
> {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(
    FuncSort.search,
    Entity.ProductRegelmatig
  ) as SearchHistoryFuncType
  const queryResult = useQuery({
    queryKey: [filter],
    enabled:
      enabled &&
      Boolean(apiClient) &&
      Boolean(filter) &&
      hasPropertyAndNotNull(apiClient, func),
    keepPreviousData: true,
    placeholderData,
    queryFn: () =>
      apiClient[func](
        filter.vestigingCode,
        filter.aanvoerderNummer,
        filter.productCode
      ) as Promise<any>,
    select: (result: any) => ({
      records: result?.value || [],
      count: result['@odata.count'] ?? 0,
    }),
    ...extraOptions,
  }) as UseQueryResult<ListAndCount<typeof Entity.ProductRegelmatig>, unknown>

  return queryResult
}
