import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import type { Item } from 'src/common/types'

type Props = {
  onChange?: (event: SelectChangeEvent<string>) => void
  label?: string
  disabled?: boolean
  fieldName?: string
  values?: Item<string>[]
  value?: string
  sx?: SxProps
}

export default function LabelDropdownText({
  disabled,
  fieldName,
  label,
  onChange,
  values,
  value,
  sx,
}: Readonly<Props>): JSX.Element {
  const { t } = useTranslation()
  return (
    <Grid
      container
      item
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      sx={sx}
    >
      <Grid item>
        <FormControl fullWidth>
          <FormLabel
            htmlFor={`id-${fieldName}`}
            sx={{ color: RfhColors.black, whiteSpace: 'nowrap', minWidth: 170 }}
          >
            {label}
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <Select
            value={value ?? ''}
            defaultValue=''
            onChange={onChange}
            disabled={disabled}
            id={`id-${fieldName}`}
            name={fieldName}
            autoWidth
            sx={{
              minWidth: 160,
              bgcolor: RfhColors.white,
              '.MuiSelect-select': { ml: 1 },
            }}
          >
            <MenuItem value=''></MenuItem>
            {values?.length === 0 ? (
              <MenuItem value=''>{t('common.noResults')}</MenuItem>
            ) : null}
            {values?.map(item => (
              <MenuItem key={item.code} value={item.code}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
