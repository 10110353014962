import { FC, memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-premium'
import DeleteIcon from '@rfh/ui/shared/floriday-icons/icons/DeleteIcon'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { useStore } from 'zustand'

import {
  ActionModal,
  ButtonsBlock,
  CustomDataGrid,
} from 'src/common/components'
import { ThemeConfig } from 'src/common/config'
import { ELEMENT_HEIGHT, GRID_MARGIN_TOP } from 'src/common/constants'
import { byBloemenPlantenFilter } from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import {
  IProductclusterProductgroep,
  IProductgroepenReport,
} from 'src/common/services/client'
import { SnackbarMessage, snackbarUtils } from 'src/common/utils'

import { PRODUCTGROEPEN_CACHE_KEY, defaultSortModel } from '../constants'
import {
  calcMarginRight,
  getStaticProductgroepenColumns,
  mergeProductgroepen,
} from '../lib'
import { magProductgroepWordenVerwijderd } from '../services'
import { useProductClusterStore, useProductClustersStore } from '../stores'
import { ProductGroupsAddDialog } from './ProductGroupsAddDialog'

import { v4 as getRandomID } from 'uuid'

type ProductGroupsGridProps = {
  loading?: boolean
}

export const ProductGroupsGrid: FC<ProductGroupsGridProps> = memo(
  ({ loading = false, ...rest }) => {
    const translationsKey = 'productCluster.productGroup.deleteDialog'
    const marginRight = calcMarginRight()
    const { i18n, t } = useTranslation()
    const { apiClient } = useApiClient()
    const userId = useUser().sub
    const {
      cluster,
      deletedProductgroepen,
      productclusterProductgroepen,
      removeProductgroep,
      setDeletedProductgroepen,
      setProductgroepen,
      setProductgroepenHaveChanged,
    } = useProductClusterStore()
    const bloemenPlanten = useStore(
      useProductClustersStore,
      state => state.bloemenPlanten
    )
    const [open, setOpen] = useState<boolean>(false)
    const [codeToBeDeleted, setCodeToBeDeleted] = useState<number>(0)
    const [sortModel, setSortModel] = useState(() => defaultSortModel)

    const openDeleteDialog = useCallback((row: IProductclusterProductgroep) => {
      setCodeToBeDeleted(row.productgroepCode)
      setOpen(true)
    }, [])

    const closeDeleteDialog = useCallback(() => {
      setCodeToBeDeleted(0)
      setOpen(false)
    }, [])

    const handleChangeSortModel = (newSortModel: GridSortModel) => {
      setSortModel(newSortModel)
    }

    const getRowId = useCallback(() => getRandomID(), [])

    /**
     * handleAddProductgroepen adds entities only to the store
     * persisting the change goes via a cluster update
     */
    const handleAddProductgroepen = useCallback(
      (newProductgroepen: IProductgroepenReport[]) => {
        const mergedProductgroepen = mergeProductgroepen(
          cluster?.productclusterID ?? 0,
          productclusterProductgroepen,
          deletedProductgroepen.filter(byBloemenPlantenFilter(bloemenPlanten)),
          newProductgroepen,
          userId
        )
        setProductgroepen(mergedProductgroepen)
        setDeletedProductgroepen(
          deletedProductgroepen.filter(
            dpg =>
              !mergedProductgroepen.find(
                mpg => mpg.productgroepCode === dpg.productgroepCode
              )
          )
        )
        setProductgroepenHaveChanged(true)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        bloemenPlanten,
        cluster?.productclusterID,
        deletedProductgroepen,
        productclusterProductgroepen,
        userId,
      ]
    )

    /**
     * handleDeleteProductgroep deletes entities only from the store
     * persisting the change goes via a cluster update (see ManageProductCluster)
     */
    const handleDeleteProductgroep = useCallback(async () => {
      if (!apiClient) return Promise.resolve()

      if (
        productclusterProductgroepen.findIndex(
          pcpg =>
            pcpg.productclusterProductgroepID > 0 &&
            pcpg.productgroepCode === codeToBeDeleted
        ) !== -1 // we want to delete a persisted child productgroep
      ) {
        const messages = await magProductgroepWordenVerwijderd(
          apiClient,
          cluster.productclusterID,
          codeToBeDeleted
        )
        if (messages.length > 0) {
          snackbarUtils.error(
            SnackbarMessage(
              messages,
              t(`${translationsKey}.deleteErrorMessage` as any)
            )
          )
          closeDeleteDialog()
          return
        }
      }
      removeProductgroep(codeToBeDeleted)
      setProductgroepenHaveChanged(true)
      closeDeleteDialog()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      apiClient,
      cluster?.productclusterID,
      codeToBeDeleted,
      productclusterProductgroepen,
      t,
    ])

    const handleDeleteClick = useCallback(
      (row: IProductclusterProductgroep) => (): void => {
        openDeleteDialog(row)
      },
      [openDeleteDialog]
    )

    const columns = useMemo((): GridColDef[] => {
      const columnsSet = getStaticProductgroepenColumns()
      // DELETE icon
      columnsSet.push({
        field: 'delete',
        disableColumnMenu: true,
        type: 'actions',
        hideable: false,
        sortable: false,
        renderHeader: () => (
          <DeleteIcon
            sx={{
              color: RfhColors.fogWhite,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams) => (
          <DeleteIcon
            onClick={handleDeleteClick(params.row)}
            sx={{
              cursor: 'pointer',
              color: RfhColors.darkGrey,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        flex: 1,
      })
      return columnsSet
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleDeleteClick, i18n.language])

    return (
      <Grid container marginTop={GRID_MARGIN_TOP} {...rest}>
        <Grid item xs={12} height={0} zIndex={1}>
          <ButtonsBlock marginRight={marginRight}>
            <ProductGroupsAddDialog
              apiClient={apiClient}
              buttonSx={{
                height: ELEMENT_HEIGHT,
                width: ThemeConfig.spacing.xl * 16,
              }}
              onChange={newProductgroepen =>
                handleAddProductgroepen(newProductgroepen)
              }
              showNumber
              trigger={'button'}
            />
          </ButtonsBlock>
        </Grid>
        <Grid item xs={12}>
          <CustomDataGrid
            cacheKey={PRODUCTGROEPEN_CACHE_KEY}
            changeSortModel={handleChangeSortModel}
            columns={columns}
            getRowId={getRowId}
            gridProps={{
              loading,
              rowCount: productclusterProductgroepen.length,
              ...sortModel,
            }}
            hideHorizontalScrollbar
            language={i18n.language}
            pagination={false}
            paginationMode={'client'}
            rows={productclusterProductgroepen}
            sortingMode={'client'}
          />
        </Grid>
        <Grid item xs={12}>
          <ActionModal
            onCancel={closeDeleteDialog}
            onConfirm={handleDeleteProductgroep}
            open={open}
            translationsKey={translationsKey}
          />
        </Grid>
      </Grid>
    )
  }
)
