import { GridColDef } from '@mui/x-data-grid-premium'

import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { mergeArraysByKey } from 'src/common/lib'
import {
  IProductclusterProductgroep,
  IProductclusterView,
  IProductgroepenReport,
} from 'src/common/services/client'
import { formatDate } from 'src/common/utils'
import i18n from 'src/i18n'

import { toIProductclusterProductgroep } from './lib.maps'
import { byProductgroepIdSort } from './lib.sort'

export const calcMarginRight = (): number => ThemeConfig.spacing.l * 4.64

export function buildEersteProductgroepenString(
  productgroepen: IProductclusterProductgroep[] = []
): string {
  if (productgroepen.length === 0) return ''

  const sorted = [...productgroepen]
  sorted.sort(byProductgroepIdSort)

  const addCodesReducer = (
    acc: string,
    curr: IProductclusterProductgroep,
    index: number,
    arr: IProductclusterProductgroep[]
  ): string => {
    if (index < 3) {
      return acc + curr.productgroepCode.toString() + ', '
    } else if (index === 3) {
      arr.splice(1)
      return acc + '...'
    }
  }
  const firstThree = sorted.slice(0).reduce<string>(addCodesReducer, '')
  return firstThree
}

export function upsertCluster(
  clusters: IProductclusterView[],
  cluster: IProductclusterView
): IProductclusterView[] {
  if (!cluster) return clusters ?? ([] as IProductclusterView[])
  if (!clusters || clusters.length === 0) return [cluster]

  const index = clusters.findIndex(
    cl => cl.productclusterID === cluster.productclusterID
  )
  if (index === -1) return [...clusters, cluster]
  return [...clusters.slice(0, index), cluster, ...clusters.slice(index + 1)]
}

export const getStaticClustersColumns = (): GridColDef[] => {
  const columns: GridColDef[] = []
  columns.push({
    field: 'productclusterOmschrijving',
    headerName: i18n.t('common.description'), // Omschrijving
    headerAlign: 'left',
    align: 'left',
    flex: 3,
  })
  columns.push({
    field: 'eersteProductgroepen',
    headerName: i18n.t('productCluster.productGroups'), // Productgroepen
    headerAlign: 'left',
    align: 'left',
    flex: 4,
  })
  columns.push({
    field: 'mutatieDatumTijd',
    headerName: i18n.t('common.mutationDateTime'), // Gemuteerd
    headerAlign: 'left',
    align: 'left',
    flex: 2,
    valueFormatter: date => formatDate(date, 'dd-MM-yyyy HH:mm:ss'),
  })
  columns.push({
    field: 'mutatieGebruiker',
    headerName: i18n.t('common.mutationUser'), // Door
    headerAlign: 'left',
    align: 'left',
    flex: 2,
  })
  return columns
}

export const getStaticProductgroepenColumns = (): GridColDef[] => {
  const columns: GridColDef[] = []
  columns.push({
    field: 'productgroepCode',
    headerName: i18n.t('common.code'), // Code
    headerAlign: 'left',
    align: 'left',
    flex: 3,
  })
  columns.push({
    field: 'productgroepOmschrijving',
    headerName: i18n.t('common.description'), // Omschrijving
    headerAlign: 'left',
    align: 'left',
    flex: 5,
  })
  columns.push({
    field: 'mutatieDatumTijd',
    headerName: i18n.t('common.mutationDateTime'), // Gemuteerd
    headerAlign: 'left',
    align: 'left',
    flex: 3,
    valueFormatter: date => formatDate(date, 'dd-MM-yyyy HH:mm:ss'),
  })
  columns.push({
    field: 'mutatieGebruiker',
    headerName: i18n.t('common.mutationUser'), // Door
    headerAlign: 'left',
    align: 'left',
    flex: 3,
  })
  return columns
}

export const mergeProductgroepen = (
  clusterID: number,
  prevProductgroepen: IProductclusterProductgroep[],
  deletedProductgroepen: IProductclusterProductgroep[],
  newProductgroepen: IProductgroepenReport[],
  user: string
): IProductclusterProductgroep[] => {
  const mergedByUniqueProductgroepCode = mergeArraysByKey(
    prevProductgroepen,
    deletedProductgroepen,
    'productgroepCode'
  )
  const filteredNewProductgroepen = newProductgroepen.filter(
    npg =>
      !mergedByUniqueProductgroepCode.find(
        ppg => ppg.productgroepCode === npg.productGroepCode
      )
  )

  return [
    ...mergedByUniqueProductgroepCode,
    ...filteredNewProductgroepen
      .map(toIProductclusterProductgroep)
      .map(fnpg => ({
        ...fnpg,
        productclusterID: clusterID,
        aanmaakDatumTijd: new Date(),
        aanmaakGebruiker: user,
        mutatieDatumTijd: new Date(),
        mutatieGebruiker: user,
      })),
  ]
}
export const getExtraReportsFromDeleted = (
  allowed: IProductgroepenReport[],
  deleted: IProductclusterProductgroep[]
): IProductgroepenReport[] =>
  deleted
    .map(dpg => ({
      productGroepCode: dpg.productgroepCode,
      productGroepOmschrijving: dpg.productgroepOmschrijving,
      productGroepGeselecteerd: false,
    }))
    .filter(
      dpg => !allowed.find(apg => apg.productGroepCode === dpg.productGroepCode)
    )
