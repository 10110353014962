import { Entity } from './lib.entities'
import type { EntityType, FuncSortType, FuncType } from './lib.funcTypes'
import {
  FuncSort,
  GetFunc,
  ListFunc,
  SearchBatchesFunc,
  SearchCountsFunc,
  SearchHistoryFunc,
} from './lib.funcs'

export const getFuncByEntity = (
  funcSort: FuncSortType,
  entity: EntityType
): FuncType => {
  let func: FuncType
  switch (funcSort) {
    case FuncSort.get:
      if (entity === Entity.ProductRegelmatig) {
        func = GetFunc.getProductRegelmatig
      }
      break
    case FuncSort.list:
      switch (entity) {
        case Entity.LocationClock:
          func = ListFunc.listTribuneKlok
          break
        case Entity.ProductRegelmatig:
          func = ListFunc.listProductRegelmatig
          break
        case Entity.ProductRegelmatigUitzondering:
          func = ListFunc.listProductRegelmatigUitzondering
          break
      }
      break
    case FuncSort.search:
      if (entity === Entity.AuctionGroupCount) {
        func = SearchCountsFunc.zoekVeilgroepTellingenPerVeildatumVestiging
      } else if (entity === Entity.BatchesStatus) {
        func = SearchBatchesFunc.zoekPartijStatussenPerVeildatumVestiging
      } else if (entity === Entity.ProductRegelmatig) {
        func = SearchHistoryFunc.zoekHistorieRegelmatig
      }
      break
  }

  return func
}
