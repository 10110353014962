import { TableCell, TableHead, TableRow } from '@mui/material'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { getHeaderCellStyles } from './lib'

export default function LogisticsDashboardGridHeader(): JSX.Element {
  return (
    <TableHead>
      <TableRow sx={{ background: RfhColors.leafGreen }}>
        <TableCell style={{ ...getHeaderCellStyles('left'), width: '18%' }}>
          Vestiging
        </TableCell>
        <TableCell style={{ ...getHeaderCellStyles('left'), width: '17%' }}>
          Klok soort
        </TableCell>
        <TableCell style={getHeaderCellStyles()}>Aantal karren</TableCell>
        <TableCell style={getHeaderCellStyles()}>
          Aantal karren ontvangen
        </TableCell>
        <TableCell style={getHeaderCellStyles()}>
          Aantal karren nog te ontvangen
        </TableCell>
        <TableCell style={getHeaderCellStyles()}>
          Aantal karren in neerzet volgorde
        </TableCell>
        <TableCell style={getHeaderCellStyles()}>
          Aantal karren niet in neerzet volgorde
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
