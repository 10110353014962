import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Grid } from '@mui/material'
import {
  GridActionsCellItem,
  GridColDef,
  GridPaginationInitialState,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-premium'
import { DeleteIcon, EditIcon } from '@rfh/ui/shared/floriday-icons'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import {
  ActionButton,
  ActionModal,
  ButtonsBlock,
  CustomDataGrid,
} from 'src/common/components'
import { GRID_MARGIN_TOP } from 'src/common/constants'
import { useApiClient, useUser } from 'src/common/providers'
import { IAanvoerderPromotieKenmerk } from 'src/common/services/client'
import { snackbarUtils, utcToday } from 'src/common/utils'
import i18n from 'src/i18n'

import {
  CHARACTERISTICS_CACHE_KEY,
  DELETE_TRANSLATION_KEY,
  FEATURE_ROOT_PATH,
} from '../constants'
import { calcMarginRight, getStaticColumns } from '../lib'
import { deleteCharacteristic } from '../services'
import { useSupplierPromotionCharacteristicsStore } from '../stores'

import { isEqual } from 'lodash'

type CharacteristicsGridProps = {
  loading: boolean
}

export default function CharacteristicsGrid({
  loading = false,
  ...rest
}: Readonly<CharacteristicsGridProps>): JSX.Element {
  const marginRight = calcMarginRight()
  const history = useHistory()
  const { apiClient } = useApiClient()
  const { t } = useTranslation()
  const isAuctionCoordinator = useUser().isAuctionCoordinator
  const {
    characteristics,
    numberOfCharacticeristics,
    pagination,
    sorting,
    setPagination,
    setSorting,
    removeCharacteristic,
    setMustRefetchCharacteristics,
  } = useSupplierPromotionCharacteristicsStore()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [idToBeDeleted, setIdToBeDeleted] = useState(0)

  function changePageModel(input: GridPaginationInitialState) {
    if (isEqual(input, pagination)) return
    setPagination(input)
  }

  function changeSorting(input: GridSortModel) {
    if (isEqual(input, sorting)) return
    setSorting(input)
  }

  const isExpired = useCallback((date: Date): boolean => date < utcToday(), [])

  const getRowId = useCallback(
    (row: IAanvoerderPromotieKenmerk) => row.aanvoerderPromotieKenmerkId,
    []
  )

  const handleEditClick = useCallback(
    (row: IAanvoerderPromotieKenmerk) => (): void => {
      history.push(
        `/dashboard/${FEATURE_ROOT_PATH}/${row.aanvoerderPromotieKenmerkId}`
      )
    },
    [history]
  )
  const handleDeleteClick = useCallback(
    (row: IAanvoerderPromotieKenmerk) => (): void => {
      setIdToBeDeleted(row.aanvoerderPromotieKenmerkId)
      setOpenDeleteDialog(true)
    },
    [setIdToBeDeleted, setOpenDeleteDialog]
  )
  const closeDeleteDialog = useCallback((): void => {
    setOpenDeleteDialog(false)
    setIdToBeDeleted(0)
  }, [setOpenDeleteDialog, setIdToBeDeleted])

  const handleConfirm = useCallback(async () => {
    if (!apiClient) {
      return Promise.resolve()
    }
    try {
      closeDeleteDialog()
      await deleteCharacteristic(apiClient, idToBeDeleted)
      removeCharacteristic(idToBeDeleted)
      snackbarUtils.success(t(`${DELETE_TRANSLATION_KEY}.deleteSuccessMessage`))
    } catch (error: any) {
      snackbarUtils.error(String(error))
    } finally {
      setMustRefetchCharacteristics(true)
    }
  }, [
    apiClient,
    closeDeleteDialog,
    idToBeDeleted,
    removeCharacteristic,
    setMustRefetchCharacteristics,
    t,
  ])

  const columns = useMemo((): GridColDef[] => {
    const columnsSet = getStaticColumns()
    if (isAuctionCoordinator) {
      // EDIT icon
      columnsSet.push({
        field: 'edit',
        disableColumnMenu: true,
        type: 'actions',
        hideable: false,
        sortable: false,
        renderHeader: () => (
          <EditIcon
            sx={{
              color: RfhColors.fogWhite,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        getActions: ({ id, row }: GridRowParams) => [
          <GridActionsCellItem
            className='textPrimary'
            color='inherit'
            disabled={isExpired(row.eindDatum)}
            icon={
              <EditIcon
                sx={{
                  cursor: 'pointer',
                  color: RfhColors.darkGrey,
                  width: '20px',
                  height: '20px',
                }}
              />
            }
            key={id}
            label='Edit'
            onClick={handleEditClick(row)}
          />,
        ],
        flex: 0.5,
      })
      // DELETE icon
      columnsSet.push({
        field: 'delete',
        disableColumnMenu: true,
        type: 'actions',
        hideable: false,
        sortable: false,
        renderHeader: () => (
          <DeleteIcon
            sx={{
              color: RfhColors.fogWhite,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            className='textPrimary'
            color='inherit'
            disabled={isExpired(row.ingangsDatum)}
            icon={
              <DeleteIcon
                sx={{
                  cursor: 'pointer',
                  color: RfhColors.darkGrey,
                  width: '20px',
                  height: '20px',
                }}
              />
            }
            key={id}
            label='delete'
            onClick={handleDeleteClick(row)}
          />,
        ],
        flex: 0.5,
      })
    }

    return columnsSet
  }, [handleDeleteClick, handleEditClick, isAuctionCoordinator, isExpired])

  return (
    <Grid container marginTop={GRID_MARGIN_TOP} {...rest}>
      <Grid item xs={12} height={0} zIndex={1}>
        <ButtonsBlock marginRight={marginRight}>
          <ActionButton
            onClick={() => history.push(`/dashboard/${FEATURE_ROOT_PATH}/add`)}
          >
            {t('common.add')}
          </ActionButton>
        </ButtonsBlock>
      </Grid>
      <Grid item xs={12}>
        <CustomDataGrid
          rows={characteristics}
          cacheKey={CHARACTERISTICS_CACHE_KEY}
          language={i18n.language}
          columns={columns}
          getRowId={getRowId}
          changeGridPaginationInitialState={changePageModel}
          changeSortModel={changeSorting}
          pagination
          gridProps={{
            rowCount: numberOfCharacticeristics,
            pageSize: pagination?.paginationModel?.pageSize,
            page: pagination?.paginationModel?.page,
            loading,
          }}
        />
        <ActionModal
          open={openDeleteDialog}
          onCancel={closeDeleteDialog}
          translationsKey={DELETE_TRANSLATION_KEY}
          onConfirm={handleConfirm}
        />
      </Grid>
    </Grid>
  )
}
