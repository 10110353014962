import { ChangeEvent, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Box, Container, Grid, Stack, Typography } from '@mui/material'

import {
  ActionButton,
  AvailableSupplierInput,
  ButtonsBlock,
  Divider,
  LocationSelect,
} from 'src/common/components'
import { BUTTONS_GAP } from 'src/common/constants'
import { initialState, refreshReducer } from 'src/common/lib'
import { useApiClient } from 'src/common/providers'
import { snackbarUtils } from 'src/common/utils'

import { CustomLabel } from '../components'
import { addIrregularSupplier } from '../services/irregularSuppliersService'

export default function AddIrregularSupplier(): JSX.Element {
  const { t } = useTranslation()
  const [supplierCode, setSupplierCode] = useState(0)
  const [locationCode, setLocationCode] = useState(0)
  const { apiClient } = useApiClient()
  const history = useHistory()
  const [state, dispatch] = useReducer(refreshReducer, initialState)

  const handleChangeLocation = (
    event: ChangeEvent<HTMLSelectElement>
  ): void => {
    setLocationCode(parseInt(event.target.value, 10))
  }

  const handleChangeSupplier = (id: number) => {
    setSupplierCode(id)
  }

  const handleChangeValid = (bool: boolean): void => void 0

  const handleAddIrregularSupplier = async () => {
    try {
      dispatch({ type: 'PENDING' })
      await addIrregularSupplier(apiClient, {
        vestigingCode: locationCode,
        aanvoerderNummer: supplierCode,
      })
    } catch (error: any) {
      snackbarUtils.error(String(error))
    }
    dispatch({ type: 'RESOLVED' })
    history.push('/dashboard/irregular-suppliers')
  }

  return (
    <Container maxWidth='xl'>
      <Box mt={4} mb={2}>
        <Typography variant='h3' style={{ fontWeight: 'bold' }}>
          {t('common.titles.irregularSuppliers')}
        </Typography>
      </Box>
      <Grid item xs={12}>
        <Stack
          direction={'row'}
          justifyContent={'flex-start'}
          alignItems={'end'}
          gap={BUTTONS_GAP}
        >
          <LocationSelect
            onChange={handleChangeLocation}
            customLabel={CustomLabel}
            addCatchAll
          />
          <AvailableSupplierInput
            changeSupplierNumber={handleChangeSupplier}
            changeValid={handleChangeValid}
            value={supplierCode}
            showNumberInDialog
            sx={{ width: 180 }}
          />
        </Stack>
      </Grid>
      <Divider {...{ marginTop: 16 }} />
      <Grid item xs={12}>
        <ButtonsBlock marginRight={0.3}>
          <ActionButton
            variant={'block--outlined'}
            onClick={() => history.goBack()}
          >
            {t('common.back')}
          </ActionButton>
          <ActionButton
            type='submit'
            disabled={!locationCode || !supplierCode}
            isLoading={state.loading}
            onClick={handleAddIrregularSupplier}
          >
            {t('common.add')}
          </ActionButton>
        </ButtonsBlock>
      </Grid>
    </Container>
  )
}
