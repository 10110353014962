import { Box, Container } from '@mui/material'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import ClocksStatusGrid from './ClocksStatusGrid'

export default function ClocksStatus(): JSX.Element {
  return (
    <Container maxWidth={'xs'}>
      <Box textAlign='center' pt={0} mt={0}>
        <Typography
          aria-label={'Logistics Dashboard'}
          variant={'h2'}
          sx={{ color: RfhColors.leafGreen, mb: '0.5em', mt: 0, p: 0 }}
        >
          {'Klokkenstatus'}
        </Typography>
      </Box>
      <ClocksStatusGrid />
    </Container>
  )
}
