/* eslint-disable max-len */
import { IApiClientListFilter } from 'src/common/interfaces'
import {
  Aanvoerdercluster,
  AanvoerderclusterAanvoerder,
  IAanvoerdercluster,
  IAanvoerderclusterAanvoerder,
  IAanvoerderclusterView,
  IClient,
  _expand,
} from 'src/common/services/client'

import { byAanvoerderclusterNummer } from '../lib'

export const getClustersAsync = async (
  apiClient: IClient,
  apiClientFilter: Partial<IApiClientListFilter>
): Promise<IAanvoerderclusterView[]> => {
  const result =
    (await apiClient.aanvoerderclusterViews_AanvoerderclusterView_ListAanvoerderclusterView(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      apiClientFilter.expand
    )) as any
  const records: IAanvoerderclusterView[] = result?.cleanedResponse?.value || []
  records.sort(byAanvoerderclusterNummer)
  return records
}

export const getClusterAsync = async (
  apiClient: IClient,
  key?: number
): Promise<IAanvoerderclusterView> => {
  const result =
    (await apiClient.aanvoerderclusterViews_AanvoerderclusterView_GetAanvoerderclusterView2(
      key,
      undefined,
      ['aanvoerderclusterAanvoerders' as _expand]
    )) as any

  const record: IAanvoerderclusterView = result?.cleanedResponse || null
  return record
}

export const addClusterAsync = async (
  apiClient: IClient,
  newEntity: Aanvoerdercluster
): Promise<IAanvoerdercluster> => {
  const result =
    (await apiClient.aanvoerderclusters_Aanvoerdercluster_CreateAanvoerdercluster(
      newEntity
    )) as any
  return result?.cleanedResponse as IAanvoerdercluster
}

export const updateClusterAsync = async (
  apiClient: IClient,
  newEntity: Aanvoerdercluster
): Promise<void> => {
  await apiClient.aanvoerderclusters_Aanvoerdercluster_UpdateAanvoerdercluster(
    newEntity.aanvoerderclusterID,
    newEntity
  )
}

export const deleteClusterAsync = async (
  apiClient: IClient,
  idToBeDeleted: number
): Promise<void> => {
  await apiClient.aanvoerderclusters_Aanvoerdercluster_DeleteAanvoerdercluster2(
    idToBeDeleted,
    '*' //Fix: IF-Match header Bug, alle resources mogen nu matchen.
  )
}

export const addClusterAanvoerderAsync = async (
  apiClient: IClient,
  newEntity: AanvoerderclusterAanvoerder
): Promise<IAanvoerderclusterAanvoerder> => {
  const result =
    (await apiClient.aanvoerderclusterAanvoerders_AanvoerderclusterAanvoerder_CreateAanvoerderclusterAanvoerder(
      newEntity
    )) as any
  return result?.cleanedResponse as IAanvoerderclusterAanvoerder
}

export const deleteClusterAanvoerderAsync = async (
  apiClient: IClient,
  idToBeDeleted: number
): Promise<void> => {
  await apiClient.aanvoerderclusterAanvoerders_AanvoerderclusterAanvoerder_DeleteAanvoerderclusterAanvoerder2(
    idToBeDeleted,
    '*' //Fix: IF-Match header Bug, alle resources mogen nu matchen.
  )
}

export const updateClusterAanvoerderAsync = async (
  apiClient: IClient,
  newEntity: AanvoerderclusterAanvoerder
): Promise<IAanvoerderclusterAanvoerder> => {
  await deleteClusterAanvoerderAsync(
    apiClient,
    newEntity.aanvoerderclusterAanvoerderId
  )
  newEntity.aanvoerderclusterAanvoerderId = 0
  return await addClusterAanvoerderAsync(apiClient, newEntity)
}
