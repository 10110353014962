import { FC, ReactChild, ReactFragment, ReactPortal } from 'react'

import Grid from '@mui/material/Grid'

import { ThemeConfig } from 'src/common/config'
import { alignColumns } from 'src/common/lib'
import type { Align } from 'src/common/types'

type StyledGridCardProps = {
  aligns?: Align[]
  lg: number
  xs: number
  children: boolean | ReactChild | ReactFragment | ReactPortal
}

export const StyledGridCard: FC<StyledGridCardProps> = ({
  aligns,
  lg,
  xs,
  children,
}) => (
  <Grid
    item
    lg={lg}
    xs={xs}
    sx={{
      ...alignColumns(aligns),
      padding: ThemeConfig.spacing.sm,
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    }}
  >
    {children}
  </Grid>
)
