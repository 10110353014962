import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react'

import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import TextField from '@rfh/ui/components/Inputs/TextField'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { getStringValue } from 'src/common/lib'

type LabelInputIconTextProps = {
  disabled?: boolean
  fieldName?: string
  inputValue?: string | number
  label?: string
  textValue?: string
  onChange?: (newInput: string) => void
  children?: ReactNode
}

export default function LabelInputIconText({
  disabled = false,
  fieldName = '',
  inputValue = '',
  label = '',
  textValue = '',
  onChange,
  children,
}: Readonly<LabelInputIconTextProps>): JSX.Element {
  const [inputText, setInputText] = useState<string>(getStringValue(inputValue))

  const handleChangeLocally = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setInputText(event.target.value)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onChange(event.target?.value ?? '')
  }

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onChange(inputText)
    }
  }

  useEffect(() => {
    setInputText(getStringValue(inputValue))
  }, [inputValue])

  return (
    <Grid
      container
      item
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      <Grid item>
        <FormControl fullWidth>
          <FormLabel
            htmlFor={`id-${fieldName}`}
            sx={{ color: RfhColors.black, whiteSpace: 'nowrap', minWidth: 170 }}
          >
            {label}
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <TextField
            id={`id-${fieldName}`}
            name={fieldName}
            value={inputText}
            disabled={disabled}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            onChange={handleChangeLocally}
            sx={{ flex: 1, maxWidth: 160 }}
          />
        </FormControl>
      </Grid>
      <Grid item>
        {/* dialog + trigger button */}
        {children}
      </Grid>
      <Grid item xs={4}>
        {textValue?.trim()}
      </Grid>
    </Grid>
  )
}
