import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, capitalize } from '@mui/material'
import { RfhTypography as Typography } from '@rfh/ui'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import FullscreenDialog from 'src/common/components/FullscreenDialog'
import Spinner from 'src/common/components/Spinner'
import { StyledGridCard } from 'src/common/components/StyledGridCard'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import {
  Entity,
  SearchHistoryFilterType,
  useFetchOne,
  useSearchHistory,
} from 'src/common/hooks'

import { isRegularityDefined } from '../lib'
import { StyledBoxLoader } from './ProductRegularityDetails.styles'
import ProductRegularityDetailsItem from './ProductRegularityDetailsItem'
import ProductRegularityHistoryGrid from './ProductRegularityHistoryGrid'

type ProductRegelmatig = typeof Entity.ProductRegelmatig

type ProductRegularityDetailsProps = {
  open: boolean
  aanvoerderProductRegelmatigId: string
  onClose: () => void
}

export default function ProductRegularityDetails({
  aanvoerderProductRegelmatigId,
  open,
  onClose,
}: Readonly<ProductRegularityDetailsProps>): JSX.Element {
  const { t } = useTranslation()

  const { data: regularity, isError } = useFetchOne<ProductRegelmatig>(
    { key: aanvoerderProductRegelmatigId },
    Entity.ProductRegelmatig
  )

  const filter: SearchHistoryFilterType = useMemo(
    () =>
      isRegularityDefined(regularity)
        ? {
            vestigingCode: regularity.vestigingCode,
            aanvoerderNummer: regularity.aanvoerderNummer,
            productCode: regularity.productCode,
          }
        : null,
    [regularity]
  )

  const { data: history, isLoading } = useSearchHistory(filter)

  return (
    <FullscreenDialog
      title={t('common.titles.regularityDetails')}
      open={open}
      handleClose={onClose}
    >
      <Grid
        container
        sx={{
          width: '100%',
          marginLeft: 0,
          '& > .MuiGrid-root.MuiGrid-item': {
            paddingLeft: 0,
            width: '100%',
            '& .MuiBox-root': {
              backgroundColor: RfhColors.fogWhite,
              minHeight: 0,
              paddingLeft: 0,
            },
          },
        }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sx={{
            padding: '0 !important',
            my: ThemeConfig.spacing.sm,
          }}
        >
          <Typography variant={'h3'} sx={{ fontWeight: 'bold' }}>
            {t('common.titles.regularityDetails')}
          </Typography>
        </Grid>
        {regularity ? (
          <StyledGridCard lg={6} xs={12}>
            <StyledBoxLoader isCard={!!regularity}>
              <ProductRegularityDetailsItem
                label={t('overviews.supplierName')}
                value={`${regularity.aanvoerderNummer} ${
                  regularity.aanvoerderNaam ?? ''
                }`}
              />
              <ProductRegularityDetailsItem
                label={t('common.auctionLocation')}
                value={capitalize(regularity.vestigingNaam)}
              />
              <ProductRegularityDetailsItem
                label={t('overviews.productDescription')}
                value={` ${regularity.productCode} ${
                  regularity.productNaam ?? ''
                }`}
              />
            </StyledBoxLoader>
          </StyledGridCard>
        ) : (
          <Spinner isError={isError} />
        )}
        <ProductRegularityHistoryGrid
          history={history.records}
          isLoading={isLoading}
        />
      </Grid>
    </FullscreenDialog>
  )
}
