import { fallbackLng, supportedLanguages } from './i18n'

import type { CustomDetector } from 'i18next-browser-languagedetector'

export const customLanguageDetector: CustomDetector = {
  name: 'customLanguageDetector',

  lookup(_options) {
    const browserLng = navigator.language.substring(0, 2)
    return supportedLanguages.includes(browserLng) ? browserLng : fallbackLng
  },

  cacheUserLanguage(lng, _options) {
    localStorage.setItem('i18nextLng', lng)
  },
}
