/* eslint-disable id-blacklist */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { Box } from '@mui/material'
import {
  GridCellParams,
  GridColDef,
  GridInitialState,
  GridPaginationInitialState,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-premium'
import { RfhAlert } from '@rfh/ui'
import InfoIcon from '@rfh/ui/shared/floriday-icons/icons/InfoIcon'

import { BooleanTag, CustomDataGrid } from 'src/common/components'
import { GRID_MARGIN_TOP, MAX_NUMBER_COLS_SORTED } from 'src/common/constants'
import { IAanvoerderProductgroepRegelmatigView } from 'src/common/services/client'
import { formatDate, snackbarUtils } from 'src/common/utils'

import ProductGroupRegularityDetails from './ProductGroupRegularityDetails'

type ProductGroupRegularityGridProps = {
  cacheKey: string
  rows: IAanvoerderProductgroepRegelmatigView[]
  isLoading: boolean
  paginationStateChange: (value: GridPaginationInitialState) => void
  sortModelChange: (value: GridSortModel) => void
  rowCount: number
}

export default function ProductGroupRegularityGrid({
  cacheKey,
  rows,
  isLoading,
  paginationStateChange,
  sortModelChange,
  rowCount,
  ...rest
}: Readonly<ProductGroupRegularityGridProps>): JSX.Element {
  /*
  State
  */
  const [dialogOpen, setDialogOpen] = useState(false)
  const [hideAlertContainer, setHideAlertContainer] = useState(false)

  const initialState: GridInitialState = {
    columns: {
      columnVisibilityModel: {
        aanvoerderNummer: true,
        AanvoerderNaam: true,
        ProductgroepCode: true,
        ProductgroepNaam: true,
        vestigingCode: true,
        regelmatig: true,
        UitzonderingDatumVanaf: true,
        UitzonderingDatumTtm: true,
        UitzonderingRedenOmschrijving: true,
        UitzonderingRedenTekst: true,
      },
    },
  }

  /*
  Hooks
  */
  const { i18n, t } = useTranslation()
  const history = useHistory()
  const { aanvoerderProductgroepRegelmatigId }: any = useParams()

  /*
  Methods
  */
  const changeLocalStorageSortingModel = (value: GridSortModel) => {
    if (value?.length <= MAX_NUMBER_COLS_SORTED) {
      sortModelChange(value)
    } else {
      // To prevent abuse the maximum number of sorted columns is set to five in the backend
      // we catch this so we can show a friendlier message
      snackbarUtils.warning(t('overviews.maximumSorting'))
    }
  }

  const changePaginationState = (value: GridPaginationInitialState) => {
    paginationStateChange(value)
  }

  const goToRegularityDetails = useCallback(
    (params: GridCellParams) => {
      if (params.row.aanvoerderProductgroepRegelmatigId === undefined) {
        return
      }

      history.push(
        `/dashboard/supplier-product-group-regularity/${params.row.aanvoerderProductgroepRegelmatigId}`
      )
      setDialogOpen(true)
    },
    [history]
  )

  const closeRegularityDetails = () => {
    history.push('/dashboard/supplier-product-group-regularity')
    setDialogOpen(false)
  }

  /*
  Effects
  */
  useEffect(() => {
    if (aanvoerderProductgroepRegelmatigId) {
      goToRegularityDetails({
        row: { aanvoerderProductgroepRegelmatigId },
      } as any)
    }
  }, [aanvoerderProductgroepRegelmatigId, goToRegularityDetails])

  // Because we use server side sorting we only allow sorting when there are no more than 300.000 rows
  const maxSortedRows = 300000
  const sortingAllowed = rowCount <= maxSortedRows

  /*
  Columns
  */
  const getStaticColumns = (): GridColDef[] => {
    const newColumns: GridColDef[] = []
    newColumns.push({
      field: 'vestigingNaam',
      headerName: t('common.auctionLocation'),
      align: 'left',
      headerAlign: 'left',
      minWidth: 90,
      sortable: sortingAllowed,
      valueFormatter: ({ value }: { value: string }) =>
        value ? value.charAt(0).toUpperCase() + value.slice(1) : '',
    })
    newColumns.push({
      field: 'aanvoerderNummer',
      headerName: t('overviews.supplierNumber'),
      sortable: sortingAllowed,
      align: 'right',
      headerAlign: 'left',
      minWidth: 130,
    })
    newColumns.push({
      field: 'aanvoerderNaam',
      headerName: t('overviews.supplierName'),
      minWidth: 160,
      align: 'left',
      headerAlign: 'left',
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'productgroepCode',
      headerName: t('overviews.productGroupCode'),
      align: 'right',
      headerAlign: 'right',
      minWidth: 160,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'productgroepNaam',
      headerName: t('overviews.productGroupDescription'),
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      minWidth: 220,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'regelmatig',
      headerName: t('overviews.regularity'),
      sortable: sortingAllowed,
      align: 'center',
      headerAlign: 'left',
      minWidth: 110,
      aggregable: false,
      renderCell: (params: GridRenderCellParams) => (
        <BooleanTag value={params.row.regelmatig} />
      ),
    })
    newColumns.push({
      field: 'aantalDagenGeveild',
      headerName: t('overviews.auctionDays'),
      type: 'number',
      align: 'center',
      headerAlign: 'left',
      minWidth: 120,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'aantalDagenUitzondering',
      headerName: t('overviews.exceptionDays'),
      type: 'number',
      align: 'center',
      headerAlign: 'left',
      minWidth: 190,
      sortable: sortingAllowed,
      valueFormatter: ({ value }) => value ?? '0',
    })
    newColumns.push({
      field: 'datumEindeWeek',
      headerName: t('overviews.dateEndOfWeek'),
      align: 'left',
      headerAlign: 'left',
      minWidth: 160,
      sortable: sortingAllowed,
      valueFormatter: row =>
        row.value && formatDate(row, t('common.dateFormatShort')),
      groupingValueGetter: row =>
        row.value && formatDate(row, t('common.dateFormatShort')),
    })

    return newColumns
  }
  const columns = useMemo(
    () => getStaticColumns(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getStaticColumns, i18n.language]
  )

  /*
  Render
  */
  return (
    <>
      {!sortingAllowed && !hideAlertContainer && (
        <Box
          sx={{
            marginBottom: 2,
            width: '100%',
          }}
        >
          <RfhAlert
            bgColorVariant='dutchOrange'
            icon={<InfoIcon />}
            messageText={t('overviews.sortingDisabledAlertMessage')}
            title={t('overviews.sortingDisabledAlertTitle')}
            onClose={() => setHideAlertContainer(true)}
          />
        </Box>
      )}
      <Box position={'relative'} marginTop={GRID_MARGIN_TOP} {...rest}>
        <CustomDataGrid
          cacheKey={cacheKey}
          changeGridPaginationInitialState={changePaginationState}
          changeSortModel={changeLocalStorageSortingModel}
          columns={columns}
          rows={rows}
          initialState={initialState}
          language={i18n.language}
          getRowId={(row: IAanvoerderProductgroepRegelmatigView) =>
            row.aanvoerderProductgroepRegelmatigId
          }
          gridProps={{
            rowCount,
            onCellClick: goToRegularityDetails,
            loading: isLoading,
          }}
        />
      </Box>
      <ProductGroupRegularityDetails
        open={dialogOpen}
        aanvoerderProductgroepRegelmatigId={aanvoerderProductgroepRegelmatigId}
        onClose={closeRegularityDetails}
      ></ProductGroupRegularityDetails>
    </>
  )
}
