import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  RouteComponentProps,
  RouteProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import { SecureRoute } from '@okta/okta-react'
import LoadingIconText from '@rfh/ui/components/LoadingStates/LoadingIconText'

import { useUser } from 'src/common/providers/UserProvider'

type RenderProps = RouteComponentProps<
  {
    [x: string]: string
  },
  //! TS compiler will StaticContext but it seems not to be importable from react-router-dom
  unknown,
  unknown
>

type ConsulterRouteProps = RouteProps & {
  visible?: boolean
}

export const ConsulterRoute: FC<ConsulterRouteProps> = ({
  component: Component,
  visible,
  ...rest
}) => {
  const user = useUser()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const { t } = useTranslation()

  const getContent = (props: RenderProps): JSX.Element => {
    if (!user.isAuthenticated) {
      return (
        <LoadingIconText loadingText={t('common.loading')} positionCenter />
      )
    }

    // user is authenticated but the "visible" prop is passed as "false"
    // => nothing to do
    if (visible != null && !visible) {
      return
    }

    if (!(user.isAuctionCoordinator || user.isConsulter)) {
      console.warn('Unauthorized: redirecting to read only environment')
      history.push('/dashboard')
      return
    }

    // "visible" prop is not passed at all or passed as "true"
    // => show the Component
    return (
      <Component
        history={history}
        location={location}
        match={match}
        {...props}
      />
    )
  }

  return (
    <SecureRoute {...rest} render={(props: RenderProps) => getContent(props)} />
  )
}
