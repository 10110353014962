import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  RouteProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import { SecureRoute } from '@okta/okta-react'
import LoadingIconText from '@rfh/ui/components/LoadingStates/LoadingIconText'

import { useUser } from 'src/common/providers/UserProvider'

/**
 * Route that can only be accessed when a user is authenticated and a application manager, when
 * these conditions are not met you will be redirected to the base route.
 */
export const AuctionCoordinatorRoute: FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const user = useUser()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const { t } = useTranslation()

  const getContent = (props: any): JSX.Element => {
    if (!user.isAuthenticated) {
      return (
        <LoadingIconText loadingText={t('common.loading')} positionCenter />
      )
    } else if (!user.isAuctionCoordinator) {
      console.warn('Unauthorized access to Auction Coordinator only route')
      history.push('/dashboard')
      return
    } else {
      return (
        <Component
          history={history}
          location={location}
          match={match}
          {...props}
        />
      )
    }
  }

  return <SecureRoute {...rest} render={(props: any) => getContent(props)} />
}
