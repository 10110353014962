import { GridLocaleText, deDE, nlNL } from '@mui/x-data-grid-premium'

export const getLocale = (
  language: string
): Partial<GridLocaleText> | undefined => {
  switch (language) {
    case 'nl':
      return nlNL.components.MuiDataGrid.defaultProps.localeText
    case 'de':
      return deDE.components.MuiDataGrid.defaultProps.localeText
    default:
      return undefined
  }
}

type TFunction = (key: string, options?: Record<string, unknown>) => string
let tt: TFunction
export const initTranslations = (t: TFunction): void => {
  tt = t
}
export const getT = (): TFunction => {
  if (!tt) {
    throw new Error(
      'Translations not initialized. Call initializeTranslations first.'
    )
  }

  return tt
}
