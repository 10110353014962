import { styled } from '@mui/material'
import { RfhTypography } from '@rfh/ui'

import { ThemeConfig } from 'src/common/config/SpacingConfig'

export const StyledRfhTypographyTitle = styled(RfhTypography)(() => ({}))

export const StyledRfhTypographyContent = styled(RfhTypography)(() => ({
  display: 'flex',
  padding: 0,
  justifyContent: 'space-between',
  marginBottom: ThemeConfig.spacing.xl,
  overflowWrap: 'anywhere',
}))
