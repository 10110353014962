import { useCallback, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Grid } from '@mui/material'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'

import { useStore } from 'zustand'

import { Divider } from 'src/common/components'
import { getStringValue, initialState, refreshReducer } from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { IClient } from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'

import { AllocationsFilter, AllocationsGrid } from '../components'
import { getByBloemenPlantenVestiging } from '../services'
import { useAllocationStore, useAllocationsStore } from '../stores'

export default function Allocations(): JSX.Element {
  const { t } = useTranslation()
  const { apiClient } = useApiClient()
  const {
    filter,
    mustRefetchAllocations,
    setAllocations,
    setMustRefetchAllocations,
  } = useAllocationsStore()
  const resetAllocationStoreState = useStore(
    useAllocationStore,
    state => state.resetAllocationStoreState
  )
  const [state, dispatch] = useReducer(refreshReducer, initialState)

  const fetchData = useCallback(
    async (client: IClient): Promise<void> => {
      if (!client || state.loading) {
        return Promise.resolve()
      }
      try {
        dispatch({ type: 'PENDING' })
        // eslint-disable-next-line testing-library/no-await-sync-query
        const fetchedAllocations = await getByBloemenPlantenVestiging(
          client,
          filter.bloemenPlantenCode,
          filter.locationCode
        )
        setAllocations(fetchedAllocations)
      } catch (error: any) {
        snackbarUtils.error(getStringValue(error))
      } finally {
        dispatch({ type: 'RESOLVED' })
      }
    },
    [filter, dispatch, setAllocations, state.loading]
  )

  useEffect(() => {
    if (apiClient && mustRefetchAllocations) {
      fetchData(apiClient)
      setMustRefetchAllocations(false)
    }
  }, [apiClient, fetchData, mustRefetchAllocations, setMustRefetchAllocations])

  useEffect(() => {
    resetAllocationStoreState()
    setAllocations([])
    setMustRefetchAllocations(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container maxWidth='xl'>
      <Grid item xs={12} mt={4} mb={2}>
        <Typography variant={'h3'} sx={{ fontWeight: 'bold' }}>
          {t('common.titles.auctionGroupAllocations')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AllocationsFilter data-testid={'allocations-filter'} />
      </Grid>
      <Divider {...{ marginTop: 16 }} />
      <Grid item xs={12}>
        <AllocationsGrid
          data-testid={'allocations-grid'}
          loading={state.loading}
        />
      </Grid>
    </Container>
  )
}
