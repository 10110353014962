import type { Item } from 'src/common/types'

import type {
  IAanvoerder,
  IProduct,
  IProductVeilgroep,
  IProductclusterView,
  IProductgroep,
  IVeilgroepIndelingOverview,
} from '../services/client'

export const byAanvoerderNummerSort = (
  a1: IAanvoerder,
  a2: IAanvoerder
): number => a1.aanvoerderNummer - a2.aanvoerderNummer

export const byProductAuctionGroupCodeSort = (
  pg1: IProductVeilgroep,
  pg2: IProductVeilgroep
): number => pg1.veilgroepCode - pg2.veilgroepCode

export const byProductCodeSort = (pg1: IProduct, pg2: IProduct): number =>
  pg1.productCode - pg2.productCode

export const byProductGroupCodeSort = (
  pg1: IProductgroep,
  pg2: IProductgroep
): number => pg1.productgroepCode - pg2.productgroepCode

export const byCodeSort = (c1: Item<number>, c2: Item<number>): number =>
  c1.code - c2.code

type T = IProductclusterView | IVeilgroepIndelingOverview

export const byProductclusterDescriptionSort = (obj1: T, obj2: T): number =>
  obj1.productclusterOmschrijving.localeCompare(obj2.productclusterOmschrijving)
