import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { formLabelClasses } from '@mui/material'

import {
  ActionButton,
  AvailableProductInput,
  AvailableSupplierInput,
  FilterButtonsStack,
  FilterFieldsStack,
  FilterStack,
} from 'src/common/components'
import { LabelCheckbox } from 'src/common/components/LabelCheckbox'
import { useFilter } from 'src/common/hooks'
import {
  constructFilterString,
  getDefaultPagination,
  getLocalItem,
  isFilterEmpty,
} from 'src/common/utils'

import {
  CHARACTERISTICS_CACHE_KEY,
  CHARACTERISTICS_FILTER_CACHE,
  defaultFilter,
  defaultSortModel,
} from '../constants'
import { createFilter } from '../lib/lib.utils'
import { useSupplierPromotionCharacteristicsStore } from '../stores'
import type { FilterInput } from '../types'

export default function CharacteristicsFilter({
  ...rest
}: Readonly<{ [x: string]: any }>): JSX.Element {
  const { t } = useTranslation()
  const { setFilter, setPagination, setSorting } =
    useSupplierPromotionCharacteristicsStore()

  function setFilterString(values: FilterInput) {
    if (isFilterEmpty(values)) {
      const filterString = constructFilterString(defaultFilter, createFilter)
      setFilter(filterString)
      return
    }
    const newFilterString = constructFilterString(values, createFilter)
    setFilter(newFilterString)
  }

  function resetFilter() {
    setFilterString(defaultFilter)
    setPagination({
      ...getDefaultPagination(CHARACTERISTICS_CACHE_KEY),
      paginationModel: { page: 0 },
    })
    setSorting(defaultSortModel)
  }
  const {
    filter,
    handleChange,
    clear,
    handleRequest: search,
  } = useFilter<FilterInput>({
    cacheKey: CHARACTERISTICS_FILTER_CACHE,
    defaultFilter,
    resetFilter,
    setFilterString,
  })

  useEffect(() => {
    const savedFilter = getLocalItem<FilterInput>(CHARACTERISTICS_FILTER_CACHE)
    if (savedFilter) {
      setFilterString(savedFilter)
    } else {
      setFilterString(defaultFilter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FilterStack {...rest}>
      <FilterFieldsStack>
        <AvailableSupplierInput
          changeSupplierNumber={value =>
            value > 0
              ? handleChange('aanvoerdernummer', value)
              : handleChange('aanvoerdernummer', undefined)
          }
          changeValid={() => {
            /* coming soon */
          }}
          value={filter.aanvoerdernummer ?? undefined}
          sx={{
            minWidth: 150,
            maxWidth: 180,
          }}
        />
        <AvailableProductInput
          values={filter}
          value={filter.productCode}
          changeValid={() => {
            /* coming soon */
          }}
          onChange={value =>
            value > 0
              ? handleChange('productCode', value)
              : handleChange('productCode', undefined)
          }
          sx={{ minWidth: 150, maxWidth: 180 }}
        />
        <LabelCheckbox
          fieldName='toonVervallen'
          flowDirection='column'
          label={t('supplierPromotionCharacteristics.showExpired')}
          onChange={value =>
            handleChange('toonVervallen', value.target.checked)
          }
          value={filter.toonVervallen}
          width={100}
          sx={{
            mx: 0,
            minWidth: 100,
            maxWidth: 120,
            [`&.${formLabelClasses.root}`]: {
              mb: '1.2em',
              fontSize: '100%',
              fontWeight: 'bold',
            },
          }}
        />
      </FilterFieldsStack>
      <FilterButtonsStack>
        <ActionButton variant='block--outlined' onClick={clear}>
          {t('common.clear')}
        </ActionButton>
        <ActionButton type='submit' onClick={search}>
          {t('common.search')}
        </ActionButton>
      </FilterButtonsStack>
    </FilterStack>
  )
}
