import { FC, MutableRefObject, useEffect, useRef } from 'react'

import Box from '@mui/material/Box'

type DotRefProps = {
  onMount: (ref: MutableRefObject<boolean>) => void
}

const DotRef: FC<DotRefProps> = ({ onMount }) => {
  const componentIsMounted = useRef(true)

  useEffect(() => {
    onMount(componentIsMounted)

    return () => {
      componentIsMounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        display: 'block',
        width: '1px',
        height: '1px',
        color: 'transparent',
        backgroundColor: 'transparent',
      }}
    />
  )
}

export default DotRef
