/* eslint-disable id-blacklist */
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import {
  GridColDef,
  GridInitialState,
  GridPaginationInitialState,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-premium'
import { RfhAlert } from '@rfh/ui'
import { DeleteIcon, EditIcon, InfoIcon } from '@rfh/ui/shared/floriday-icons'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'
import { QueryObserverResult } from '@tanstack/react-query'

import {
  ActionButton,
  ButtonsBlock,
  CustomDataGrid,
  RegularityExceptionDelete,
} from 'src/common/components'
import { GRID_MARGIN_TOP, MAX_NUMBER_COLS_SORTED } from 'src/common/constants'
import { Entity } from 'src/common/hooks'
import { mayEditPeriod } from 'src/common/lib'
import { useUser } from 'src/common/providers/UserProvider'
import { IAanvoerderProductRegelmatigUitzonderingView } from 'src/common/services/client'
import { ListAndCount } from 'src/common/types'
import { formatDate, getUTCDate, snackbarUtils } from 'src/common/utils'

import { calcMarginRight } from '../lib'
import ProductRegularityExceptionEdit from './ProductRegularityExceptionEdit'

import { capitalize } from 'lodash'

type ProductRegularityExceptionsGridProps = {
  cacheKey: string
  rows: IAanvoerderProductRegelmatigUitzonderingView[]
  isLoading: boolean
  paginationStateChange: (value: GridPaginationInitialState) => void
  sortModelChange: (value: GridSortModel) => void
  refresh: () => Promise<
    QueryObserverResult<
      ListAndCount<typeof Entity.ProductRegelmatigUitzondering>,
      unknown
    >
  >
  rowCount: number
}

export default function ProductRegularityExceptionsGrid({
  cacheKey,
  isLoading,
  paginationStateChange,
  refresh,
  rowCount,
  rows,
  sortModelChange,
  ...rest
}: Readonly<ProductRegularityExceptionsGridProps>): JSX.Element {
  // Because we use server side sorting we only allow sorting when there are no more than 300.000 rows
  const maxSortedRows = 300000
  const sortingAllowed = rowCount <= maxSortedRows
  const { i18n, t } = useTranslation()
  const user = useUser()
  const history = useHistory()
  const marginRight = calcMarginRight()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [selectedExceptionToBeEdited, setSelectedExceptionToBeEdited] =
    useState<IAanvoerderProductRegelmatigUitzonderingView>()
  const [selectedExceptionToBeDeleted, setSelectedExceptionToBeDeleted] =
    useState<IAanvoerderProductRegelmatigUitzonderingView>()
  const [hideAlertContainer, setHideAlertContainer] = useState(false)

  const initialState: GridInitialState = {
    columns: {
      columnVisibilityModel: {
        aanvoerderNummer: true,
        AanvoerderNaam: true,
        ProductCode: true,
        ProductNaam: true,
        vestigingCode: true,
        UitzonderingDatumVanaf: true,
        UitzonderingDatumTtm: true,
        UitzonderingRedenOmschrijving: true,
        UitzonderingRedenTekst: true,
        verwijderen: true,
        bewerken: true,
        __check__: true,
      },
    },
  }

  const openEditDialog = (
    value: IAanvoerderProductRegelmatigUitzonderingView
  ) => {
    setSelectedExceptionToBeEdited(value)
    setEditOpen(true)
  }

  const closeEditDialog = () => {
    setSelectedExceptionToBeEdited(undefined)
    setEditOpen(false)
  }

  const openDeleteAlert = (
    value: IAanvoerderProductRegelmatigUitzonderingView
  ) => {
    setSelectedExceptionToBeDeleted(value)
    setDeleteOpen(true)
  }

  const closeDeleteAlert = () => {
    setDeleteOpen(false)
    setSelectedExceptionToBeDeleted(undefined)
  }

  const changeLocalStorageSortingModel = (value: GridSortModel) => {
    if (value?.length <= MAX_NUMBER_COLS_SORTED) {
      sortModelChange(value)
    } else {
      // To prevent abuse the maximum number of sorted columns is set to five in the backend
      // we catch this so we can show a friendlier message
      snackbarUtils.warning(t('overviews.maximumSorting'))
    }
  }

  const changePaginationState = (value: GridPaginationInitialState) =>
    paginationStateChange(value)

  const getStaticColumns = useCallback((): GridColDef[] => {
    const newColumns: GridColDef[] = []
    newColumns.push({
      field: 'aanvoerderNummer',
      headerName: t('overviews.supplierNumber'),
      sortable: sortingAllowed,
      align: 'right',
      headerAlign: 'right',
      minWidth: 130,
    })
    newColumns.push({
      field: 'aanvoerderNaam',
      headerName: t('overviews.supplierName'),
      minWidth: 180,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'productCode',
      headerName: t('overviews.productCode'),
      align: 'right',
      headerAlign: 'right',
      minWidth: 120,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'productNaam',
      headerName: t('overviews.productDescription'),
      align: 'left',
      headerAlign: 'left',
      minWidth: 175,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'vestigingNaam',
      headerName: t('common.auctionLocation'),
      align: 'center',
      headerAlign: 'left',
      minWidth: 90,
      sortable: sortingAllowed,
      valueFormatter: item => capitalize(item.value ?? ''),
    })
    newColumns.push({
      field: 'uitzonderingDatumVanaf',
      headerName: t('overviews.startDate'),
      align: 'center',
      headerAlign: 'left',
      type: 'dateTime',
      sortable: sortingAllowed,
      minWidth: 115,
      valueFormatter: row => formatDate(row, t('common.dateFormatShort')),
      groupingValueGetter: row => formatDate(row, t('common.dateFormatShort')),
    })
    newColumns.push({
      field: 'uitzonderingDatumTtm',
      headerName: t('overviews.endDate'),
      align: 'center',
      headerAlign: 'left',
      type: 'dateTime',
      sortable: sortingAllowed,
      minWidth: 115,
      valueGetter: params => params.row.uitzonderingDatumTtm,
      valueFormatter: row => formatDate(row, t('common.dateFormatShort')),
      groupingValueGetter: row => formatDate(row, t('common.dateFormatShort')),
    })
    newColumns.push({
      field: 'uitzonderingRedenOmschrijving',
      headerName: t('overviews.reason'),
      type: 'text',
      sortable: sortingAllowed,
      minWidth: 150,
    })
    newColumns.push({
      field: 'uitzonderingRedenTekst',
      headerName: t('overviews.comments'),
      type: 'text',
      sortable: sortingAllowed,
      minWidth: 140,
    })
    if (user.isAuctionCoordinator) {
      newColumns.push({
        field: 'bewerken',
        disableColumnMenu: true,
        type: 'actions',
        hideable: false,
        sortable: false,
        renderHeader: () => (
          <EditIcon
            sx={{
              color: RfhColors.fogWhite,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams) =>
          mayEditPeriod(
            new Date(params.row.uitzonderingDatumVanaf),
            new Date(params.row.uitzonderingDatumTtm)
          ) ? (
            <EditIcon
              onClick={() => openEditDialog(params.row)}
              sx={{
                cursor: 'pointer',
                color: RfhColors.darkGrey,
                width: '20px',
                height: '20px',
              }}
            />
          ) : (
            <EditIcon
              sx={{
                color: `${RfhColors.lightGrey}`,
                width: '20px',
                height: '20px',
              }}
            />
          ),
        width: 50,
      })
      newColumns.push({
        field: 'verwijderen',
        disableColumnMenu: true,
        type: 'actions',
        hideable: false,
        sortable: false,
        renderHeader: () => (
          <DeleteIcon
            sx={{
              color: RfhColors.fogWhite,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams) =>
          getUTCDate(new Date(params.row.uitzonderingDatumVanaf)) >
          getUTCDate(new Date()) ? (
            <DeleteIcon
              onClick={() => openDeleteAlert(params.row)}
              sx={{
                cursor: 'pointer',
                color: RfhColors.darkGrey,
                width: '20px',
                height: '20px',
              }}
            />
          ) : (
            <DeleteIcon
              sx={{
                color: `${RfhColors.lightGrey}`,
                width: '20px',
                height: '20px',
              }}
            />
          ),
        width: 50,
      })
    }

    return newColumns
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingAllowed, t, i18n.language, user.isAuctionCoordinator])

  const columns = useMemo(
    () => getStaticColumns(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getStaticColumns, i18n.language]
  )

  const fields = useMemo(
    () =>
      getStaticColumns()
        .filter(
          column =>
            column.field !== 'bewerken' && column.field !== 'verwijderen'
        )
        .map(column => column.field),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getStaticColumns, i18n.language]
  )

  return (
    <Grid container marginTop={GRID_MARGIN_TOP} {...rest}>
      <Grid item xs={12} height={0} zIndex={1}>
        <ButtonsBlock marginRight={marginRight}>
          {user.isAuctionCoordinator ? (
            <ActionButton
              variant='block--contained'
              onClick={() => history.push('/dashboard/add-exception-product')}
            >
              {t('common.add')}
            </ActionButton>
          ) : null}
        </ButtonsBlock>
      </Grid>
      {!sortingAllowed && !hideAlertContainer ? (
        <Box
          sx={{
            marginBottom: 2,
            width: '100%',
          }}
        >
          <RfhAlert
            bgColorVariant='dutchOrange'
            icon={<InfoIcon />}
            messageText={t('overviews.sortingDisabledAlertMessage')}
            title={t('overviews.sortingDisabledAlertTitle')}
            onClose={() => setHideAlertContainer(true)}
          />
        </Box>
      ) : null}
      <Grid item xs={12}>
        <CustomDataGrid
          cacheKey={cacheKey}
          changeGridPaginationInitialState={changePaginationState}
          changeSortModel={changeLocalStorageSortingModel}
          columns={columns}
          rows={rows}
          initialState={initialState}
          language={i18n.language}
          getRowId={(row: IAanvoerderProductRegelmatigUitzonderingView) =>
            row.aanvoerderProductRegelmatigUitzonderingId
          }
          gridProps={{
            rowCount,
            loading: isLoading,
            fields,
          }}
        />
      </Grid>
      <ProductRegularityExceptionEdit
        regularityException={selectedExceptionToBeEdited}
        open={editOpen}
        onClose={closeEditDialog}
        onCancel={closeEditDialog}
        refresh={refresh}
      />
      <RegularityExceptionDelete
        exceptionToBeDeleted={selectedExceptionToBeDeleted}
        open={deleteOpen}
        onClose={closeDeleteAlert}
        onCancel={closeDeleteAlert}
        refresh={refresh}
      />
    </Grid>
  )
}
