import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'

import { Rainbow } from '@con/ui'
import Box from '@mui/material/Box'
import RfhFooter, { Language } from '@rfh/ui/components/RfhFooter/RfhFooter'
import { RfhTheme } from '@rfh/ui/components/RfhTheme'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'
import * as Sentry from '@sentry/react'

import { useDevTools } from 'src/dev-tools'

import Package from './../package.json'
import './App.css'
import { AppBar } from './common/components'
import { Config } from './common/config'
import { GlobalProvider } from './common/providers/GlobalProvider'
import { initTranslations } from './common/utils'
import { Routes } from './routes/routes'

import { createBrowserHistory } from 'history'
import { SnackbarProvider } from 'notistack'

const history = createBrowserHistory()

Sentry.init({
  dsn: 'https://24a0d0a25b88eb69a18b7d4f4672620e@o902103.ingest.sentry.io/4506716941975552',
  environment: Config.env,
  release: Package.version,
  enabled: process.env.NODE_ENV !== 'development',
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

const App: FC = () => {
  const { i18n, t } = useTranslation()
  const { version } = useDevTools()
  const zIndex = 1500

  useEffect(() => {
    initTranslations(t)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RfhTheme>
      <SnackbarProvider
        autoHideDuration={7000}
        maxSnack={5}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Router>
          <GlobalProvider>
            <Box position={'absolute'} top={14} right={64} zIndex={zIndex}>
              <Typography variant='overline'>V {version}</Typography>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              minHeight={'100vh'}
            >
              <AppBar />
              <Routes />
              <RfhFooter
                container='lg'
                language={i18n.language.split('-')[0] as Language}
                size='small'
              />
            </Box>
            <Box
              position={'fixed'}
              left={0}
              right={0}
              bottom={0}
              zIndex={zIndex}
            >
              <Rainbow />
            </Box>
          </GlobalProvider>
        </Router>
      </SnackbarProvider>
    </RfhTheme>
  )
}

export default App
