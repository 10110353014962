import { GridValueFormatterParams } from '@mui/x-data-grid-premium'
import { GridGroupingValueGetterParams } from '@mui/x-data-grid-premium/models/gridGroupingValueGetterParams'

import { FRIDAY, MONDAY } from '../constants'

import format from 'date-fns/format'
import nextFriday from 'date-fns/nextFriday'
import nextMonday from 'date-fns/nextMonday'
import previousMonday from 'date-fns/previousMonday'

export const getUTCDate = (value: Date): Date =>
  new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()))

export const utcNever = (): Date => getUTCDate(new Date('2099-12-31T23:59:59'))
export const utcToday = (): Date => getUTCDate(new Date())

export const formatDate = (
  maybeDate: Date | GridGroupingValueGetterParams | GridValueFormatterParams,
  dateFormat: string
): string => {
  if (!maybeDate) {
    return '-'
  }
  if (maybeDate instanceof Date) {
    return format(maybeDate, dateFormat)
  }
  // GridGroupingValueGetterParams | GridValueFormatterParams
  if (!maybeDate.value) {
    return '-'
  }
  const date = new Date(maybeDate.value)
  return format(date, dateFormat)
}

export const getPreviousMonday = (value?: Date): Date => {
  const currentDay = value ?? new Date()
  if (currentDay.getDay() === MONDAY) {
    return getUTCDate(currentDay)
  } else {
    return getUTCDate(previousMonday(getUTCDate(currentDay)))
  }
}

export const getNextMonday = (value?: Date): Date => {
  const currentDay = value ?? new Date()
  if (currentDay.getDay() === MONDAY) {
    return getUTCDate(currentDay)
  } else {
    return getUTCDate(nextMonday(getUTCDate(currentDay)))
  }
}

export const getNextFriday = (value?: Date): Date => {
  const currentDay = value ?? new Date()
  if (currentDay.getDay() === FRIDAY) {
    return getUTCDate(currentDay)
  } else {
    return getUTCDate(nextFriday(getUTCDate(currentDay)))
  }
}

export const disableNonMondays = (date: Date): boolean =>
  date.getDay() !== MONDAY

export const disableNonFridays =
  (startDate?: Date) =>
  (date: Date): boolean =>
    date.getDay() !== FRIDAY ||
    (date < (startDate ?? getNextMonday()) && date < getUTCDate(new Date()))
