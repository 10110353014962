import { DEFAULT_TAKE } from '../constants'
import { IApiClientGetFilter, IApiClientListFilter } from '../interfaces'
import { byProductGroupCodeSort } from '../lib'
import { IClient, IProductgroep } from './client'

export const getProductGroupAsync = async (
  apiClient: IClient,
  apiClientFilter: Partial<IApiClientGetFilter>
): Promise<Partial<IProductgroep>> => {
  try {
    const result =
      (await apiClient.productgroepen_Productgroep_GetProductgroep2(
        apiClientFilter.key,
        undefined,
        undefined
      )) as any

    const cleanedResult: Partial<IProductgroep> =
      process.env.REACT_APP_MOCK_API === 'true'
        ? result
        : result?.cleanedResponse

    return {
      productgroepCode: cleanedResult.productgroepCode,
      productgroepNaam: cleanedResult.productgroepNaam,
    }
  } catch (_error) {
    console.log(`Productgroep ${apiClientFilter.key} niet gevonden`)
  }
}

export const getProductGroupsAsync = async (
  apiClient: IClient,
  apiClientFilter: Partial<IApiClientListFilter>
): Promise<Partial<IProductgroep>[]> => {
  const result = (await apiClient.productgroepen_Productgroep_ListProductgroep(
    DEFAULT_TAKE,
    undefined,
    undefined,
    apiClientFilter.filter,
    undefined,
    undefined,
    undefined,
    undefined
  )) as any

  const records: Partial<IProductgroep>[] = result?.cleanedResponse?.value
  records.sort(byProductGroupCodeSort)
  return records
}
