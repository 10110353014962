import { FC } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'

import { BUTTONS_GAP } from 'src/common/constants'

const ProductGroupsGridSkeleton: FC = () => {
  const dummyArray = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
  ]
  return (
    <Grid container sx={{ width: '100%', marginTop: -1 }}>
      <Grid item xs={12}>
        <Grid
          container
          direction={'row'}
          justifyContent={'flex-end'}
          spacing={BUTTONS_GAP}
        >
          <Skeleton
            variant={'text'}
            sx={{ fontSize: '3em', width: '9%', marginRight: BUTTONS_GAP }}
          />
          <Skeleton
            variant={'text'}
            sx={{ fontSize: '3em', width: '8%', marginRight: BUTTONS_GAP }}
          />
          <Skeleton variant={'text'} sx={{ fontSize: '3em', width: '8%' }} />
        </Grid>
        <Stack spacing={1}>
          {dummyArray.map(el => (
            <Box display={'flex'} gap={2} key={el.id}>
              <Skeleton variant='rectangular' width={'100%'} height={40} />
            </Box>
          ))}
        </Stack>
      </Grid>
    </Grid>
  )
}

export default ProductGroupsGridSkeleton
