import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'

import { ThemeConfig } from 'src/common/config'

import { useClusterRulesStore } from '../stores'

export default function SearchInfo(): JSX.Element {
  const { clusterView } = useClusterRulesStore()
  const { t } = useTranslation()
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
          <Grid item>{clusterView.productgroep}</Grid>
          <Grid item>
            {t('searchProduct.clusterName')}: {clusterView.clusterNaam}
          </Grid>
        </Typography>
      </Grid>

      <Grid item xs={12} mt={4} mb={2}>
        <Typography
          variant='h4'
          sx={{ fontWeight: 'bold', marginBottom: ThemeConfig.spacing.s }}
        >
          {t('searchProduct.auctiongroupRules')}
        </Typography>
        {!clusterView?.veilgroepregels ||
        clusterView?.veilgroepregels?.length <= 0 ? (
          t('common.noResults')
        ) : (
          <ul>
            {clusterView.veilgroepregels.map((value: string) => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        )}
      </Grid>
    </Grid>
  )
}
