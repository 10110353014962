import { GridSortModel } from '@mui/x-data-grid-premium'

import { ColumnsLayout } from 'src/common/types'

export const defaultSortModel: GridSortModel = [
  {
    sort: 'asc',
    field: 'aanvoerderNummer',
  },
  {
    sort: 'asc',
    field: 'productCode',
  },
  {
    sort: 'asc',
    field: 'vestigingCode',
  },
]

export const layout: ColumnsLayout[] = [
  { ratio: 1, align: 'right' },
  { ratio: 0.9, align: 'center' },
  { ratio: 0.9, align: 'center' },
  { ratio: 1, align: 'center' },
  { ratio: 1.1, align: 'center' },
  { ratio: 1, align: 'center' },
  { ratio: 1.1, align: 'center' },
  { ratio: 2, align: 'left' },
  { ratio: 2, align: 'left' },
]
