import { MouseEventHandler } from 'react'

import { TableCell, TableRow, lighten } from '@mui/material'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { ROW_PADDING } from 'src/common/constants'
import type { Item } from 'src/common/types'

type LookupItemProps = {
  item: Item<number>
  onClick: MouseEventHandler<HTMLTableRowElement>
}

export default function LookupItem({
  item,
  onClick,
}: Readonly<LookupItemProps>): JSX.Element {
  const color = item.expired ? lighten(RfhColors.darkGrey, 0.5) : undefined
  return (
    <TableRow hover={true} onClick={onClick} sx={{ cursor: 'pointer' }}>
      <TableCell sx={{ paddingY: ROW_PADDING, color }}>{item.code}</TableCell>
      <TableCell sx={{ paddingY: ROW_PADDING, color }}>{item.name}</TableCell>
    </TableRow>
  )
}
