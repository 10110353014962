import { Paper, Table, TableBody, TableContainer } from '@mui/material'

import { VestigingCode } from 'src/common/enums'

import LogisticsDashboardGridHeader from './LogisticsDashboardGridHeader'
import LogisticsDashboardGridRow from './LogisticsDashboardGridRow'
import type { LogisticsDashboardRowData } from './lib'

import { v4 as getRandomID } from 'uuid'

type LogisticsDashboardGridProps = {
  location?: VestigingCode
  rows: LogisticsDashboardRowData[]
}

export default function LogisticsDashboardGrid({
  location,
  rows,
}: Readonly<LogisticsDashboardGridProps>): JSX.Element {
  const filteredRows = rows.filter(row =>
    location ? row.vestigingCode === location : true
  )
  return (
    <TableContainer
      component={Paper}
      elevation={8}
      style={{ borderRadius: '7px' }}
    >
      <Table aria-label='logistics dashboard' style={{ tableLayout: 'fixed' }}>
        <LogisticsDashboardGridHeader />
        <TableBody>
          {filteredRows.map((row, index) => (
            <LogisticsDashboardGridRow
              row={row}
              index={index}
              key={getRandomID()}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
