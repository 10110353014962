import { ChangeEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material'
import { Button, RfhTypography, TextField } from '@rfh/ui'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'
import { SearchIcon } from '@rfh/ui/shared/floriday-icons'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { fromProductToItem } from 'src/common/lib'
import { IClient, IProduct } from 'src/common/services/client'
import { getProducts } from 'src/common/services/productService'
import { Item } from 'src/common/types'
import { snackbarUtils } from 'src/common/utils'

import { Divider } from '../Divider'
import LookupGrid from '../LookupDialog/LookupGrid'

interface AvailableProductsDialogProps {
  apiClient: IClient
  buttonSx?: Record<string, unknown>
  disabled?: boolean
  inclExpired?: boolean
  onChange: (aanvoerder: IProduct) => void
  showNumber?: boolean
  trigger?: 'iconButton' | 'button'
}

export default function AvailableProductsDialog({
  apiClient,
  buttonSx,
  disabled = false,
  inclExpired = false,
  onChange,
  showNumber = false,
  trigger = 'iconButton',
}: Readonly<AvailableProductsDialogProps>): JSX.Element {
  /*
   * Hooks
   */
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit } = useForm()
  const [inProgress, setInProgress] = useState(false)
  const [open, setOpen] = useState<boolean>(false)
  const [products, setProducts] = useState<IProduct[]>([])
  const [filterString, setFilterString] = useState<string>('')

  /*
   * Methods
   */
  function handleClickOpen() {
    setFilterString('')
    setOpen(true)
    fetchData()
  }

  function handleClose() {
    setOpen(false)
  }

  const itemSelected = (evt: Item<number>) => {
    const selectedProductCode = evt.code
    const product: IProduct = {
      productCode: selectedProductCode,
      productNaam: products.find(p => p.productCode === selectedProductCode)
        .productNaam,
    }
    onChange(product)
    handleClose()
  }

  const handleUserKeyPress = (evt: any) => {
    if (evt.key === 'Enter' && !evt.shiftKey) {
      evt.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  function onSubmit() {
    fetchData()
  }

  function handleFilterProductName(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setFilterString(event.target.value)
  }

  function getODataFilter() {
    const inclExpiredFilter = !inclExpired ? 'isVervallen eq false' : ''
    const and = inclExpiredFilter && filterString ? ' and ' : ''
    const productFilter = filterString
      ? `contains(productnaam, '${filterString}')`
      : ''
    const filter = inclExpiredFilter + and + productFilter
    return filter
  }

  const fetchData = async () => {
    if (inProgress) {
      return Promise.resolve()
    }

    try {
      setInProgress(true)

      // eslint-disable-next-line @typescript-eslint/quotes
      const filter = getODataFilter()
      const records = await getProducts(apiClient, {
        filter: filter ?? undefined,
      })
      setProducts(records)
    } catch (error: any) {
      snackbarUtils.error(String(error))
    } finally {
      setInProgress(false)
      setFilterString('')
    }
  }

  /**
   * Render
   */
  return (
    <>
      {trigger === 'iconButton' ? (
        <IconButton
          aria-label={'lookup icon button'}
          onClick={handleClickOpen}
          sx={{ paddingX: 0.5 }}
        >
          <SearchIcon sx={{ color: RfhColors.darkOrange, height: 20, py: 0 }} />
        </IconButton>
      ) : (
        <Button
          disabled={disabled}
          onClick={handleClickOpen}
          size='small'
          sx={buttonSx}
          type='submit'
          variant='block--contained'
        >
          {t('common.add')}
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            width: '600px',
            maxWidth: '600px',
          }}
        >
          {' '}
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              left: '1.5em',
              right: '2em',
              clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
              backgroundColor: 'white',
              paddingTop: '2em',
              zIndex: 1,
            }}
          >
            <RfhTypography style={{ marginBottom: '16px' }} variant={'h5'}>
              {t('exceptions.addExceptions.searchProducts')}
            </RfhTypography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <TextField
                autoFocus
                fieldColor='dark'
                fullWidth
                id='productNaam'
                name='productNaam'
                onChange={handleFilterProductName}
                onKeyPress={handleUserKeyPress}
                placeholder={t('exceptions.addExceptions.productName')}
              />
              <RfhButton
                variant='block--contained'
                onClick={handleSubmit(onSubmit)}
                isLoading={inProgress}
              >
                {t('common.search')}
              </RfhButton>
            </form>
            <Divider
              {...{
                marginTop: '1em',
                marginBottom: 0,
              }}
            />
          </Box>
          {/*GRID WITH LOOKUP DATA*/}
          <LookupGrid
            items={products?.map(fromProductToItem) ?? []}
            selectItem={itemSelected}
          />
        </DialogContent>
        <Box mr={3}>
          <DialogActions>
            <RfhButton variant='text--underlined' onClick={handleClose}>
              {t('common.back')}
            </RfhButton>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}
