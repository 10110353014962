import { VestigingCode } from 'src/common/enums'
import type { IApiClientGetFilter } from 'src/common/interfaces'
import {
  getAuctionGroupRuleAsync,
  getProductAuctionGroupByCodeAndLocationAsync,
} from 'src/common/services'
import {
  IClient,
  ProductclusterVeilgroepRegelCode,
} from 'src/common/services/client'
import type { Item } from 'src/common/types'
import { snackbarUtils } from 'src/common/utils'

import {
  CodeSoortFust,
  CodeSoortProduct,
  CodeSoortProductgroep,
  validationErrors,
} from '../constants'
import { byGeselecteerd } from '../lib'
import { CodeSoort } from '../types'

export async function searchRegelCodes(
  client: IClient,
  codeSoort: CodeSoort,
  productclusterId: number,
  id: number
): Promise<ProductclusterVeilgroepRegelCode[]> {
  let result: any
  switch (codeSoort) {
    case CodeSoortFust:
      result = await client.veilgroepIndelingRegelViews_ZoekFustcodes2(id)
      break
    case CodeSoortProductgroep:
      result = await client.veilgroepIndelingRegelViews_ZoekProductgroepen2(
        productclusterId,
        id
      )
      break
    case CodeSoortProduct:
      result = await client.veilgroepIndelingRegelViews_ZoekProducten2(
        productclusterId,
        id
      )
      break
    default:
      console.log('unknown value:', codeSoort)
  }
  const records: ProductclusterVeilgroepRegelCode[] =
    result?.cleanedResponse?.value || []
  records.sort(byGeselecteerd)
  return records
}

export async function getActionGroupAsync(
  client: IClient,
  veilgroepCode: number,
  vestigingCode: VestigingCode,
  ofSupplier?: boolean
): Promise<Item<number>> {
  if (veilgroepCode <= 0 || vestigingCode <= 0) {
    return Promise.resolve({
      code: veilgroepCode,
      name: ofSupplier
        ? validationErrors.invalidAuctionGroupSupplier
        : validationErrors.invalidAuctionGroup,
    })
  }

  const auctionGroup = await getProductAuctionGroupByCodeAndLocationAsync(
    client,
    veilgroepCode,
    vestigingCode as number
  )

  return {
    code: veilgroepCode,
    name:
      auctionGroup?.veilgroepOmschrijving ||
      (ofSupplier
        ? validationErrors.invalidAuctionGroupSupplier
        : validationErrors.invalidAuctionGroup),
  }
}

export async function getActionGroupRuleAsync(
  client: IClient,
  textInput: string
): Promise<Item<number>> {
  if (!textInput) {
    return Promise.resolve({ code: null, name: '' })
  }

  const code = Number(textInput)
  if (isNaN(code) || code <= 0) {
    snackbarUtils.error('De waarde moet een positief getal zijn')
    return Promise.resolve(null)
  }

  // ok: code > 0
  const filter: Partial<IApiClientGetFilter> = { key: code }
  const fetchedRule = await getAuctionGroupRuleAsync(client, filter)
  if (!fetchedRule) {
    return Promise.resolve(null)
  }
  return {
    code: fetchedRule.veilgroepRegelId,
    name: fetchedRule.veilgroepRegelOmschrijving,
  }
}
