import { Stack } from '@mui/material'

import { BUTTONS_GAP } from 'src/common/constants'

export default function FilterButtonsStack({
  children,
}: Readonly<{ children: React.ReactNode }>): JSX.Element {
  return (
    <Stack
      direction={'row'}
      justifyContent={'justify-end'}
      alignItems={'start'}
      gap={BUTTONS_GAP}
      marginRight={0.3}
      marginTop={'2.5em'}
    >
      {children}
    </Stack>
  )
}
