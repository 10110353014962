import { useCallback, useMemo, useReducer } from 'react'

import {
  byCodeSort,
  fromIVeilgroepRegelToItem,
  getStringValue,
  initialState,
  refreshReducer,
} from 'src/common/lib'
import { useApiClient } from 'src/common/providers'
import { getAuctionGroupRulesAsync } from 'src/common/services'
import { Item } from 'src/common/types'
import { snackbarUtils } from 'src/common/utils'

import {
  CodeSoortFust,
  CodeSoortProduct,
  CodeSoortProductgroep,
} from '../constants'
import { bySelected, concatCodes, toItem } from '../lib'
import { searchRegelCodes } from '../services'
import { useRuleStore } from '../stores'
import type { CodeSoort } from '../types'

type UseReferenceDataReturn = {
  ensureReferenceData: (productclusterId: number, id: number) => Promise<void>
  mergeSelected: (codeSoort: CodeSoort) => void
}

export default function useReferenceData(): UseReferenceDataReturn {
  const { apiClient } = useApiClient()
  const { rule, ruleCodes, ruleCodesToUpdate, setRule, setRuleCodes } =
    useRuleStore()
  const [state, dispatch] = useReducer(refreshReducer, initialState)

  const recalculateCodeSoortText = useCallback(
    (items: Item<number>[], codeSoort: CodeSoort) => {
      /* recalculate and update the text field */
      const newText = concatCodes(items)
      switch (codeSoort) {
        case CodeSoortFust:
          setRule({ ...rule, fustTekst: newText })
          break
        case CodeSoortProduct:
          setRule({ ...rule, productTekst: newText })
          break
        case CodeSoortProductgroep:
          setRule({ ...rule, productgroepTekst: newText })
          break
      }
    },
    [rule, setRule]
  )

  const mergeSelected = useCallback(
    function (codeSoort: CodeSoort) {
      /* sort ruleCodes */
      const updatedRuleCodes = [...ruleCodes[codeSoort]]
      ruleCodesToUpdate.forEach(rc => {
        const ruleCode = updatedRuleCodes.find(
          urc => rc.codeSoort === codeSoort && urc.code === rc.code
        )
        if (ruleCode) {
          ruleCode.selected = rc.geselecteerd
        }
      })

      updatedRuleCodes.sort(bySelected)
      recalculateCodeSoortText(updatedRuleCodes, codeSoort)
    },
    [recalculateCodeSoortText, ruleCodes, ruleCodesToUpdate]
  )

  const fetchMulti = useCallback(
    async function (
      codeSoort: CodeSoort,
      productclusterId: number,
      id: number
    ): Promise<void> {
      const regelCodes = await searchRegelCodes(
        apiClient,
        codeSoort,
        productclusterId,
        id
      )
      setRuleCodes(regelCodes.map(toItem), codeSoort)
    },
    [apiClient, setRuleCodes]
  )

  const fetchVeilgroepRegels = useCallback(
    async function (): Promise<void> {
      const veilgroepRegels = (
        (await getAuctionGroupRulesAsync(apiClient)) ?? []
      ).map(fromIVeilgroepRegelToItem)
      veilgroepRegels.sort(byCodeSort)
      setRuleCodes(veilgroepRegels)
    },
    [apiClient, setRuleCodes]
  )

  const ensureReferenceData = useCallback(
    (productclusterId: number, id: number): Promise<void> => {
      if (!apiClient || state.loading) {
        return Promise.resolve()
      }

      try {
        dispatch({ type: 'PENDING' })
        const promisedFusten = fetchMulti(CodeSoortFust, productclusterId, id)
        const promisedProducten = fetchMulti(
          CodeSoortProduct,
          productclusterId,
          id
        )
        const promisedProductgroepen = fetchMulti(
          CodeSoortProductgroep,
          productclusterId,
          id
        )
        const promisedVeilgroepRegels = fetchVeilgroepRegels()
        Promise.all([
          promisedFusten,
          promisedProducten,
          promisedProductgroepen,
          promisedVeilgroepRegels,
        ])
      } catch (error: any) {
        snackbarUtils.error(getStringValue(error))
      } finally {
        dispatch({ type: 'RESOLVED' })
      }
    },
    [apiClient, fetchMulti, fetchVeilgroepRegels, state.loading]
  )

  return useMemo(
    () => ({ ensureReferenceData, mergeSelected }),
    [ensureReferenceData, mergeSelected]
  )
}
