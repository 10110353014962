import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { VestigingCode } from 'src/common/enums'
import type { IAanvoerderOnregelmatig } from 'src/common/services/client'

export type StoreState = {
  irregularSuppliers: IAanvoerderOnregelmatig[]
  mustRefetchIrregularSuppliers: boolean
  supplierCodeFilter: number
  locationCodeFilter: VestigingCode
}

type Actions = {
  /* store */
  resetIrregularSuppliersStoreState: () => void
  addIrregularSupplier: (c: IAanvoerderOnregelmatig) => void
  removeIrregularSupplier: (id: number) => void
  setLocationCodeFilter: (id: VestigingCode) => void
  setSupplierCodeFilter: (id: number) => void
  setIrregularSuppliers: (cs: IAanvoerderOnregelmatig[]) => void
  setMustRefetchIrregularSuppliers: (input: boolean) => void
}

const storeName = 'IrregularSuppliersStore'

const initialStoreState: StoreState = {
  irregularSuppliers: [],
  mustRefetchIrregularSuppliers: false,
  supplierCodeFilter: 0,
  locationCodeFilter: 0,
}

export const useIrregularSuppliersStore = create<StoreState & Actions>()(
  devtools(
    persist(
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetIrregularSuppliersStoreState: () =>
          set(
            () => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetIrregularSuppliersStoreState'
          ),
        addIrregularSupplier: (c: IAanvoerderOnregelmatig) =>
          set(
            (state: StoreState) => ({
              irregularSuppliers: [...state.irregularSuppliers, c],
            }),
            false,
            'addIrregularSupplier'
          ),
        removeIrregularSupplier: (id: number) =>
          set(
            (state: StoreState) => ({
              irregularSuppliers: [
                ...state.irregularSuppliers.filter(c => c?.id !== id),
              ],
            }),
            false,
            'removeIrregularSupplier'
          ),
        setIrregularSuppliers: (cs: IAanvoerderOnregelmatig[]) =>
          set(
            () => ({ irregularSuppliers: [...cs] }),
            false,
            'setIrregularSuppliers'
          ),
        setMustRefetchIrregularSuppliers: (input: boolean) =>
          set(
            () => ({
              mustRefetchIrregularSuppliers: input,
            }),
            false,
            'setMustRefetchIrregularSuppliers'
          ),
        setSupplierCodeFilter: (id: number) =>
          set(
            () => ({ supplierCodeFilter: id }),
            false,
            'setSupplierCodeFilter'
          ),
        setLocationCodeFilter: (id: VestigingCode) =>
          set(
            () => ({ locationCodeFilter: id }),
            false,
            'setLocationCodeFilter'
          ),
      }),
      {
        name: 'irreglar-suppliers-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
