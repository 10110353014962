import { ChangeEvent } from 'react'

import {
  AvailableSupplierInput,
  FilterFieldsStack,
  FilterStack,
  LocationSelect,
} from 'src/common/components'

import { useIrregularSuppliersStore } from '../stores'
import CustomLabel from './CustomLabel'

export default function IrregularSuppliersFilter({
  ...rest
}: Readonly<{ [x: string]: any }>): JSX.Element {
  const { supplierCodeFilter, setLocationCodeFilter, setSupplierCodeFilter } =
    useIrregularSuppliersStore()

  const handleChangeSupplier = (code: number): void => {
    setSupplierCodeFilter(code)
  }

  const handleChangeLocation = (
    event: ChangeEvent<HTMLSelectElement>
  ): void => {
    setLocationCodeFilter(parseInt(event.target.value, 10))
  }

  //! waarschijnlijk niet nodig
  const handleChangeValid = (bool: boolean): void => void 0

  return (
    <FilterStack {...rest}>
      <FilterFieldsStack>
        <LocationSelect
          onChange={handleChangeLocation}
          customLabel={CustomLabel}
          addCatchAll
        />
        <AvailableSupplierInput
          changeSupplierNumber={handleChangeSupplier}
          changeValid={handleChangeValid}
          value={supplierCodeFilter}
          showNumberInDialog
          sx={{
            minWidth: 150,
            maxWidth: 180,
          }}
        />
      </FilterFieldsStack>
    </FilterStack>
  )
}
