import { useTranslation } from 'react-i18next'

import { Box, Dialog, DialogActions, DialogContent } from '@mui/material'
import { Button } from '@rfh/ui/components/RfhButton'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'

import { Divider } from 'src/common/components'
import { ThemeConfig } from 'src/common/config'

import { useReferenceData } from '../../hooks'
import { getDiffs } from '../../lib'
import { useRuleStore } from '../../stores'
import { CodeSoort } from '../../types'
import DeferredTable from './DeferredTable'

type MultiSelectDialogProps = {
  codeSoort: CodeSoort
  dialogTitle: string
  isLoading: boolean
  isOpen: boolean
  isTimeoutReached: boolean
  onClose?: () => void
}

export default function MultiSelectDialog({
  codeSoort,
  dialogTitle,
  isLoading,
  isOpen,
  isTimeoutReached,
  onClose,
}: Readonly<MultiSelectDialogProps>): JSX.Element {
  const { t } = useTranslation()
  const {
    oldRuleCodesToUpdate,
    ruleCodesToUpdate,
    setOldRuleCodesToUpdate,
    setRuleCodesToUpdate,
    upsertRuleCodeToUpdate,
  } = useRuleStore()
  const { mergeSelected } = useReferenceData()

  function updateSelection() {
    setOldRuleCodesToUpdate(ruleCodesToUpdate)
    mergeSelected(codeSoort) //! recalculate the text
    onClose()
  }

  function revertSelection() {
    //! rollback all diffs
    const ruleCodesToRevert = getDiffs(ruleCodesToUpdate, oldRuleCodesToUpdate)
    ruleCodesToRevert.forEach(rcr => {
      rcr.geselecteerd = !rcr.geselecteerd
      upsertRuleCodeToUpdate(rcr)
    })
    setRuleCodesToUpdate(oldRuleCodesToUpdate)
    mergeSelected(codeSoort) //! recalculate the text
    onClose()
  }

  return (
    <>
      {isOpen && !isTimeoutReached && (
        <Dialog open={isOpen} onClose={onClose}>
          <DialogContent
            sx={{
              width: '600px',
              maxWidth: '600px',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '0',
                left: '1.5em',
                right: '2em',
                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
                backgroundColor: 'white',
                paddingTop: '2em',
                zIndex: 1,
              }}
            >
              <Typography variant={'h5'} sx={{ marginLeft: '0.6em' }}>
                {dialogTitle}
              </Typography>
              <Box
                display={'flex'}
                justifyContent={'flex-end'}
                alignItems={'center'}
              >
                <Button variant='block--contained' onClick={updateSelection}>
                  {t('common.select')}
                </Button>
              </Box>
              <Divider
                {...{
                  marginTop: '1em',
                  marginBottom: 0,
                }}
              />
            </Box>
            <DeferredTable codeSoort={codeSoort} />
          </DialogContent>
          <DialogActions>
            <Button
              variant='text--underlined'
              onClick={revertSelection}
              sx={{
                marginBottom: ThemeConfig.spacing.xs * 2,
                marginRight: ThemeConfig.spacing.sm + ThemeConfig.spacing.xs,
              }}
            >
              {t('common.back')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isOpen && isTimeoutReached && isLoading && null}
    </>
  )
}
