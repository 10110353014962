import { Children, FC, useEffect, useMemo, useState } from 'react'

declare global {
  interface Window {
    requestIdleCallback: (
      // eslint-disable-next-line id-blacklist
      callback: IdleRequestCallback,
      options?: IdleRequestOptions
    ) => number
  }
}

type DeferredLoadProps = {
  chunkSize: number
  timeout?: number
}

const DeferredLoad: FC<DeferredLoadProps> = ({
  chunkSize,
  timeout = 200,
  children,
}) => {
  const [renderedItemsCount, setRenderedItemsCount] = useState(chunkSize)

  const childrenArray = useMemo(() => Children.toArray(children), [children])

  useEffect(() => {
    if (renderedItemsCount < childrenArray.length) {
      window.requestIdleCallback(
        () => {
          setRenderedItemsCount(
            Math.min(renderedItemsCount + chunkSize, childrenArray.length)
          )
        },
        { timeout }
      )
    }
  }, [renderedItemsCount, childrenArray.length, chunkSize, timeout])

  return <>{childrenArray.slice(0, renderedItemsCount)}</>
}

export default DeferredLoad
