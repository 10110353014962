/* eslint-disable @typescript-eslint/quotes */
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, NativeSelect } from '@mui/material'
import { RfhTypography } from '@rfh/ui'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { getExceptionReasons } from 'src/common/services'
import { IUitzonderingReden } from 'src/common/services/client'

import ReasonOptions from './ReasonOptions'

type AvailableReasonsInputProps = {
  input: number
  labelVisible?: boolean
  marginTop?: number
  required?: boolean
  changeValid?: (value: any) => void
  onChangeReason: (name: string, value: any) => void
}

export default function AvailableReasonsInput({
  input,
  labelVisible,
  marginTop,
  required,
  changeValid,
  onChangeReason,
}: Readonly<AvailableReasonsInputProps>): JSX.Element {
  /*
   * Hooks
   */
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const [inProgress, setInProgress] = useState(false)
  const [reasons, setReasons] = useState<IUitzonderingReden[]>([])
  const { apiClient } = useApiClient()
  /*
   * Methods
   */
  const fetchData = useCallback(async () => {
    if (!apiClient || inProgress) {
      return Promise.resolve()
    }

    setInProgress(true)
    const records = await getExceptionReasons(apiClient)
    setReasons(records)
    setInProgress(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient])

  const onClickSelectItemOrClear = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    if (!value || value === '' || value === '0') {
      onChangeReason('uitzonderingRedenCode', undefined)
      changeValid?.(false)
    } else {
      onChangeReason('uitzonderingRedenCode', value)
      changeValid?.(true)
    }
  }

  useEffect(() => {
    fetchData()
  }, [apiClient, fetchData])

  return (
    <FormControl>
      {labelVisible && (
        <RfhTypography variant={'subheader'}>
          {required ? `${t('overviews.reason')} *` : t('overviews.reason')}
        </RfhTypography>
      )}

      <NativeSelect
        disabled={inProgress}
        value={input}
        onChange={onClickSelectItemOrClear}
        sx={{
          marginTop: { marginTop },
          bgcolor: RfhColors.white,
          'svg.MuiNativeSelect-icon': {
            pointerEvents: 'none',
          },
        }}
      >
        <ReasonOptions reasons={reasons} />
      </NativeSelect>
    </FormControl>
  )
}
