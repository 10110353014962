// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { FC } from 'react'

export const composeProviders =
  (...providers: FC[]): FC =>
  ({ children }: any) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    providers.reduceRight(
      (child, Provider) => <Provider>{child}</Provider>,
      children
    )
