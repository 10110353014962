import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@mui/material/FormControl'
import NativeSelect from '@mui/material/NativeSelect'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import {
  FilterFieldsStack,
  FilterStack,
  LocationSelect,
} from 'src/common/components'
import { VestigingCode } from 'src/common/enums'
import { Entity, useFetchMany } from 'src/common/hooks'

import { initialFilter } from '../constants'
import {
  byLocationCodeClockName,
  splitClocks,
  toComboBoxOption,
  toLocationClock,
} from '../lib'
import { useFilterStore } from '../stores'

enum TrolleyType {
  All = ' ',
  Stapelwagen = 'Stapelwagen',
  Deen = 'Deen',
}

export default function AuctionGroupCountsFilter(): JSX.Element {
  const ENABLED = true
  const { t } = useTranslation()
  const {
    filter,
    getLocationClocks,
    locationConcernClocks,
    locationLocalClocks,
    setClockPool,
    setFilter,
    setLocationLocalClocks,
    setLocationTestClocks,
  } = useFilterStore()
  const [locationCode, setLocationCode] = useState<VestigingCode>(
    filter.locationCode
  )

  const {
    data: { records: tribuneKlokken },
  } = useFetchMany<typeof Entity.LocationClock>(
    {},
    Entity.LocationClock,
    ENABLED,
    { staleTime: Infinity }
  )

  const changeLocation = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const newLocationCode = +event.target.value
      setLocationCode(newLocationCode)
      setFilter({
        locationCode: newLocationCode,
      })
    },
    [setFilter]
  )

  const clockSelectOptions = useMemo(
    () => getLocationClocks().map(toComboBoxOption),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationCode, locationConcernClocks, locationLocalClocks]
  )

  useEffect(() => {
    if (!tribuneKlokken || tribuneKlokken.length === 0) {
      setClockPool([])
      return
    }

    const allClocks = tribuneKlokken.map(toLocationClock)
    allClocks.sort(byLocationCodeClockName)
    setClockPool(allClocks)
    const { localClocks, testClocks } = splitClocks(allClocks, locationCode)
    localClocks.sort(byLocationCodeClockName)
    testClocks.sort(byLocationCodeClockName)
    setLocationLocalClocks(localClocks)
    setLocationTestClocks(testClocks)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationCode, tribuneKlokken])

  useEffect(() => {
    if (!locationCode) {
      setLocationCode(initialFilter.locationCode)
      setFilter({ locationCode: initialFilter.locationCode })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFilter])

  useEffect(() => {
    if (locationCode === filter.locationCode) return
    setFilter({
      locationCode,
      clockNameFrom: undefined,
      clockNumberFrom: 0,
      clockNameTo: undefined,
      clockNumberTo: 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationCode, setFilter])

  return (
    <FilterStack>
      <FilterFieldsStack>
        <FormControl>
          <Typography variant={'subheader'}>
            {t('common.auctionDate')}
          </Typography>
          <DatePicker
            aria-label={'auction date'}
            value={new Date(filter.auctionDate)}
            onChange={newDate => {
              setFilter({ auctionDate: new Date(newDate) })
            }}
            slotProps={{
              textField: {
                sx: {
                  mt: 1.5,
                  bgcolor: RfhColors.white,
                  '.MuiInputBase-root': { paddingLeft: 1, paddingRight: 1 },
                  maxWidth: 180,
                },
              },
            }}
          />
        </FormControl>

        <FormControl>
          <LocationSelect
            addCatchAll
            locationCode={locationCode}
            onChange={changeLocation}
          />
        </FormControl>

        <FormControl>
          <Typography variant={'subheader'}>
            {t('dayCounts.clockNameFrom')}
          </Typography>
          <NativeSelect
            aria-label={'from clock'}
            value={filter.clockNumberFrom}
            onChange={event => {
              const clockNumber = +event.target.value
              const clockName = getLocationClocks().find(
                cl => cl.clockNumber === clockNumber
              )?.clockName
              setFilter({
                clockNameFrom: clockName,
                clockNumberFrom: clockNumber,
              })
            }}
            sx={{
              p: 1,
              mt: 1.5,
              minWidth: 75,
              bgcolor: RfhColors.white,
              'svg.MuiNativeSelect-icon': {
                pointerEvents: 'none',
              },
            }}
          >
            <option value={0}></option>
            {clockSelectOptions}
          </NativeSelect>
        </FormControl>

        <FormControl>
          <Typography variant={'subheader'}>
            {t('dayCounts.clockNameTo')}
          </Typography>
          <NativeSelect
            aria-label={'to clock'}
            value={filter.clockNumberTo}
            onChange={event => {
              const clockNumber = +event.target.value
              const clockName = getLocationClocks().find(
                cl => cl.clockNumber === clockNumber
              )?.clockName
              setFilter({
                clockNameTo: clockName,
                clockNumberTo: clockNumber,
              })
            }}
            sx={{
              p: 1,
              mt: 1.5,
              minWidth: 75,
              bgcolor: RfhColors.white,
              'svg.MuiNativeSelect-icon': {
                pointerEvents: 'none',
              },
            }}
          >
            <option value={0}></option>
            {clockSelectOptions}
          </NativeSelect>
        </FormControl>

        <FormControl>
          <Typography variant={'subheader'}>
            {t('dayCounts.trolley')}
          </Typography>
          <NativeSelect
            aria-label={'trolley type'}
            value={filter.trolleyType}
            onChange={event => {
              setFilter({ trolleyType: +event.target.value })
            }}
            sx={{
              p: 1,
              mt: 1.5,
              minWidth: 150,
              maxWidth: 180,
              bgcolor: RfhColors.white,
              'svg.MuiNativeSelect-icon': {
                pointerEvents: 'none',
              },
            }}
          >
            <option value={0}>{TrolleyType.All}</option>
            <option value={1}>{TrolleyType.Stapelwagen}</option>
            <option value={2}>{TrolleyType.Deen}</option>
          </NativeSelect>
        </FormControl>
      </FilterFieldsStack>
    </FilterStack>
  )
}
