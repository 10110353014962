import { TableCell, TableRow } from '@mui/material'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { ClockStatusData, KLOK_STATUS, getCellStyles } from './lib'

type ClocksStatusTableRowProps = {
  index: number
  row: ClockStatusData
  mainRow: boolean
}

export default function ClocksStatusTableRow({
  index,
  row,
  mainRow,
}: Readonly<ClocksStatusTableRowProps>): JSX.Element {
  function getStatusColor(statusData: ClockStatusData): {
    bgColor: string
    color?: string
  } {
    switch (statusData?.klokStatus) {
      case 'WAC':
        return { bgColor: KLOK_STATUS.WAC.color }
      case 'VEL':
        return { bgColor: KLOK_STATUS.VEL.color }
      case 'PAU':
        return { bgColor: KLOK_STATUS.PAU.color }
      case 'STO':
        return { bgColor: KLOK_STATUS.STO.color, color: RfhColors.white }
      case 'END':
        return { bgColor: KLOK_STATUS.END.color }
      default:
        return { bgColor: RfhColors.white }
    }
  }

  const mainBgColor = mainRow ? RfhColors.fogWhite : RfhColors.white

  return (
    <TableRow
      style={{
        backgroundColor: mainBgColor,
      }}
    >
      <TableCell
        style={{
          ...getCellStyles('left'),
          backgroundColor: mainRow ? mainBgColor : getStatusColor(row).bgColor,
          color: getStatusColor(row)?.color,
          fontWeight: 700,
          overflow: 'visible',
          width: '11.5%',
        }}
      >
        {row.klokNaam}
      </TableCell>
      <TableCell
        style={{
          ...getCellStyles('left'),
          width: '48.5%',
        }}
      >
        {row.huidigeVeilgroep}
      </TableCell>
      <TableCell style={getCellStyles('right')}>{row.teVeilen}</TableCell>
      <TableCell style={getCellStyles('right')}>{row.geveild}</TableCell>
    </TableRow>
  )
}
