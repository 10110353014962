import { Route, Switch } from 'react-router-dom'

import { useDevToolsStore } from 'src/dev-tools'
import { FEATURES } from 'src/dev-tools/config'
import {
  AddIrregularSupplier,
  AddProductException,
  AddProductGroupException,
  Allocations,
  AuctionGroupCounts,
  AuctionGroupRules,
  BatchesStatus,
  ClocksStatus,
  IrregularSuppliers,
  LogisticsDashboard,
  ManageAllocation,
  ManageAuctionGroupRules,
  ManageProductCluster,
  ManageRuleMutation,
  ManageSupplierCluster,
  ManageSupplierPromotionCharacteristic,
  ProductClusters,
  ProductGroupRegularity,
  ProductGroupRegularityExceptions,
  ProductRegularity,
  ProductRegularityExceptions,
  SearchProduct,
  SupplierClusters,
  SupplierPromotionCharacteristics,
} from 'src/features'
import { HomePage, NotFound, ProfilePage } from 'src/views'

import { AuctionCoordinatorRoute } from '../guards/AuctionCoordinatorRoute'
import { ConsulterRoute } from '../guards/ConsulterRoute'

export default function InternalRoutes(): JSX.Element {
  const { features } = useDevToolsStore()

  // Example of route enabled by a feature toggle
  // const { features } = useDevToolsStore()

  // <ConsulterRoute
  //   path='/dashboard/example-feature'
  //   exact
  //   component={ExampleFeature}
  //   visible={features?.[FEATURES.EXAMPLE]}
  // />

  return (
    <Switch>
      <ConsulterRoute path='/dashboard' exact component={HomePage} />
      <ConsulterRoute path='/dashboard/profile' component={ProfilePage} />
      <ConsulterRoute
        path='/dashboard/supplier-product-regularity'
        exact
        component={ProductRegularity}
      />
      <ConsulterRoute
        path='/dashboard/supplier-product-regularity/:aanvoerderProductRegelmatigId'
        component={ProductRegularity}
      />
      <ConsulterRoute
        path='/dashboard/supplier-product-group-regularity'
        exact
        component={ProductGroupRegularity}
      />
      <ConsulterRoute
        path='/dashboard/supplier-product-group-regularity/:aanvoerderProductgroepRegelmatigId'
        component={ProductGroupRegularity}
      />
      <ConsulterRoute
        path='/dashboard/exceptions-product'
        exact
        component={ProductRegularityExceptions}
      />
      <ConsulterRoute
        path='/dashboard/exceptions-product-group'
        exact
        component={ProductGroupRegularityExceptions}
      />
      <AuctionCoordinatorRoute
        path='/dashboard/add-exception-product'
        component={AddProductException}
      />
      <AuctionCoordinatorRoute
        path='/dashboard/add-exception-product-group'
        component={AddProductGroupException}
      />
      <ConsulterRoute
        path='/dashboard/auction-group-counts'
        exact
        component={AuctionGroupCounts}
      />
      <ConsulterRoute
        path='/dashboard/batches-status'
        exact
        component={BatchesStatus}
      />
      <ConsulterRoute
        path='/dashboard/auction-group-rules'
        exact
        component={AuctionGroupRules}
      />
      <ConsulterRoute
        path='/dashboard/auction-group-rules/add'
        exact
        component={ManageAuctionGroupRules}
      />
      <ConsulterRoute
        path='/dashboard/auction-group-rules/:id'
        exact
        component={ManageAuctionGroupRules}
      />
      <ConsulterRoute
        path='/dashboard/supplier-clusters'
        exact
        component={SupplierClusters}
      />
      <AuctionCoordinatorRoute
        path='/dashboard/supplier-clusters/add'
        exact
        component={ManageSupplierCluster}
      />
      <AuctionCoordinatorRoute
        path='/dashboard/supplier-clusters/:id'
        exact
        component={ManageSupplierCluster}
      />
      <ConsulterRoute
        path='/dashboard/product-clusters'
        exact
        component={ProductClusters}
      />
      <AuctionCoordinatorRoute
        path='/dashboard/product-clusters/add'
        exact
        component={ManageProductCluster}
      />
      <AuctionCoordinatorRoute
        path='/dashboard/product-clusters/:id'
        exact
        component={ManageProductCluster}
      />
      <ConsulterRoute
        path='/dashboard/auction-group-allocations'
        exact
        component={Allocations}
      />
      <ConsulterRoute
        path='/dashboard/auction-group-allocations/:id/:ruleId'
        exact
        component={ManageRuleMutation}
      />
      <ConsulterRoute
        path='/dashboard/auction-group-allocations/:id'
        exact
        component={ManageAllocation}
      />
      <ConsulterRoute
        path='/dashboard/supplier-promotion-characteristics'
        exact
        component={SupplierPromotionCharacteristics}
      />
      <ConsulterRoute
        path='/dashboard/supplier-promotion-characteristics/add'
        exact
        component={ManageSupplierPromotionCharacteristic}
      />
      <ConsulterRoute
        path='/dashboard/supplier-promotion-characteristics/:id'
        exact
        component={ManageSupplierPromotionCharacteristic}
      />
      <ConsulterRoute
        path='/dashboard/search-product'
        exact
        component={SearchProduct}
      />
      <ConsulterRoute
        path='/dashboard/irregular-suppliers'
        exact
        component={IrregularSuppliers}
      />
      <ConsulterRoute
        path='/dashboard/irregular-suppliers/add'
        exact
        component={AddIrregularSupplier}
      />
      <ConsulterRoute
        path='/dashboard/clocks-status'
        exact
        component={ClocksStatus}
        visible={features?.[FEATURES.KLOKSTATUS]}
      />
      <ConsulterRoute
        path='/dashboard/logistics-dashboard'
        component={LogisticsDashboard}
        visible={features?.[FEATURES.DASHBOARD]}
      />
      <Route component={NotFound} />
    </Switch>
  )
}
