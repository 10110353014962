import { Grid } from '@mui/material'
import { RfhTypography } from '@rfh/ui'

import {
  StyledGridSmall,
  StyledRfhTypographyText,
} from './ProductGroupRegularityDetails.styles'

type ProductGroupRegularityDetailsItemProps = {
  label: string
  value: any
}

export default function ProductGroupRegularityDetailsItem({
  label,
  value,
}: Readonly<ProductGroupRegularityDetailsItemProps>): JSX.Element {
  return (
    <StyledGridSmall container>
      <Grid item xs={12} sm={6}>
        <RfhTypography variant='subheader'>{label}</RfhTypography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <StyledRfhTypographyText variant='body-copy'>
          {value}
        </StyledRfhTypographyText>
      </Grid>
    </StyledGridSmall>
  )
}
