import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Box, Container } from '@mui/material'
import EmptyState from '@rfh/ui/components/EmptyState/EmptyState'
import EmptyStateIcon from '@rfh/ui/components/EmptyState/EmptyStateIcon'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'

interface IProps {
  error: any
}

const GenericError: FC<IProps> = (props: IProps): ReactElement => {
  const { error } = props
  const history = useHistory()
  const { t } = useTranslation()

  const retry = (): void => {
    history.push('/dashboard')
  }

  return (
    <Container
      maxWidth='lg'
      sx={{
        maxWidth: '1280px',
        height: 'calc(100vh - 72px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '770px',
        }}
      >
        <EmptyState
          header={t('common.genericError')}
          subheader={<p>{String(error)}</p>}
          graphic={<EmptyStateIcon />}
          bottomComponent={
            <RfhButton variant='block--contained' onClick={retry}>
              {t('common.retry')}
            </RfhButton>
          }
        />
      </Box>
    </Container>
  )
}

export default GenericError
