import { ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@mui/material/FormControl'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { RfhTypography } from '@rfh/ui/components/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import {
  FilterFieldsStack,
  FilterStack,
  LocationSelect,
} from 'src/common/components'
import { VestigingCode } from 'src/common/enums'

import useFilterStore from '../stores/FilterStore'

export default function BatchesStatusFilter(): JSX.Element {
  const { t } = useTranslation()
  const { filter, setFilter } = useFilterStore()
  const [locationCode, setLocationCode] = useState<VestigingCode>(
    filter.locationCode
  )

  const changeLocation = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const newLocationCode = +event.target.value
      setLocationCode(newLocationCode)
      setFilter({
        locationCode: newLocationCode,
      })
    },
    [setFilter]
  )

  return (
    <FilterStack>
      <FilterFieldsStack>
        <FormControl>
          <RfhTypography variant={'subheader'}>
            {t('common.auctionDate')}
          </RfhTypography>
          <DatePicker
            aria-label={'auction date'}
            value={new Date(filter.auctionDate)}
            onChange={newDate => {
              setFilter({ auctionDate: new Date(newDate) })
            }}
            slotProps={{
              textField: {
                sx: {
                  mt: 1.5,
                  bgcolor: RfhColors.white,
                  '.MuiInputBase-root': { paddingLeft: 1, paddingRight: 1 },
                  maxWidth: 180,
                },
              },
            }}
          />
        </FormControl>
        <FormControl>
          <LocationSelect
            addCatchAll
            locationCode={locationCode}
            onChange={changeLocation}
          />
        </FormControl>
      </FilterFieldsStack>
    </FilterStack>
  )
}
