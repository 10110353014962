import { useCallback, useReducer } from 'react'

import { LookupDialog } from 'src/common/components'
import { VestigingCode } from 'src/common/enums'
import {
  byCodeSort,
  fromIProductVeilgroepToItem,
  getStringValue,
  initialState,
  refreshReducer,
} from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { getProductAuctionGroupsByBloemenPlantenVestigingAsync } from 'src/common/services'
import type { BloemenPlanten, Item } from 'src/common/types'
import { snackbarUtils } from 'src/common/utils'

import { Kind } from '../../constants'
import { useAllocationsStore, useRuleStore } from '../../stores'

type AuctionGroupDialogProps = {
  kind?: Kind.regular | Kind.supplier
}

export default function AuctionGroupsDialog({
  kind = Kind.regular,
}: Readonly<AuctionGroupDialogProps>): JSX.Element {
  const { apiClient } = useApiClient()
  const { filter } = useAllocationsStore()
  const { rule, setRule } = useRuleStore()
  const [state, dispatch] = useReducer(refreshReducer, initialState)

  const getItems = useCallback(async () => {
    if (!apiClient || state.loading) {
      return Promise.resolve([] as Item<number>[])
    }

    try {
      dispatch({ type: 'PENDING' })
      const fetchedItems = (
        await getProductAuctionGroupsByBloemenPlantenVestigingAsync(
          apiClient,
          filter.bloemenPlantenCode as BloemenPlanten,
          filter.locationCode as VestigingCode
        )
      ).map(fromIProductVeilgroepToItem)
      fetchedItems.sort(byCodeSort)
      return fetchedItems
    } catch (error: any) {
      snackbarUtils.error(getStringValue(error))
    } finally {
      dispatch({ type: 'RESOLVED' })
    }
  }, [apiClient, filter.bloemenPlantenCode, filter.locationCode, state.loading])

  function handleChange(item: Item<number>): void {
    if (kind === Kind.regular) {
      setRule({
        ...rule,
        veilgroepCode: item.code,
        veilgroepOmschrijving: item.name,
      })
    } else {
      setRule({
        ...rule,
        veilgroepCodeAanvoerder: item.code,
        veilgroepOmschrijvingAanvoerder: item.name,
      })
    }
  }

  return (
    <LookupDialog
      getItems={getItems}
      searchTitle={'Zoek productveilgroep'}
      onChange={handleChange}
    />
  )
}
