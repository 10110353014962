import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  NativeSelect,
} from '@mui/material'
import Box from '@mui/material/Box'
import { Button, DataGrid, RfhTypography } from '@rfh/ui'
import { Row } from '@rfh/ui/components/DataGrid/DataGrid'
import { DeleteIcon } from '@rfh/ui/shared/floriday-icons'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { IVeilgroepRegelKenmerk } from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'

import { byKenmerkcode, ruleCharacteristicToView } from '../lib'
import {
  addAuctionGroupRuleCharacteristic,
  deleteAuctionGroupRuleCharacteristic,
  getValueByCharacteristicCode,
} from '../services'
import { useAuctionGroupRuleStore } from '../stores'
import { VeilgroepRegelKenmerkView } from '../types'

type AddRuleCharacteristicProps = {
  open: boolean
  onClose: () => void
}

export const AddRuleCharacteristic: FC<AddRuleCharacteristicProps> = ({
  open,
  onClose,
}) => {
  const { apiClient } = useApiClient()
  const { t } = useTranslation()
  const user = useUser()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues: VeilgroepRegelKenmerkView = {
    aanmaakDatumTijd: new Date(),
    aanmaakGebruiker: user.sub,
    mutatieDatumTijd: new Date(),
    mutatieGebruiker: user.sub,
    veilgroepRegelId: undefined,
    kenmerkCode: undefined,
    kenmerkWaardeTtm: undefined,
    kenmerkWaardeVan: undefined,
    kenmerkOmschrijving: undefined,
  }

  const {
    characteristics,
    auctionGroupRule,
    auctionGroupRuleCharacteristics,
    characteristicValues,
    setCharacteristicValues,
    addCharacteristic,
    removeCharacteristic,
    setMustRefetchAuctionGroupRuleCharacteristics,
  } = useAuctionGroupRuleStore()

  const [inProgress, setInProgress] = useState(false)
  const [auctionGroupRuleCharacteristic, setAuctionGroupRuleCharacteristic] =
    useState<VeilgroepRegelKenmerkView>(defaultValues)

  const views = useMemo(
    () => [
      ...auctionGroupRuleCharacteristics.map(arc =>
        ruleCharacteristicToView(arc, characteristics)
      ),
    ],
    [auctionGroupRuleCharacteristics, characteristics]
  )

  const fetchCharacteristicValues = useCallback(
    async (characteristic: VeilgroepRegelKenmerkView) => {
      if (!characteristic.kenmerkCode) {
        return
      }
      try {
        setInProgress(true)
        const result = await getValueByCharacteristicCode(
          apiClient,
          characteristic.kenmerkCode
        )
        if (result.length > 0) {
          setCharacteristicValues(result)
        }
      } catch (error: any) {
        snackbarUtils.error(String(error))
      } finally {
        setInProgress(false)
      }
    },
    [apiClient, setCharacteristicValues]
  )

  const getCharacteristicOptions = useCallback(() => {
    const characteristicData = characteristics.map(characteristic => (
      <option
        key={characteristic.kenmerkCode}
        value={characteristic.kenmerkCode}
      >
        {characteristic.kenmerkCode} {characteristic.kenmerkOmschrijving}
      </option>
    ))
    characteristicData.unshift(<option key='' value=''></option>)
    return characteristicData
  }, [characteristics])

  const getCharacteristicValueOptions = useCallback(() => {
    const valueData = characteristicValues.map(characteristicValue => (
      <option
        key={characteristicValue.kenmerkWaardeCode}
        value={characteristicValue.kenmerkWaardeCode}
      >
        {characteristicValue.kenmerkWaardeCode}{' '}
        {characteristicValue.kenmerkWaardeOmschrijving}
      </option>
    ))

    valueData.unshift(<option key='' value=''></option>)
    return valueData
  }, [characteristicValues])

  const onCharacteristicChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target
      setCharacteristicValues([])
      if (!value || value === '') {
        setAuctionGroupRuleCharacteristic({
          ...auctionGroupRuleCharacteristic,
          [name]: undefined,
          kenmerkWaardeVan: '',
          kenmerkWaardeTtm: '',
        })
      } else {
        setAuctionGroupRuleCharacteristic({
          ...auctionGroupRuleCharacteristic,
          [name]: value,
          kenmerkWaardeVan: '',
          kenmerkWaardeTtm: '',
        })
      }
    },
    [auctionGroupRuleCharacteristic, setCharacteristicValues]
  )

  const onCharacteristicValueChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target
      if (!value || value === '') {
        setAuctionGroupRuleCharacteristic({
          ...auctionGroupRuleCharacteristic,
          [name]: undefined,
        })
      } else {
        setAuctionGroupRuleCharacteristic({
          ...auctionGroupRuleCharacteristic,
          [name]: value,
        })
      }
    },
    [auctionGroupRuleCharacteristic]
  )

  const validateCharacteristic = useCallback(
    (characteristic: IVeilgroepRegelKenmerk) => {
      if (characteristic.kenmerkWaardeTtm < characteristic.kenmerkWaardeVan) {
        throw new Error(t('auctionRule.invalidCharacteristicValues'))
      }
    },
    [t]
  )

  const onCreate = useCallback(async () => {
    setInProgress(true)
    const newVeilgroepRegelKenmerk: IVeilgroepRegelKenmerk = {
      ...auctionGroupRuleCharacteristic,
      veilgroepRegelId: auctionGroupRule.veilgroepRegelId,
      aanmaakDatumTijd: new Date(),
      aanmaakGebruiker: user.sub,
      mutatieDatumTijd: new Date(),
      mutatieGebruiker: user.sub,
    }

    try {
      validateCharacteristic(newVeilgroepRegelKenmerk)
      const result = await addAuctionGroupRuleCharacteristic(
        apiClient,
        newVeilgroepRegelKenmerk
      )
      if (result) {
        addCharacteristic(result)
      }
      setAuctionGroupRuleCharacteristic({
        ...defaultValues,
        veilgroepRegelId: auctionGroupRule.veilgroepRegelId,
        kenmerkOmschrijving: undefined,
        kenmerkCode: undefined,
        kenmerkWaardeVan: undefined,
        kenmerkWaardeTtm: undefined,
      })
      setMustRefetchAuctionGroupRuleCharacteristics(true)
    } catch (error: any) {
      snackbarUtils.error(String(error))
    } finally {
      setInProgress(false)
    }
  }, [
    auctionGroupRuleCharacteristic,
    auctionGroupRule.veilgroepRegelId,
    user.sub,
    validateCharacteristic,
    apiClient,
    defaultValues,
    setMustRefetchAuctionGroupRuleCharacteristics,
    addCharacteristic,
  ])

  const onDelete = useCallback(
    async (idToBeDeleted: number) => {
      if (!apiClient || !idToBeDeleted) return Promise.resolve()
      try {
        setInProgress(true)
        await deleteAuctionGroupRuleCharacteristic(apiClient, idToBeDeleted)
        removeCharacteristic(idToBeDeleted)
      } catch (error: any) {
        snackbarUtils.error(String(error))
      } finally {
        setInProgress(false)
      }
    },
    [apiClient, removeCharacteristic]
  )

  const handleClose = () => {
    setAuctionGroupRuleCharacteristic(defaultValues)
    onClose()
  }

  const getRows = useCallback(() => {
    const rows: Row[] = [
      {
        header: true,
        values: [
          t('auctionRule.characteristicCode'),
          t('auctionRule.ruleDescription'),
          t('auctionRule.from'),
          t('auctionRule.till'),
          <DeleteIcon
            key={0}
            sx={{
              color: RfhColors.fogWhite,
              width: '20px',
              height: '20px',
            }}
          />,
        ],
      },
    ]
    let newRow: Row = null
    views.sort(byKenmerkcode)
    views.forEach(characteristic => {
      newRow = {
        header: false,
        values: [
          characteristic.kenmerkCode,
          characteristic.kenmerkOmschrijving,
          characteristic.kenmerkWaardeVan,
          characteristic.kenmerkWaardeTtm,
          <DeleteIcon
            onClick={() => onDelete(characteristic.veilgroepRegelKenmerkId)}
            key={characteristic.veilgroepRegelKenmerkId}
            sx={{
              cursor: 'pointer',
              color: RfhColors.darkGrey,
              width: '20px',
              height: '20px',
            }}
          />,
        ],
      }
      rows.push(newRow)
    })

    return rows
  }, [t, views, onDelete])

  const rows = getRows()

  const disableButton = useMemo(
    () =>
      auctionGroupRuleCharacteristic.kenmerkCode &&
      !auctionGroupRuleCharacteristic.kenmerkWaardeVan,
    [auctionGroupRuleCharacteristic]
  )

  useEffect(() => {
    const refreshCharacteristicValues = async () => {
      apiClient &&
        (await fetchCharacteristicValues(auctionGroupRuleCharacteristic))
    }
    open &&
      auctionGroupRuleCharacteristic.kenmerkCode &&
      refreshCharacteristicValues()
  }, [
    apiClient,
    auctionGroupRuleCharacteristic.kenmerkCode,
    open,
    fetchCharacteristicValues,
    auctionGroupRuleCharacteristic,
  ])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{
          backgroundColor: RfhColors.lightGrey,
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <RfhTypography variant='h5' style={{ margin: ThemeConfig.spacing.xs }}>
          {t('auctionRule.title.AddCharacteristic')}
        </RfhTypography>

        <Box
          component='form'
          sx={{
            width: '100%',
            margin: ThemeConfig.spacing.xs,
          }}
          onSubmit={onCreate}
        >
          <FormGroup
            sx={{
              width: '100%',
              marginTop: ThemeConfig.spacing.xs,
            }}
          >
            <Grid container sx={{ margin: ThemeConfig.spacing.xs }}>
              <Grid item xs={12} sm={4} sx={{ verticalAlign: 'center' }}>
                <FormLabel>{t('auctionRule.characteristicCode')}</FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl
                  sx={{
                    width: '100%',
                    backgroundColor: RfhColors.white,
                  }}
                >
                  <NativeSelect
                    name='kenmerkCode'
                    onChange={onCharacteristicChange}
                    value={auctionGroupRuleCharacteristic.kenmerkCode ?? ''}
                  >
                    {getCharacteristicOptions()}
                  </NativeSelect>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container sx={{ margin: ThemeConfig.spacing.xs }}>
              <Grid item xs={12} sm={4} sx={{ verticalAlign: 'center' }}>
                <FormLabel>
                  {t('auctionRule.characteristicValueCodeFrom')}
                </FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl
                  sx={{
                    width: '100%',
                    backgroundColor: RfhColors.white,
                  }}
                >
                  <NativeSelect
                    name='kenmerkWaardeVan'
                    onChange={onCharacteristicValueChange}
                    value={
                      auctionGroupRuleCharacteristic.kenmerkWaardeVan ?? ''
                    }
                  >
                    {getCharacteristicValueOptions()}
                  </NativeSelect>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container sx={{ margin: ThemeConfig.spacing.xs }}>
              <Grid item xs={12} sm={4} sx={{ verticalAlign: 'center' }}>
                <FormLabel>
                  {t('auctionRule.characteristicValueCodeTil')}
                </FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl
                  sx={{
                    width: '100%',
                    backgroundColor: RfhColors.white,
                  }}
                >
                  <NativeSelect
                    name='kenmerkWaardeTtm'
                    onChange={onCharacteristicValueChange}
                    value={
                      auctionGroupRuleCharacteristic.kenmerkWaardeTtm ?? ''
                    }
                  >
                    {getCharacteristicValueOptions()}
                  </NativeSelect>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container sx={{ margin: ThemeConfig.spacing.xs }}>
              <Button
                size='small'
                variant='block--contained'
                isLoading={inProgress}
                disabled={disableButton}
                onClick={onCreate}
              >
                {t('common.add')}
              </Button>
            </Grid>
          </FormGroup>
        </Box>

        <DataGrid label={t('auctionRule.characteristics')} rows={rows} />
        <DialogActions>
          <Button variant='block--outlined' onClick={handleClose}>
            {t('common.ok')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
