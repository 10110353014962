import { Grid, styled } from '@mui/material'
import { DatePicker, RfhTypography } from '@rfh/ui'

import { ThemeConfig } from 'src/common/config/SpacingConfig'

export const StyledRfhTypographyText = styled(RfhTypography)(() => ({
  display: 'flex',
  padding: 0,
  justifyContent: 'space-between',
  my: ThemeConfig.spacing.xs,
}))

export const StyledGrid = styled(Grid)(() => ({
  display: 'flex',
  padding: 0,
  justifyContent: 'space-between',
  my: ThemeConfig.spacing.xs,
}))

export const StyledRfhTypographyHeader = styled(RfhTypography)(() => ({
  my: ThemeConfig.spacing.xs,
}))

export const StyledDatePicker = styled(DatePicker)(() => ({
  zIndex: 100,
  textAlign: 'left',
}))
