import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Grid } from '@mui/material'
import {
  GridPaginationInitialState,
  GridSortModel,
} from '@mui/x-data-grid-premium'
import { RfhTypography as Typography } from '@rfh/ui'

import { Divider } from 'src/common/components'
import { DEFAULT_PAGE_SIZE } from 'src/common/constants'
import { getStringValue } from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { getSupplierProductGroupRegularityExceptions } from 'src/common/services'
import {
  IAanvoerderProductgroepRegelmatigUitzonderingView,
  IClient,
} from 'src/common/services/client'
import {
  getDefaultPagination,
  getDefaultSortModel,
  getOrderBy,
  snackbarUtils,
} from 'src/common/utils'

import {
  OverviewsFilter,
  ProductGroupRegularityExceptionsGrid,
} from '../components'

export default function ProductGroupRegularityExceptions(): JSX.Element {
  const CACHE_KEY = 'product-group-regularity-exceptions-datagrid'
  const { t } = useTranslation()
  const { apiClient } = useApiClient()
  const user = useUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultSortModel: GridSortModel = [
    {
      sort: 'asc',
      field: 'uitzonderingDatumVanaf',
    },
  ]
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [filterString, setFilterString] = useState<string>('')
  const [totalCount, setTotalCount] = useState<number>(0)
  const [rows, setRows] = useState<
    IAanvoerderProductgroepRegelmatigUitzonderingView[]
  >([])
  const [paginationState, setPaginationState] =
    useState<GridPaginationInitialState>(() => getDefaultPagination(CACHE_KEY))
  const [sortModel, setSortModel] = useState<GridSortModel>(
    getDefaultSortModel(CACHE_KEY, defaultSortModel)
  )
  const skip =
    paginationState?.paginationModel?.page &&
    paginationState?.paginationModel?.pageSize
      ? paginationState.paginationModel.page *
        paginationState.paginationModel.pageSize
      : 0
  const orderBy =
    sortModel.length > 0 ? getOrderBy(sortModel) : getOrderBy(defaultSortModel)
  const top = paginationState?.paginationModel?.pageSize ?? DEFAULT_PAGE_SIZE

  const changeSortModel = (value: GridSortModel) => {
    setSortModel(value?.length > 0 ? value : defaultSortModel)
  }

  const updateFilterString = useCallback(
    (value: string) => {
      setPaginationState({
        ...getDefaultPagination(CACHE_KEY),
        paginationModel: { page: 0 },
      })
      setFilterString(value)
    },
    [setFilterString, setPaginationState]
  )

  const fetchData = useCallback(
    async (client: IClient): Promise<void> => {
      if (!client || inProgress) {
        return Promise.resolve()
      }

      try {
        setInProgress(true)
        const filter =
          filterString && filterString.length > 0 ? filterString : undefined

        const { count, records } =
          await getSupplierProductGroupRegularityExceptions(apiClient, {
            top,
            skip,
            filter,
            count: true,
            orderby: orderBy,
          })

        setRows(records)
        setTotalCount(count)
      } catch (error: any) {
        snackbarUtils.error(getStringValue(error))
      } finally {
        setInProgress(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterString, inProgress]
  )

  const resetSearch = useCallback(() => {
    setFilterString('')
    setPaginationState({
      ...getDefaultPagination(CACHE_KEY),
      paginationModel: { page: 0 },
    })
    setSortModel(defaultSortModel)
  }, [defaultSortModel])

  useEffect(() => {
    setPaginationState(getDefaultPagination(CACHE_KEY))
  }, [])

  useEffect(() => {
    if (sortModel.length === 0) {
      return
    }
    fetchData(apiClient)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, filterString])

  return (
    <Container maxWidth='xl'>
      <Grid item xs={12} mt={4} mb={2}>
        <Typography variant={'h3'} sx={{ fontWeight: 'bold' }}>
          {user.isAuctionCoordinator
            ? t('common.titles.overviewsProductGroup')
            : t('common.titles.overviewsProductGroupReadOnly')}
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <OverviewsFilter
          changeFilterString={updateFilterString}
          resetSearch={resetSearch}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider {...{ marginTop: 16 }} />
      </Grid>
      <Grid item sm={12}>
        <ProductGroupRegularityExceptionsGrid
          cacheKey={CACHE_KEY}
          rows={rows}
          isLoading={inProgress}
          paginationStateChange={setPaginationState}
          sortModelChange={changeSortModel}
          refresh={() => fetchData(apiClient)}
          rowCount={totalCount}
        />
      </Grid>
    </Container>
  )
}
