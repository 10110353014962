import { useCallback, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  ActionButton,
  FilterButtonsStack,
  FilterStack,
} from 'src/common/components'
import { usePathname } from 'src/common/hooks'
import { initialState, refreshReducer } from 'src/common/lib'
import { useApiClient, useUser } from 'src/common/providers'
import { snackbarUtils } from 'src/common/utils'

import { FEATURE_ROOT_PATH } from '../constants'
import { useSupplierPromotionCharacteristic } from '../hooks'
import { addCharacteristic, updateCharacteristic } from '../services'
import { useSupplierPromotionCharacteristicStore } from '../stores'

export default function CharacteristicMutationFilter(): JSX.Element {
  const history = useHistory()
  const userSub = useUser().sub
  const { t } = useTranslation()
  const { ADDING } = usePathname(FEATURE_ROOT_PATH)
  const { apiClient } = useApiClient()
  const [state, dispatch] = useReducer(refreshReducer, initialState)
  const { isFormValid } = useSupplierPromotionCharacteristic()
  const {
    characteristic,
    setCharacteristic,
    resetSupplierPromotionCharacteristicState,
    setOldCharacteristic,
    isDataDirty,
  } = useSupplierPromotionCharacteristicStore()

  function goBack() {
    resetSupplierPromotionCharacteristicState()
    history.goBack()
  }

  const handleSubmit = useCallback(async () => {
    if (!apiClient || !isFormValid(/*showErrors=*/ true) || state.loading) {
      return Promise.resolve()
    }
    try {
      setCharacteristic({
        ...characteristic,
        mutatieGebruiker: userSub,
        mutatieDatumTijd: new Date(),
      })
      dispatch({ type: 'PENDING' })
      if (ADDING) {
        setCharacteristic({
          ...characteristic,
          aanmaakGebruiker: userSub,
          aanmaakDatumTijd: new Date(),
        })
        const result = await addCharacteristic(apiClient, characteristic)
        if (result?.aanvoerderPromotieKenmerkId > 0) {
          setCharacteristic(result)
          setOldCharacteristic(result)
          snackbarUtils.success(t('common.statusCodes.success'))
          history.replace(
            `/dashboard/${FEATURE_ROOT_PATH}/${result.aanvoerderPromotieKenmerkId}`
          )
        }
      } else {
        await updateCharacteristic(apiClient, characteristic)
        setOldCharacteristic(characteristic)
      }
    } catch (error) {
      dispatch({ type: 'ERROR' })
      snackbarUtils.error(`${error}`)
    } finally {
      dispatch({ type: 'RESOLVED' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiClient,
    isFormValid,
    setCharacteristic,
    characteristic,
    userSub,
    ADDING,
    setOldCharacteristic,
    t,
    history,
  ])

  return (
    <FilterStack justify={'flex-end'}>
      <FilterButtonsStack>
        <ActionButton variant='block--outlined' onClick={goBack}>
          {t('common.back')}
        </ActionButton>
        <ActionButton
          type='submit'
          disabled={!ADDING && !isDataDirty()}
          onClick={handleSubmit}
        >
          {t('common.save')}
        </ActionButton>
      </FilterButtonsStack>
    </FilterStack>
  )
}
