import { useCallback, useEffect } from 'react'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import { Divider } from 'src/common/components/Divider'
import { usePathname } from 'src/common/hooks'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import {
  AanvoerderclusterAanvoerder,
  IClient,
} from 'src/common/services/client'

import { SuppliersFilter, SuppliersGrid } from '../components'
import { FEATURE_ROOT_PATH } from '../constants'
import { getClusterAsync } from '../services'
import { useSupplierClusterStore } from '../stores/SupplierClusterStore'

export default function ManageSupplierCluster(): JSX.Element {
  const { apiClient } = useApiClient()
  const {
    cluster,
    clusterAanvoerders,
    mustRefetchCluster,
    ordered,
    renumber,
    setCluster,
    setClusterAanvoerders,
    setMustRefetchCluster,
    setOldCluster,
    setOrderedChanged,
  } = useSupplierClusterStore()
  const { ADDING } = usePathname(FEATURE_ROOT_PATH)

  const refetchCluster = useCallback(
    async (client: IClient) => {
      if (!client || !cluster) {
        return Promise.resolve()
      }

      const refetchedCluster = await getClusterAsync(
        client,
        cluster.aanvoerderclusterID
      )
      setClusterAanvoerders(refetchedCluster.aanvoerderclusterAanvoerders)
      setCluster(refetchedCluster)
      setOldCluster(refetchedCluster)
      setOrderedChanged(false)
    },
    [
      cluster,
      setCluster,
      setClusterAanvoerders,
      setOldCluster,
      setOrderedChanged,
    ]
  )

  const fixNumbering = useCallback((): void => {
    const maxSequenceNumber = clusterAanvoerders.reduce(
      (max: number, avr: AanvoerderclusterAanvoerder) =>
        avr.volgordeNummer > max ? avr.volgordeNummer : max,
      0
    )
    if (ordered && clusterAanvoerders.length !== maxSequenceNumber) {
      renumber()
      setOrderedChanged(true)
    }
  }, [clusterAanvoerders, ordered, renumber, setOrderedChanged])

  useEffect(() => {
    if (mustRefetchCluster) {
      refetchCluster(apiClient).then(() => fixNumbering())
      setMustRefetchCluster(false)
      setOrderedChanged(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiClient,
    fixNumbering,
    mustRefetchCluster,
    setMustRefetchCluster,
    setOrderedChanged,
  ])

  useEffect(() => {
    if (!ADDING) {
      setMustRefetchCluster(true)
    }
  }, [ADDING, setMustRefetchCluster])

  return (
    <Container maxWidth='xl'>
      <Grid item xs={12}>
        <SuppliersFilter />
      </Grid>
      <Divider {...{ marginTop: 16 }} />
      <Grid item xs={12}>
        <SuppliersGrid />
      </Grid>
    </Container>
  )
}
