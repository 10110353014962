import {
  IAllSearchFilterFields,
  IApiClientGetFilter,
  IApiClientListFilter,
} from '../interfaces'
import { toProductGroupRegularity } from '../lib'
import { ListAndCount } from '../types'
import { IAanvoerderProductgroepRegelmatigView, IClient } from './client'

export const getSupplierProductGroupRegularityView = async (
  apiClient: IClient,
  apiClientFilter: Partial<IApiClientGetFilter>
): Promise<IAanvoerderProductgroepRegelmatigView> => {
  const result =
    // eslint-disable-next-line max-len
    (await apiClient.aanvoerderProductgroepRegelmatigViews_AanvoerderProductgroepRegelmatigView_GetAanvoerderProductgroepRegelmatigView(
      apiClientFilter.key,
      undefined,
      undefined
    )) as any

  const cleanedResult: IAanvoerderProductgroepRegelmatigView =
    process.env.REACT_APP_MOCK_API === 'true' ? result : result?.cleanedResponse

  const record = toProductGroupRegularity(cleanedResult)
  return record
}

export const getSupplierProductGroupRegularityViews = async (
  apiClient: IClient,
  apiClientFilter: Partial<IApiClientListFilter>
): Promise<ListAndCount<IAanvoerderProductgroepRegelmatigView>> => {
  const result =
    // eslint-disable-next-line max-len
    (await apiClient.aanvoerderProductgroepRegelmatigViews_AanvoerderProductgroepRegelmatigView_ListAanvoerderProductgroepRegelmatigView(
      apiClientFilter.top,
      apiClientFilter.skip,
      undefined,
      apiClientFilter.filter,
      true,
      apiClientFilter.orderby,
      undefined,
      undefined
    )) as any

  const records: IAanvoerderProductgroepRegelmatigView[] =
    process.env.REACT_APP_MOCK_API === 'true'
      ? // ? extractMockRecords(result)
        result?.records?.map(toProductGroupRegularity)
      : result?.cleanedResponse?.value
  const count =
    process.env.REACT_APP_MOCK_API === 'true'
      ? result.count
      : result?.['@odata.count'] ?? 0

  return { records, count }
}

export const getProductGroupRegularityHistory = async (
  apiClient: IClient,
  apiClientFilter: Partial<IAllSearchFilterFields>
): Promise<Partial<IAanvoerderProductgroepRegelmatigView>[]> => {
  const result =
    (await apiClient.aanvoerderProductgroepRegelmatigViews_ZoekHistorie(
      apiClientFilter.vestigingCode,
      apiClientFilter.aanvoerderNummer,
      apiClientFilter.productgroepCode
    )) as any
  const records: Partial<IAanvoerderProductgroepRegelmatigView>[] =
    result?.cleanedResponse?.value
  return records
}
