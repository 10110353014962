import { TableCell, TableHead, TableRow } from '@mui/material'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { getHeaderCellStyles } from './lib'

export default function ClocksStatusTableHeader(): JSX.Element {
  return (
    <TableHead>
      <TableRow style={{ backgroundColor: RfhColors.fogWhite }}>
        <TableCell
          style={{
            ...getHeaderCellStyles(),
            overflow: 'visible',
            width: '11.5%',
          }}
        >
          Klok
        </TableCell>
        <TableCell style={{ ...getHeaderCellStyles(), width: '48.5%' }}>
          Veilgroep
        </TableCell>
        <TableCell style={getHeaderCellStyles('right')}>#Pty</TableCell>
        <TableCell
          style={{
            ...getHeaderCellStyles(),
            paddingLeft: 0,
            paddingRight: '1em',
          }}
        >
          #Geveiled
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
