import { MutableRefObject, useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'
import SearchIcon from '@rfh/ui/shared/floriday-icons/icons/SearchIcon'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { DotRef } from 'src/common/components'
import { snackbarUtils } from 'src/common/utils'

import { useRuleStore } from '../../stores'
import { CodeSoort } from '../../types'
import { MultiSelectDialog } from '../MultiSelectDialog'

type LabelIconTextProps = {
  codeSoort: CodeSoort
  fieldName?: string
  label: string
}

export default function LabelIconText({
  codeSoort,
  fieldName,
  label = '',
}: Readonly<LabelIconTextProps>): JSX.Element {
  const { concatCodesText, initialCodesText, rule } = useRuleStore()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isTimeoutReached, setIsTimeoutReached] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [text, setText] = useState<string>('')

  function openDialog() {
    setIsOpen(true)
    setIsLoading(true)
  }

  function closeDialog() {
    setIsOpen(false)
    setIsLoading(false)
  }

  let operationTimeout: NodeJS.Timeout

  function clearOperationTimeout() {
    operationTimeout && clearTimeout(operationTimeout)
    setIsTimeoutReached(false)
  }

  const handleDialogMount = (dialogIsMounted: MutableRefObject<boolean>) => {
    if (isOpen && dialogIsMounted.current) {
      clearOperationTimeout()
      setIsLoading(false)
    }
  }

  //! Research: Assignments to the 'operationTimeout' variable
  //! from inside React hook useEffect will be lost on each render.
  useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      operationTimeout = setTimeout(() => {
        setIsTimeoutReached(true)
        snackbarUtils.error('Operation timeout')
      }, 5000)
    }

    return clearOperationTimeout()
  }, [isOpen])

  useEffect(() => {
    const calculatedText = concatCodesText(codeSoort)
    if (calculatedText) {
      return setText(calculatedText)
    }
    const initialText = initialCodesText(codeSoort)
    setText(initialText)
  }, [codeSoort, concatCodesText, initialCodesText, rule])

  return (
    <>
      <Grid
        container
        item
        direction={'row'}
        justifyContent={'flex-start'}
        alignItems={'center'}
      >
        <Grid item>
          <Typography
            aria-label={`id-${fieldName}`}
            variant={'body-copy'}
            sx={{
              color: RfhColors.black,
              whiteSpace: 'nowrap',
              paddingBottom: 0,
              marginY: '0.7em',
              minWidth: 170,
            }}
          >
            {label}
          </Typography>
        </Grid>
        {/* dialog trigger button */}
        <IconButton aria-label={'lookup icon button'} onClick={openDialog}>
          <SearchIcon
            sx={{
              color: RfhColors.darkOrange,
              height: 20,
              py: 0,
              marginLeft: '-0.25em',
            }}
          />
        </IconButton>
        <Grid item>
          <MultiSelectDialog
            codeSoort={codeSoort}
            dialogTitle={`Selecteer ${codeSoort}`}
            isLoading={isLoading}
            isOpen={isOpen}
            isTimeoutReached={isTimeoutReached}
            onClose={closeDialog}
          />
        </Grid>
        <Grid item xs={8}>
          {text}
        </Grid>
      </Grid>
      <DotRef onMount={handleDialogMount} />
    </>
  )
}
