import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  NativeSelect,
  TextareaAutosize,
} from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Button, RfhTypography, TextField } from '@rfh/ui'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { Divider } from 'src/common/components'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { GRID_MARGIN_TOP } from 'src/common/constants'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { IClient, IVeilgroepRegel } from 'src/common/services/client'
import {
  constructFilterRuleForKVP,
  getOrderBy,
  snackbarUtils,
} from 'src/common/utils'

import { AuctionPeriod, FormInput } from '../components'
import {
  extractAuctionPeriod,
  ruleCharacteristicsToString,
  stringToLoadCarierTypes,
  stringToQuality,
  stringToSupplierTypes,
} from '../lib'
import { validateAuctionPeriod, validateDescription } from '../lib/lib.utils'
import { CharacteristicsProvider } from '../providers'
import getAllAuctionGroupRulesAsync, {
  addAuctionGroupRuleAsync,
  getAuctionGroupRuleByIdAsync,
  getByAuctionGroupRuleId,
  updateAuctionGroupRuleAsync,
} from '../services'
import { useAuctionGroupRuleStore, useAuctionGroupRulesStore } from '../stores'
import { VeilgroepRegelKenmerkView } from '../types'
import { AddRuleCharacteristic } from './AddRuleCharacteristic'

export const ManageAuctionGroupRules: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const user = useUser()
  const { apiClient } = useApiClient()
  const { id } = useParams<{ id?: string }>()

  /*
  State
  */
  const [inProgress, setInProgress] = useState(false)
  const {
    auctionGroupRule,
    characteristics,
    auctionGroupRuleCharacteristics,
    mustRefetchAuctionGroupRule,
    quality,
    loadCarriers,
    supplier,
    auctionPeriod,
    oldAuctionGroupRule,
    setSupplier,
    setRule,
    setRuleCharacteristics,
    setMustRefetchRule,
    resetAuctionGroupRuleStoreState,
    setLoadCarriers,
    setQuality,
    setAuctionPeriod,
    setOldRule,
  } = useAuctionGroupRuleStore()
  const { setMustRefetchAuctionGroupRules } = useAuctionGroupRulesStore()
  const [open, setOpen] = useState(false)

  const characteristicContent = useMemo(
    () =>
      ruleCharacteristicsToString(
        auctionGroupRuleCharacteristics as VeilgroepRegelKenmerkView[],
        characteristics
      ),
    [auctionGroupRuleCharacteristics, characteristics]
  )

  const getBooleanOptions = useCallback(() => {
    const booleanOptions = []
    booleanOptions.push(
      <option key='ntv' value=''>
        {t('auctionRule.notApplicable')}
      </option>
    )
    booleanOptions.push(
      <option key='true' value='1'>
        {t('common.yes')}
      </option>
    )
    booleanOptions.push(
      <option key='false' value='0'>
        {t('common.no')}
      </option>
    )
    return booleanOptions
  }, [t])

  const handleChange = useCallback(
    (event: any) => {
      const { name, value } = event.target
      setRule({
        ...auctionGroupRule,
        [name]: value,
      })
    },
    [auctionGroupRule, setRule]
  )

  const handleBooleanChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target
      setRule({
        ...auctionGroupRule,
        [name]: value === '' ? null : Boolean(JSON.parse(value)),
      })
    },
    [auctionGroupRule, setRule]
  )

  const handleQualityChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setQuality({
        ...quality,
        [target.name]: checked,
      })
    },
    [quality, setQuality]
  )

  const handleSupplyTypesChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const newSupplier = { ...supplier, [target.name]: checked }
      if (target.name === 'GP' || target.name === 'GPPlus') {
        newSupplier.GPPlus = checked
      }
      setSupplier(newSupplier)
    },
    [supplier, setSupplier]
  )

  const handleloadCarrierTypesChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const newLoadCarrier = {
        ...loadCarriers,
        [target.name]: checked,
      }
      setLoadCarriers(newLoadCarrier)
    },
    [loadCarriers, setLoadCarriers]
  )

  const openCharacteristicDialog = () => setOpen(true)

  const closeCharacteristicDialog = () => setOpen(false)

  const addRule = useCallback(async () => {
    if (inProgress) {
      return Promise.resolve()
    }
    setInProgress(true)
    const newRule: IVeilgroepRegel = {
      ...auctionGroupRule,
      ...auctionPeriod,
      aanmaakDatumTijd: new Date(),
      aanmaakGebruiker: user.sub,
      mutatieDatumTijd: new Date(),
      mutatieGebruiker: user.sub,
    }

    if (!validateAuctionPeriod(auctionPeriod)) {
      setInProgress(false)
      snackbarUtils.error(t('auctionRule.invalidAuctionPeriod'))
      return
    }
    if (!validateDescription(newRule)) {
      setInProgress(false)
      snackbarUtils.error(t('auctionRule.invalidDescription'))
      return
    }
    if (
      newRule.veilgroepRegelNummer !==
        oldAuctionGroupRule.veilgroepRegelNummer &&
      (
        await getAllAuctionGroupRulesAsync(apiClient, {
          count: false,
          filter: constructFilterRuleForKVP(
            'veilgroepRegelNummer',
            Number(newRule.veilgroepRegelNummer)
          ),
        })
      ).records.length
    ) {
      setInProgress(false)
      snackbarUtils.error(t('auctionRule.invalidRuleNumber'))
      return
    }
    setRule(newRule)

    let result: IVeilgroepRegel = null
    if (auctionGroupRule.veilgroepRegelId) {
      try {
        await updateAuctionGroupRuleAsync(
          apiClient,
          auctionGroupRule.veilgroepRegelId,
          newRule,
          user.sub
        )
      } catch (error: any) {
        snackbarUtils.error(`update rule error: ${String(error)}`)
      }
      setRule(newRule)
      setOldRule(newRule)
    } else {
      try {
        result = await addAuctionGroupRuleAsync(apiClient, newRule, user.sub)
      } catch (error: any) {
        snackbarUtils.error(`add rule error: ${String(error)}`)
      }
      setRule(result)
      setOldRule(result)
      if (result.veilgroepRegelId) {
        history.push(
          `/dashboard/auction-group-rules/${result.veilgroepRegelId}`
        )
      }
    }
    setInProgress(false)
  }, [
    inProgress,
    auctionGroupRule,
    auctionPeriod,
    user.sub,
    oldAuctionGroupRule.veilgroepRegelNummer,
    apiClient,
    setRule,
    t,
    setOldRule,
    history,
  ])

  const fetchData = useCallback(
    async (client: IClient, veilgroepRegelId: number): Promise<void> => {
      if (inProgress || !veilgroepRegelId) {
        return Promise.resolve()
      }
      try {
        const fetchedRule = await getAuctionGroupRuleByIdAsync(client, {
          key: veilgroepRegelId,
        })
        setRule(fetchedRule)
        setOldRule(fetchedRule)
        setQuality(stringToQuality(fetchedRule.kwaliteit))
        setLoadCarriers(stringToLoadCarierTypes(fetchedRule.ladingdragerTypen))
        setSupplier(stringToSupplierTypes(fetchedRule.aanvoerTypen))
        setAuctionPeriod(extractAuctionPeriod(fetchedRule))
      } catch (error: any) {
        snackbarUtils.error(String(error))
      }
    },
    [
      inProgress,
      setRule,
      setOldRule,
      setQuality,
      setLoadCarriers,
      setSupplier,
      setAuctionPeriod,
    ]
  )

  const ensureCharacteristics = useCallback(
    async (client: IClient, veilgroepRegelId: number) => {
      if (inProgress || !veilgroepRegelId) {
        return Promise.resolve()
      }

      try {
        // eslint-disable-next-line testing-library/no-await-sync-query
        const fetchedCharacteristics = await getByAuctionGroupRuleId(client, {
          key: veilgroepRegelId,
        })
        if (fetchedCharacteristics.records.length > 0) {
          setRuleCharacteristics(fetchedCharacteristics.records)
        }
      } catch (error: any) {
        snackbarUtils.error(String(error))
      }
    },
    [inProgress, setRuleCharacteristics]
  )

  const goBack = useCallback(() => {
    resetAuctionGroupRuleStoreState()
    setMustRefetchAuctionGroupRules(true)
    history.push('/dashboard/auction-group-rules')
  }, [
    history,
    resetAuctionGroupRuleStoreState,
    setMustRefetchAuctionGroupRules,
  ])

  const displayBoolean = (input: boolean | undefined | null) => {
    if (input === undefined || input === null) return ''
    return input ? '1' : '0'
  }

  const fetchAuctionRuleNumber = useCallback(
    async (client: IClient) => {
      if (inProgress) {
        return Promise.resolve()
      }
      try {
        setInProgress(true)
        const latestRuleNumber = await getAllAuctionGroupRulesAsync(client, {
          top: 1,
          skip: 0,
          count: false,
          orderby: getOrderBy([
            {
              field: 'veilgroepRegelNummer',
              sort: 'desc',
            },
          ]),
        })
        if (latestRuleNumber.records.length) {
          setRule({
            veilgroepRegelNummer:
              latestRuleNumber.records[0].veilgroepRegelNummer + 1,
          })
        } else {
          setRule({
            veilgroepRegelNummer: 1,
          })
        }
      } catch (error: any) {
        snackbarUtils.error(String(error))
      } finally {
        setInProgress(false)
      }
    },
    [inProgress, setRule]
  )

  useEffect(() => {
    if (mustRefetchAuctionGroupRule && apiClient && id) {
      fetchData(apiClient, Number(id))
      ensureCharacteristics(apiClient, Number(id))
      setMustRefetchRule(false)
    } else if (apiClient && !id) {
      fetchAuctionRuleNumber(apiClient)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, mustRefetchAuctionGroupRule, id, setMustRefetchRule])

  return (
    <CharacteristicsProvider>
      <Container maxWidth='xl'>
        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            margin: '0',
          }}
        >
          <RfhTypography variant='h3' style={{ fontWeight: 'bold' }}>
            {t('auctionRule.auctionRuleTitle')}
          </RfhTypography>
        </Grid>
        <Divider {...{ marginTop: 16 }} />
        <Box
          component='form'
          sx={{
            width: '100%',
            marginTop: GRID_MARGIN_TOP,
          }}
          onSubmit={addRule}
        >
          <FormGroup
            sx={{
              marginTop: ThemeConfig.spacing.xs,
            }}
          >
            <FormInput
              label={t('auctionRule.rulenumber')}
              id='id-veilgroepRegelNummer'
              input={
                <TextField
                  type='number'
                  name='veilgroepRegelNummer'
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={handleChange}
                  value={auctionGroupRule?.veilgroepRegelNummer ?? ''}
                  sx={{
                    bgcolor: RfhColors.white,
                  }}
                />
              }
            />
            <FormInput
              label={t('auctionRule.ruleDescription')}
              id='id-veilgroepRegelOmschrijving'
              input={
                <TextField
                  sx={{
                    bgcolor: RfhColors.white,
                  }}
                  name='veilgroepRegelOmschrijving'
                  value={auctionGroupRule?.veilgroepRegelOmschrijving ?? ''}
                  onChange={handleChange}
                />
              }
            />
            <Grid
              container
              sx={{ margin: ThemeConfig.spacing.xs }}
              alignItems={'center'}
            >
              <Grid item xs={12} sm={4}>
                <FormLabel>{t('auctionRule.characteristics')}</FormLabel>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  sx={{
                    margin: 0,
                    width: '100%',
                  }}
                >
                  <TextareaAutosize
                    style={{ padding: ThemeConfig.spacing.m }}
                    minRows={5}
                    value={characteristicContent ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  sx={{ marginLeft: ThemeConfig.spacing.s }}
                  size='small'
                  variant='block--contained'
                  disabled={!auctionGroupRule?.veilgroepRegelId}
                  onClick={openCharacteristicDialog}
                >
                  {t('auctionRule.change')}
                </Button>
              </Grid>
            </Grid>

            <FormInput
              label={t('auctionRule.quality')}
              id='id-kwaliteit'
              input={
                <FormGroup
                  sx={{
                    display: 'flex',
                    flexFlow: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <FormControlLabel
                    label='A1'
                    control={
                      <Checkbox
                        onChange={handleQualityChange}
                        checked={quality.A1}
                        name='A1'
                      />
                    }
                  />
                  <FormControlLabel
                    label='A2'
                    control={
                      <Checkbox
                        onChange={handleQualityChange}
                        checked={quality.A2}
                        name='A2'
                      />
                    }
                  />
                  <FormControlLabel
                    label='B1'
                    control={
                      <Checkbox
                        onChange={handleQualityChange}
                        checked={quality.B1}
                        name='B1'
                      />
                    }
                  />
                </FormGroup>
              }
            />
            <FormInput
              label={t('auctionRule.supplyTypes')}
              id='id-aanvoertypen'
              input={
                <FormGroup
                  sx={{
                    display: 'flex',
                    flexFlow: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <FormControlLabel
                    label='MP'
                    control={
                      <Checkbox
                        onChange={handleSupplyTypesChange}
                        checked={supplier.MP}
                        name='MP'
                      />
                    }
                  />
                  <FormControlLabel
                    label='1P'
                    control={
                      <Checkbox
                        onChange={handleSupplyTypesChange}
                        checked={supplier.oneP}
                        name='oneP'
                      />
                    }
                  />
                  <FormControlLabel
                    label='GP'
                    control={
                      <Checkbox
                        onChange={handleSupplyTypesChange}
                        checked={supplier.GP}
                        name='GP'
                      />
                    }
                  />
                  <FormControlLabel
                    label='GP+'
                    control={
                      <Checkbox
                        onChange={handleSupplyTypesChange}
                        checked={supplier.GPPlus || supplier.GP}
                        disabled={supplier.GP}
                        name='GPPlus'
                      />
                    }
                  />
                </FormGroup>
              }
            />
            <FormInput
              label={t('auctionRule.loadCarrierTypes')}
              id='id-ladingdragertypen'
              input={
                <FormGroup
                  sx={{
                    display: 'flex',
                    flexFlow: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <FormControlLabel
                    label='STW'
                    control={
                      <Checkbox
                        onChange={handleloadCarrierTypesChange}
                        checked={loadCarriers.STW}
                        name='STW'
                      />
                    }
                  />
                  <FormControlLabel
                    label='DC'
                    control={
                      <Checkbox
                        onChange={handleloadCarrierTypesChange}
                        checked={loadCarriers.DC}
                        name='DC'
                      />
                    }
                  />
                </FormGroup>
              }
            />
            <Grid
              container
              sx={{ margin: ThemeConfig.spacing.xs }}
              alignItems={'center'}
            >
              <Grid item xs={12} sm={4}>
                <FormLabel>{t('auctionRule.auctionperiod')}</FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <AuctionPeriod />
              </Grid>
            </Grid>
            <FormInput
              label={t('auctionRule.linkedPicture')}
              id='id-gekoppeldePartijFoto'
              input={
                <NativeSelect
                  name='gekoppeldePartijFoto'
                  onChange={handleBooleanChange}
                  value={displayBoolean(auctionGroupRule?.gekoppeldePartijFoto)}
                  sx={{
                    paddingLeft: ThemeConfig.spacing.s,
                    backgroundColor: RfhColors.white,
                  }}
                >
                  {getBooleanOptions()}
                </NativeSelect>
              }
            />

            <FormInput
              label={t('auctionRule.regularity')}
              id='id-aanvoerderRegelmatig'
              input={
                <NativeSelect
                  name='aanvoerderRegelmatig'
                  onChange={handleBooleanChange}
                  value={displayBoolean(auctionGroupRule?.aanvoerderRegelmatig)}
                  sx={{
                    paddingLeft: ThemeConfig.spacing.s,
                    backgroundColor: RfhColors.white,
                  }}
                >
                  {getBooleanOptions()}
                </NativeSelect>
              }
            />
            <FormInput
              label={t('auctionRule.irregularity')}
              id='id-aanvoerderOnregelmatig'
              input={
                <NativeSelect
                  name='aanvoerderOnregelmatig'
                  onChange={handleBooleanChange}
                  value={displayBoolean(
                    auctionGroupRule?.aanvoerderOnregelmatig
                  )}
                  sx={{
                    paddingLeft: ThemeConfig.spacing.s,
                    backgroundColor: RfhColors.white,
                  }}
                >
                  {getBooleanOptions()}
                </NativeSelect>
              }
            />
            <FormInput
              label={t('auctionRule.noAuctionService')}
              id='id-geenDienstVeilen'
              input={
                <NativeSelect
                  name='geenDienstVeilen'
                  onChange={handleBooleanChange}
                  value={displayBoolean(auctionGroupRule?.geenDienstVeilen)}
                  sx={{
                    paddingLeft: ThemeConfig.spacing.s,
                    backgroundColor: RfhColors.white,
                  }}
                >
                  {getBooleanOptions()}
                </NativeSelect>
              }
            />
            <Grid container sx={{ margin: ThemeConfig.spacing.xs }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: ThemeConfig.spacing.s,
                  marginBottom: ThemeConfig.spacing.sm,
                }}
              >
                <Button
                  variant='block--outlined'
                  sx={{
                    marginRight: ThemeConfig.spacing.s,
                  }}
                  onClick={goBack}
                >
                  {t('common.back')}
                </Button>
                <Button
                  size='small'
                  variant='block--contained'
                  isLoading={inProgress}
                  onClick={addRule}
                >
                  {auctionGroupRule.veilgroepRegelId
                    ? t('common.save')
                    : t('common.add')}
                </Button>
              </Grid>
            </Grid>
          </FormGroup>
        </Box>
        <AddRuleCharacteristic
          open={open}
          onClose={closeCharacteristicDialog}
        />
      </Container>
    </CharacteristicsProvider>
  )
}
