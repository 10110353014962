import { FC, memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { DeferredLoad } from 'src/common/components'
import { IProductclusterVeilgroepRegelCode } from 'src/common/services/client'
import { Item } from 'src/common/types'

import {
  HEADER_CELL_STYLE_LEFT,
  HEADER_CELL_STYLE_RIGHT,
} from '../../constants'
import { useReferenceData } from '../../hooks'
import { bySelected } from '../../lib'
import { useRuleStore } from '../../stores'
import { CodeSoort } from '../../types'
import MultiSelectItem from './MultiSelectItem'

type DeferredTableProps = {
  codeSoort: CodeSoort
}

const DeferredTable: FC<Readonly<DeferredTableProps>> = memo(
  ({ codeSoort }) => {
    const { t } = useTranslation()
    const noResults = t('common.noResults')
    const { mergeSelected } = useReferenceData()
    const { rule, getRuleCodes, upsertRuleCodeToUpdate } = useRuleStore()
    const [items, setItems] = useState<Item<number>[]>([])
    const [merge, setMerge] = useState<boolean>(true)

    const mapItemToIProductclusterVeilgroepRegelCode = useCallback(
      (itm: Item<number>): IProductclusterVeilgroepRegelCode => ({
        code: itm.code,
        codeSoort,
        productclusterVeilgroepRegelId: rule.productclusterVeilgroepRegelId,
        geselecteerd: itm.selected,
      }),
      [codeSoort, rule.productclusterVeilgroepRegelId]
    )

    const changeChecked = useCallback(
      function (item: Item<number>) {
        const index = items.findIndex(i => i.code === item.code)
        const updatedItems = [
          ...items.slice(0, index),
          item,
          ...items.slice(index + 1),
        ]
        updatedItems.sort(bySelected)
        setItems(updatedItems)
        upsertRuleCodeToUpdate(mapItemToIProductclusterVeilgroepRegelCode(item))
        setMerge(true)
      },
      [
        items,
        mapItemToIProductclusterVeilgroepRegelCode,
        upsertRuleCodeToUpdate,
      ]
    )

    useEffect(() => {
      setItems(getRuleCodes(codeSoort))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (merge) {
        mergeSelected(codeSoort) //! recalculate the text
        setMerge(false)
      }
    }, [codeSoort, merge, mergeSelected])

    return (
      <Table
        sx={{
          minHeight: 100,
          marginTop: '6em',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={HEADER_CELL_STYLE_LEFT}>
              {t('common.code')}
            </TableCell>
            <TableCell sx={HEADER_CELL_STYLE_LEFT}>
              {t('common.name')}
            </TableCell>
            <TableCell sx={HEADER_CELL_STYLE_RIGHT}>
              {t('common.selected')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>{noResults}</TableCell>
            </TableRow>
          )}
          {items.length > 0 && (
            <DeferredLoad chunkSize={100}>
              {items.map(regelCode => (
                <MultiSelectItem
                  changeChecked={changeChecked}
                  item={regelCode}
                  key={regelCode.code}
                />
              ))}
            </DeferredLoad>
          )}
        </TableBody>
      </Table>
    )
  }
)

export default DeferredTable
