import { useCallback, useMemo } from 'react'

import { usePathname } from 'src/common/hooks'
import type { IVeilgroepIndelingRegelView } from 'src/common/services/client'
import {
  ProductclusterVeilgroepRegelCode,
  VeilgroepIndelingRegelWithCodesView,
} from 'src/common/services/client'
import type { IRegelCodesViewsBodyData } from 'src/common/services/clientExtra'
import type { PropValueType } from 'src/common/types'
import { SnackbarMessage, snackbarUtils } from 'src/common/utils'

import { FEATURE_ROOT_PATH, initialRule, validationErrors } from '../constants'
import { useAllocationStore, useRuleStore } from '../stores'

import { startOfTomorrow } from 'date-fns'

type ReturnType = {
  getBodyData: () => IRegelCodesViewsBodyData
  isDataDirty: boolean
  isFormValid: (showErrors?: boolean) => boolean
  newRule: IVeilgroepIndelingRegelView
  //! interface must include at least one property of type `number` otherwise add it!!!
  ruleDeps: PropValueType<IVeilgroepIndelingRegelView>[]
}
export default function useMutation(): ReturnType {
  const { oldRule, rule, ruleCodesToUpdate } = useRuleStore()
  const { allocation, getNewOrderNumber } = useAllocationStore()
  const { ADDING } = usePathname(FEATURE_ROOT_PATH)
  const ruleDeps: PropValueType<IVeilgroepIndelingRegelView>[] = useMemo(() => {
    let propValues: PropValueType<IVeilgroepIndelingRegelView>[] = []
    if (rule) {
      propValues = Object.values(rule)
    }
    return [propValues, ruleCodesToUpdate?.length]
  }, [rule, ruleCodesToUpdate?.length])

  const getBodyData = useCallback(
    (): IRegelCodesViewsBodyData => ({
      regelCodeView: new VeilgroepIndelingRegelWithCodesView({
        productclusterVeilgroepRegelId: rule.productclusterVeilgroepRegelId,
        productclusterID: rule.productclusterID,
        vestigingCode: rule.vestigingCode,
        veilgroepCode: rule.veilgroepCode,
        veilgroepCodeAanvoerder: rule.veilgroepCodeAanvoerder,
        veilgroepRegelId: rule.veilgroepRegelId,
        volgordeNummer: rule.volgordeNummer,
        ingangsDatum: rule.ingangsDatum,
        eindDatum: rule.eindDatum,
        productclusterVeilgroepRegelCodes: ruleCodesToUpdate.map(
          regelCode => new ProductclusterVeilgroepRegelCode(regelCode)
        ),
      }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...ruleDeps]
  )

  const isAuctionGroupValid = () =>
    !rule?.veilgroepCode ||
    Number(rule.veilgroepCode) <= 0 ||
    rule.veilgroepOmschrijving === validationErrors.invalidAuctionGroup

  const isAuctionGroupSupplierValid = () =>
    (rule?.veilgroepCodeAanvoerder &&
      Number(rule?.veilgroepCodeAanvoerder) <= 0) ||
    rule.veilgroepOmschrijvingAanvoerder ===
      validationErrors.invalidAuctionGroupSupplier

  const isAuctionGroupRuleValid = () =>
    (rule?.veilgroepRegelNumber && Number(rule.veilgroepRegelNumber) <= 0) ||
    rule.veilgroepRegelOmschrijving === validationErrors.invalidAuctionGroupRule

  const isDataDirty = useMemo((): boolean => {
    const ruleUpdated = JSON.stringify(rule) !== JSON.stringify(oldRule)
    const codesUpdated = ruleCodesToUpdate?.length > 0
    return ruleUpdated || codesUpdated
  }, [oldRule, rule, ruleCodesToUpdate?.length])

  const isFormValid = useCallback(
    function (showErrors = false) {
      let result = true
      const errors: string[] = []
      if (isAuctionGroupValid()) {
        result = false
        errors.push(validationErrors.invalidAuctionGroup)
      }
      if (
        ADDING &&
        (!rule.ingangsDatum || rule.ingangsDatum < startOfTomorrow())
      ) {
        result = false
        errors.push(validationErrors.startdateNotInFuture)
      }
      if (!rule.eindDatum || rule.eindDatum < startOfTomorrow()) {
        result = false
        errors.push(validationErrors.enddateNotInFuture)
      }
      if (rule.ingangsDatum > rule.eindDatum) {
        result = false
        errors.push(validationErrors.enddateBeforeStartdate)
      }
      if (isAuctionGroupRuleValid()) {
        result = false
        errors.push(validationErrors.invalidAuctionGroupRule)
      }
      if (isAuctionGroupSupplierValid()) {
        result = false
        errors.push(validationErrors.invalidAuctionGroupSupplier)
      }
      /* FINALLY */
      if (!result && showErrors) {
        snackbarUtils.error(SnackbarMessage(errors, 'Invoer niet correct'))
      }
      return result
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...ruleDeps]
  )

  const newRule = useMemo(() => {
    if (allocation?.productclusterID > 0) {
      const result = initialRule
      /**
       * get productclusterID and max volgordeNummer from allocation
       */
      result.productclusterID = allocation.productclusterID
      result.vestigingCode = allocation.vestigingCode
      result.volgordeNummer = getNewOrderNumber()
      return result
    }
  }, [
    allocation?.productclusterID,
    allocation?.vestigingCode,
    getNewOrderNumber,
  ])

  return {
    getBodyData,
    isDataDirty,
    isFormValid,
    newRule,
    ruleDeps,
  }
}
