import { ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import ClearIcon from '@mui/icons-material/Clear'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import NativeSelect from '@mui/material/NativeSelect'
import { TextField } from '@rfh/ui'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { ThemeConfig } from 'src/common/config'

import { useAuctionGroupRuleStore } from '../stores'
import { WeekDay } from '../types'

export default function AuctionPeriod(): JSX.Element {
  const { t } = useTranslation()
  const { auctionPeriod, setAuctionPeriod } = useAuctionGroupRuleStore()

  const getdays = useCallback(() => {
    const days: JSX.Element[] = [
      <option key='' value={undefined}>
        {''}
      </option>,
    ]
    const dayData: JSX.Element[] = Object.keys(WeekDay)
      .filter(item => !Number(item))
      .map(day => (
        <option key={day} value={WeekDay[day as keyof typeof WeekDay]}>
          {t<any>(`auctionRule.${day.toLowerCase()}`)}
        </option>
      ))
    return <>{[days, ...dayData]}</>
  }, [t])

  const handleChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const { name, value } = event.target
      setAuctionPeriod({
        ...auctionPeriod,
        [name]: Number(value) && Number(value) > 0 ? Number(value) : null,
      })
    },
    [setAuctionPeriod, auctionPeriod]
  )

  const handleClear = (name: string) => {
    setAuctionPeriod({
      ...auctionPeriod,
      [name]: null,
    })
  }

  return (
    <Grid
      item
      sx={{
        marginLeft: ThemeConfig.spacing.sm * 0.5,
        width: '100%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
      }}
    >
      <FormControl
        sx={{
          margin: 0,
          width: '100%',
        }}
      >
        <FormControlLabel
          control={
            <TextField
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              sx={{
                bgcolor: RfhColors.white,
                marginRight: ThemeConfig.spacing.xs,
              }}
              value={
                Number(auctionPeriod?.weekVanaf) && auctionPeriod.weekVanaf > 0
                  ? auctionPeriod.weekVanaf
                  : ''
              }
              name='weekVanaf'
              onChange={handleChange}
            />
          }
          label={t('auctionRule.weekFrom')}
        />
      </FormControl>
      <FormControl
        sx={{
          margin: 0,
          width: '100%',
        }}
      >
        <FormControlLabel
          control={
            <NativeSelect
              name='dagVanaf'
              value={auctionPeriod?.dagVanaf ?? ''}
              onChange={handleChange}
              sx={{
                width: '100%',
                bgcolor: RfhColors.white,
                marginRight: ThemeConfig.spacing.xs,
                '& .MuiNativeSelect-iconOutlined': {
                  display: auctionPeriod?.dagVanaf ? 'none' : '',
                },
              }}
              endAdornment={
                <IconButton
                  sx={{
                    display: auctionPeriod?.dagVanaf ? '' : 'none',
                    '&.Mui-focused .MuiIconButton-root': {
                      color: 'primary.main',
                    },
                  }}
                  onClick={() => handleClear('dagVanaf')}
                >
                  <ClearIcon />
                </IconButton>
              }
            >
              {getdays()}
            </NativeSelect>
          }
          label={t('auctionRule.dayFrom')}
        />
      </FormControl>
      <FormControl
        sx={{
          margin: 0,
          width: '100%',
        }}
      >
        <FormControlLabel
          control={
            <TextField
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              sx={{
                bgcolor: RfhColors.white,
                marginRight: ThemeConfig.spacing.xs,
              }}
              name='weekTtm'
              value={
                auctionPeriod?.weekTtm !== 0 &&
                auctionPeriod?.weekTtm !== undefined &&
                auctionPeriod.weekTtm !== null &&
                !isNaN(auctionPeriod.weekTtm)
                  ? `${auctionPeriod.weekTtm}`
                  : ''
              }
              onChange={handleChange}
            />
          }
          label={t('auctionRule.weekTill')}
        />
      </FormControl>
      <FormControl
        sx={{
          margin: 0,
          width: '100%',
        }}
      >
        <FormControlLabel
          control={
            <NativeSelect
              sx={{
                width: '100%',
                bgcolor: RfhColors.white,
                marginRight: ThemeConfig.spacing.xs,
                '& .MuiNativeSelect-iconOutlined': {
                  display: auctionPeriod?.dagTtm ? 'none' : '',
                },
              }}
              name='dagTtm'
              value={auctionPeriod?.dagTtm ?? ''}
              onChange={handleChange}
              endAdornment={
                <IconButton
                  sx={{
                    display: auctionPeriod?.dagTtm ? '' : 'none',
                    '&.Mui-focused .MuiIconButton-root': {
                      color: 'primary.main',
                    },
                  }}
                  onClick={() => handleClear('dagTtm')}
                >
                  <ClearIcon />
                </IconButton>
              }
            >
              {getdays()}
            </NativeSelect>
          }
          label={t('auctionRule.dayTill')}
        />
      </FormControl>
    </Grid>
  )
}
