import type { Item } from 'src/common/types'

import type {
  IAanvoerder,
  IAanvoerderProductRegelmatigUitzonderingView,
  IAanvoerderProductRegelmatigView,
  IAanvoerderProductgroepRegelmatigUitzonderingView,
  IAanvoerderProductgroepRegelmatigView,
  IProduct,
  IProductVeilgroep,
  IProductgroep,
  IVeilgroepRegel,
} from '../services/client'

export const toProductGroupRegularityException = (
  rec?: IAanvoerderProductgroepRegelmatigUitzonderingView
): IAanvoerderProductgroepRegelmatigUitzonderingView => {
  if (!rec) {
    return null
  }

  const upg: IAanvoerderProductgroepRegelmatigUitzonderingView = {
    ...rec,
    aanmaakDatumTijd: rec.aanmaakDatumTijd ? rec.aanmaakDatumTijd : null,
    mutatieDatumTijd: rec.mutatieDatumTijd ? rec.mutatieDatumTijd : null,
  }
  return upg
}

export const toProductGroupRegularity = (
  rec?: IAanvoerderProductgroepRegelmatigView
): IAanvoerderProductgroepRegelmatigView => {
  if (!rec) {
    return null
  }

  const apgr: IAanvoerderProductgroepRegelmatigView = {
    ...rec,
    aanmaakDatumTijd: rec.aanmaakDatumTijd ? rec.aanmaakDatumTijd : null,
    mutatieDatumTijd: rec.mutatieDatumTijd ? rec.mutatieDatumTijd : null,
  }

  return apgr
}

export const toProductRegularityException = (
  rec?: IAanvoerderProductRegelmatigUitzonderingView
): IAanvoerderProductRegelmatigUitzonderingView => {
  if (!rec) {
    return null
  }

  const up: IAanvoerderProductRegelmatigUitzonderingView = {
    ...rec,
    aanmaakDatumTijd: rec.aanmaakDatumTijd ? rec.aanmaakDatumTijd : null,
    mutatieDatumTijd: rec.mutatieDatumTijd ? rec.mutatieDatumTijd : null,
  }

  return up
}

export const toProductRegularity = (
  rec?: IAanvoerderProductRegelmatigView
): IAanvoerderProductRegelmatigView => {
  if (!rec) {
    return null
  }

  const apr: IAanvoerderProductRegelmatigView = {
    ...rec,
    aanmaakDatumTijd: rec.aanmaakDatumTijd ? rec.aanmaakDatumTijd : null,
    mutatieDatumTijd: rec.mutatieDatumTijd ? rec.mutatieDatumTijd : null,
  }

  return apr
}

export const fromIProductVeilgroepToItem = (
  pvg: IProductVeilgroep
): Item<number> => ({
  code: pvg.veilgroepCode,
  name: pvg.veilgroepOmschrijving,
})

export const fromIVeilgroepRegelToItem = (
  vgr: IVeilgroepRegel
): Item<number> => ({
  code: vgr.veilgroepRegelNummer,
  name: vgr.veilgroepRegelOmschrijving,
  id: vgr.veilgroepRegelId,
})

export const fromAanvoerderToItem = (avr: IAanvoerder): Item<number> => ({
  code: avr.aanvoerderNummer,
  name: avr.aanvoerderNaam,
})

export const fromProductToItem = (prd: IProduct): Item<number> => ({
  code: prd.productCode,
  name: prd.productNaam,
  expired: prd?.isVervallen ?? false,
})

export const fromProductGroupToItem = (
  prdgrp: IProductgroep
): Item<number> => ({
  code: prdgrp.productgroepCode,
  name: prdgrp.productgroepNaam,
})
