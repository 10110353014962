import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import RfhDialog, {
  RfhDialogVariant,
} from '@rfh/ui/components/Dialogs/RfhDialog'

import { noOp } from 'src/common/lib'

type ActionModalProps = {
  onCancel?: () => void
  onConfirm?: () => void
  open?: boolean
  translationsKey: string
  customDialogElement?: ReactElement
}

export default function ActionModal({
  onCancel = noOp,
  onConfirm = noOp,
  open = false,
  translationsKey,
  customDialogElement = null,
}: Readonly<ActionModalProps>): JSX.Element {
  const { t } = useTranslation()

  if (customDialogElement) return customDialogElement

  return (
    <RfhDialog
      isOpen={open}
      title={t(`${translationsKey}.title` as any)}
      content={t(`${translationsKey}.description` as any)}
      confirmButtonText={t('common.yes')}
      cancelButtonText={t('common.no')}
      onCancel={onCancel}
      onConfirm={onConfirm}
      variant={RfhDialogVariant.Primair}
    />
  )
}
