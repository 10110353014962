import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useState,
} from 'react'

import { FormControl, FormLabel, SxProps } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@rfh/ui/components/Inputs/TextField'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { getStringValue } from 'src/common/lib'

type LabelInputProps = {
  disabled?: boolean
  fieldName?: string
  inputValue?: string | number
  label?: string
  onSave?: (newInput: string) => void
  sx?: SxProps
}

export default function LabelInput({
  onSave,
  inputValue,
  label,
  disabled,
  fieldName,
  sx,
}: Readonly<LabelInputProps>): JSX.Element {
  const [value, setValue] = useState<string>('')
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setValue(event.target.value)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onSave(event.target?.value ?? '')
  }

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSave(value)
    }
  }

  useEffect(() => {
    setValue(getStringValue(inputValue))
  }, [inputValue])
  return (
    <Grid
      container
      item
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      sx={sx}
    >
      <Grid item>
        <FormControl fullWidth>
          <FormLabel
            htmlFor={`id-${fieldName}`}
            sx={{
              color: RfhColors.black,
              whiteSpace: 'nowrap',
              minWidth: 170,
            }}
          >
            {label}
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <TextField
            id={`id-${fieldName}`}
            name={fieldName}
            value={value}
            disabled={disabled}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            onChange={handleChange}
            sx={{ flex: 1, maxWidth: 160 }}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}
