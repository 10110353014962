// eslint-disable-next-line
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Grid } from '@mui/material'
import { CardHorizontal, RfhTypography } from '@rfh/ui'
import GridViewIcon from '@rfh/ui/shared/floriday-icons/icons/GridViewIcon'
import LogoutIcon from '@rfh/ui/shared/floriday-icons/icons/LogoutIcon'
import UserIcon from '@rfh/ui/shared/floriday-icons/icons/UserIcon'

import { MenuCard } from 'src/common/components'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useAuthentication } from 'src/common/providers/AuthenticationProvider'
import { useUser } from 'src/common/providers/UserProvider'
import {
  getSupplierProductGroupRegularityExceptions,
  getSupplierProductRegularityExceptions,
} from 'src/common/services'
import { snackbarUtils } from 'src/common/utils'
import { useDevToolsStore } from 'src/dev-tools'
import { FEATURES, featuresList } from 'src/dev-tools/config'

export default function HomePage(): JSX.Element {
  /*
   * Hooks
   */
  const { logout } = useAuthentication()
  const { t } = useTranslation()
  const user = useUser()
  const { apiClient } = useApiClient()

  const [countExceptionsPerProduct, setCountExceptionsPerProduct] = useState(0)
  const [countExceptionsPerProductGroup, setCountExceptionsPerProductGroup] =
    useState(0)

  // Use feature toggle to show features only if enable in the devtools panel.
  const { features } = useDevToolsStore()

  /*
   * Methods
   */
  const fetchExceptionsPerProduct = useCallback(async () => {
    try {
      const { count } = await getSupplierProductRegularityExceptions(
        apiClient,
        {
          top: 1,
          skip: 0,
          count: true,
        }
      )

      setCountExceptionsPerProduct(count ?? 0)
    } catch (error: any) {
      snackbarUtils.error(String(error))
    }
  }, [apiClient, setCountExceptionsPerProduct])

  const fetchExceptionsPerProductGroup = useCallback(async () => {
    try {
      const { count } = await getSupplierProductGroupRegularityExceptions(
        apiClient,
        {
          top: 1,
          skip: 0,
          count: true,
        }
      )

      setCountExceptionsPerProductGroup(count ?? 0)
    } catch (error: any) {
      snackbarUtils.error(String(error))
    }
  }, [apiClient, setCountExceptionsPerProductGroup])

  useEffect(() => {
    if (apiClient) {
      fetchExceptionsPerProduct()
      fetchExceptionsPerProductGroup()
    }
  }, [apiClient, fetchExceptionsPerProduct, fetchExceptionsPerProductGroup])

  // Example of route enabled by a feature toggle
  // const { features } = useDevToolsStore()

  // <MenuCard
  //     icon={<GridViewIcon />}
  //     title='clocksStatus'
  //     subheader='clocksStatus'
  //     uri='clocks-status'
  //     visible={
  //       featuresList.includes(FEATURES.KLOKSTATUS) &&
  //       features?.[FEATURES.KLOKSTATUS]
  //     }
  // />

  return (
    <Container
      maxWidth='xl'
      sx={{
        padding: ThemeConfig.spacing.sm,
        minHeight: 'calc(100vh - 64px - 8px)',
      }}
    >
      {/* TITLE HERO CARD */}
      <CardHorizontal
        imgSrc={
          new Date().getDate() % 2 === 0
            ? '/assets/images/flower.png'
            : '/assets/images/leaves.png'
        }
        content={
          <>
            <RfhTypography variant='h5' data-testid='welcome'>
              {t('homePage.welcome')}
            </RfhTypography>
            <RfhTypography variant='h4' data-testid='welcomeName'>
              {user.name && ` ${user.name.split(' ')[0]}`}
            </RfhTypography>
          </>
        }
        height={100}
        alt='Welcome'
      />
      {/* MENU OPTIES */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RfhTypography variant={'subheader'}>
            {t('homePage.actions')}
          </RfhTypography>
        </Grid>
        {/* REGELMAAT OP PRODUCT*/}
        <MenuCard
          icon={<GridViewIcon />}
          title={'overviewsProductRegularityReadOnly'}
          subheader={'overviewsProductRegularity'}
          uri={'supplier-product-regularity'}
        />
        {/* REGELMAAT OP PRODUCTGROEP*/}
        <MenuCard
          icon={<GridViewIcon />}
          title={'overviewsProductGroupRegularityReadOnly'}
          subheader={'overviewsProductGroupRegularity'}
          uri={'supplier-product-group-regularity'}
        />
        {/* PRODUCT UITZONDERINGSPERIODEN */}
        <MenuCard
          icon={<GridViewIcon />}
          title={
            user.isAuctionCoordinator
              ? 'exceptionsProduct'
              : 'exceptionsProductReadOnly'
          }
          subheader={
            user.isAuctionCoordinator
              ? 'exceptionsProduct'
              : 'exceptionsProductReadOnly'
          }
          uri={'exceptions-product'}
          bodyText={t('homePage.exceptions', {
            count: countExceptionsPerProduct,
          })}
        />
        {/* PRODUCTGROEP UITZONDERINGSPERIODEN */}
        <MenuCard
          icon={<GridViewIcon />}
          title={
            user.isAuctionCoordinator
              ? 'exceptionsProductGroup'
              : 'exceptionsProductGroupReadOnly'
          }
          subheader={
            user.isAuctionCoordinator
              ? 'exceptionsProductGroup'
              : 'exceptionsProductGroupReadOnly'
          }
          uri={'exceptions-product-group'}
          bodyText={t('homePage.exceptions', {
            count: countExceptionsPerProductGroup,
          })}
        />
        {/* VEILDAGTELLINGEN */}
        <MenuCard
          icon={<GridViewIcon />}
          title={'auctionDayCounts'}
          subheader={'auctionDayCounts'}
          uri={'auction-group-counts'}
        />
        {/* PARTIJENSTATUSSEN */}
        <MenuCard
          icon={<GridViewIcon />}
          title={'searchBatchesStatus'}
          subheader={'searchBatchesStatus'}
          uri={'batches-status'}
        />
        {/* VEILGROEPREGELS */}
        <MenuCard
          icon={<GridViewIcon />}
          title={
            user.isAuctionCoordinator
              ? 'manageAuctionGroupRules'
              : 'searchAuctionGroupRules'
          }
          subheader={
            user.isAuctionCoordinator
              ? 'manageAuctionGroupRules'
              : 'searchAuctionGroupRules'
          }
          uri={'auction-group-rules'}
        />
        {/* AANVOERDERCLUSTERS */}
        <MenuCard
          icon={<GridViewIcon />}
          title={'supplierClusters'}
          subheader={'supplierClusters'}
          uri={'supplier-clusters'}
        />
        {/* PRODUCTCLUSTERS */}
        <MenuCard
          icon={<GridViewIcon />}
          title={'productClusters'}
          subheader={'productClusters'}
          uri={'product-clusters'}
        />
        {/* VEILGROEPINDELINGEN */}
        <MenuCard
          icon={<GridViewIcon />}
          title={
            user.isAuctionCoordinator
              ? 'manageAuctionGroupAllocations'
              : 'auctionGroupAllocations'
          }
          subheader={
            user.isAuctionCoordinator
              ? 'manageAuctionGroupAllocations'
              : 'auctionGroupAllocations'
          }
          uri={'auction-group-allocations'}
        />
        {/* ONREGELMATIGE AANVOERDERS */}
        <MenuCard
          icon={<GridViewIcon />}
          title={'irregularSuppliers'}
          subheader={'irregularSuppliers'}
          uri={'irregular-suppliers'}
        />
        {/* PROMOTIEKENMERKEN */}
        <MenuCard
          icon={<GridViewIcon />}
          title={
            user.isAuctionCoordinator
              ? 'manageSupplierPromotionCharacteristics'
              : 'supplierPromotionCharacteristics'
          }
          subheader={
            user.isAuctionCoordinator
              ? 'manageSupplierPromotionCharacteristics'
              : 'supplierPromotionCharacteristics'
          }
          uri={'supplier-promotion-characteristics'}
        />
        {/* Zoek Product in Cluster/veilgroepregel */}
        <MenuCard
          icon={<GridViewIcon />}
          title='searchProduct'
          subheader='searchProduct'
          uri='search-product'
        />
        {/* Klokkenstatus*/}
        <MenuCard
          icon={<GridViewIcon />}
          title='clocksStatus'
          subheader='clocksStatus'
          uri='clocks-status'
          visible={
            featuresList.includes(FEATURES.KLOKSTATUS) &&
            features?.[FEATURES.KLOKSTATUS]
          }
        />
        {/* Logistiek dashboard*/}
        <MenuCard
          icon={<GridViewIcon />}
          title='logisticsDashboard'
          subheader='logisticsDashboard'
          uri='logistics-dashboard'
          visible={
            featuresList.includes(FEATURES.DASHBOARD) &&
            features?.[FEATURES.DASHBOARD]
          }
        />
        {/* BEHEER */}
        <Grid item xs={12}>
          <RfhTypography variant={'subheader'}>
            {t('homePage.manage')}
          </RfhTypography>
        </Grid>
        {/* MIJN ACCOUNT */}
        <MenuCard
          icon={<UserIcon />}
          title={'profilePage'}
          subheader={'profilePage'}
          uri={'profile'}
        />
        {/* UITLOGGEN */}
        <MenuCard
          icon={<LogoutIcon />}
          title={'logout'}
          subheader={'logout'}
          onClick={logout}
        />
      </Grid>
    </Container>
  )
}
