import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

export type StoreState = {
  features: Record<string, boolean>
}

type Actions = {
  /* store */
  resetDevToolsStoreState: () => void
  /* features */
  setFeatures: (fts: Record<string, boolean>, hard?: boolean) => void
}

const storeName = 'DevToolsStore'

const initialStoreState: StoreState = {
  features: {},
}

const useDevToolsStore = create<StoreState & Actions>()(
  devtools(
    persist(
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetDevToolsStoreState: () =>
          set(
            () => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetDevToolsStoreState'
          ),
        /* features */
        setFeatures: (fts: Record<string, boolean>, hard?: boolean) =>
          set(
            (state: StoreState) => ({
              features: hard ? fts : { ...state.features, ...fts },
            }),
            false,
            'setFeatures'
          ),
      }),
      {
        name: 'dev-tools-state',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)

export default useDevToolsStore
