import {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  ActionButton,
  BloemenPlantenSelect,
  FilterButtonsStack,
  FilterFieldsStack,
  FilterStack,
  TextInput,
} from 'src/common/components'
import { usePathname } from 'src/common/hooks'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import {
  IProductclusterView,
  Productcluster,
  ProductclusterProductgroep,
} from 'src/common/services/client'
import type { BloemenPlanten } from 'src/common/types'
import { snackbarUtils } from 'src/common/utils'

import { FEATURE_ROOT_PATH } from '../constants'
import { useProductClusters } from '../hooks'
import { buildEersteProductgroepenString, upsertCluster } from '../lib'
import { addClusterAsync, updateClusterAsync } from '../services'
import { useProductClusterStore, useProductClustersStore } from '../stores'

export const ProductGroupsFilter: FC = ({
  ...rest
}: Readonly<{ [x: string]: any }>): JSX.Element => {
  const history = useHistory()
  const { t } = useTranslation()
  const { apiClient } = useApiClient()
  const userId = useUser().sub ?? ''
  const { ADDING, urlParam } = usePathname(FEATURE_ROOT_PATH)
  const {
    cluster,
    productclusterProductgroepen,
    productgroepenHaveChanged,
    setCluster,
    setProductgroepen,
    setMustRefetchCluster,
    setOldCluster,
    updateCluster,
  } = useProductClusterStore()
  const {
    bloemenPlanten,
    clusters,
    setBloemenPlanten,
    setClusters,
    setMustRefetchClusters,
  } = useProductClustersStore()
  const { isDataDirty } = useProductClusters()

  const clusterFound = useMemo(
    () => clusters.find(c => c.productclusterID === urlParam[0]),
    [clusters, urlParam]
  )

  const handleGoBack = useCallback(() => {
    history.push(`/dashboard/${FEATURE_ROOT_PATH}`)
  }, [history])

  const isOmschrijvingFree = useCallback(
    (omschrijving: string) =>
      !clusters.some(
        cl =>
          cl.productclusterID !== cluster?.productclusterID &&
          cl.productclusterOmschrijving === omschrijving
      ),
    [cluster, clusters]
  )

  const handleChangeClusterDescription = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newOmschrijving = event.target.value
      updateCluster({
        productclusterOmschrijving: newOmschrijving,
      })
      if (!isOmschrijvingFree(newOmschrijving)) {
        snackbarUtils.error(t('productCluster.notFree'))
      }
    },
    [isOmschrijvingFree, t, updateCluster]
  )

  const handleChangeBloemenPlanten = (
    event: ChangeEvent<HTMLSelectElement>
  ): void => {
    const newBloemenPlanten = event.target.value as BloemenPlanten
    setBloemenPlanten(newBloemenPlanten)
    updateCluster({ bloemenPlantenCode: newBloemenPlanten })
  }

  const shouldSaveBeDisabled = useMemo(() => {
    const enabled =
      isOmschrijvingFree(cluster?.productclusterOmschrijving) &&
      productclusterProductgroepen?.length > 0 &&
      (isDataDirty || productgroepenHaveChanged)
    return !enabled
  }, [
    cluster?.productclusterOmschrijving,
    isDataDirty,
    isOmschrijvingFree,
    productclusterProductgroepen?.length,
    productgroepenHaveChanged,
  ])

  const setClustersOptimistically = useCallback(
    (updatedCluster: IProductclusterView) => {
      updatedCluster.eersteProductgroepen = buildEersteProductgroepenString(
        productclusterProductgroepen
      )
      setCluster(updatedCluster)
      setOldCluster(updatedCluster)
      setClusters(upsertCluster(clusters, updatedCluster))
    },
    [
      clusters,
      productclusterProductgroepen,
      setCluster,
      setClusters,
      setOldCluster,
    ]
  )

  const handleSaveCluster = useCallback(
    async (_event: MouseEvent<HTMLButtonElement>) => {
      if (!cluster) {
        snackbarUtils.error(t('supplierCluster.noCluster'))
        return Promise.resolve()
      }

      let messageBody: Productcluster
      const timestampNow = new Date()
      const clusterSeed: IProductclusterView = {
        ...cluster,
        bloemenPlantenCode: bloemenPlanten,
        aanmaakDatumTijd: new Date(cluster.aanmaakDatumTijd),
        mutatieDatumTijd: timestampNow,
        mutatieGebruiker: userId,
      }

      try {
        if (ADDING) {
          clusterSeed.aanmaakDatumTijd = timestampNow
          clusterSeed.aanmaakGebruiker = userId
          clusterSeed.productclusterProductgroepen = [
            ...productclusterProductgroepen.map(
              pg => new ProductclusterProductgroep(pg)
            ),
          ]
          messageBody = new Productcluster(clusterSeed)
          const addedProductcluster = await addClusterAsync(
            apiClient,
            messageBody
          )
          history.replace(
            `/dashboard/${FEATURE_ROOT_PATH}/${addedProductcluster.productclusterID}`
          )
          setClustersOptimistically(addedProductcluster)
        } /* UPDATING */ else {
          setClustersOptimistically(cluster)
          messageBody = new Productcluster(cluster)
          messageBody.productclusterProductgroepen = [
            ...productclusterProductgroepen.map(
              pg => new ProductclusterProductgroep(pg)
            ),
          ]
          await updateClusterAsync(apiClient, messageBody)
        }
      } catch (error: any) {
        snackbarUtils.error(String(error))
      } finally {
        setMustRefetchCluster(true)
        setMustRefetchClusters(true)
      }
    },
    [
      ADDING,
      apiClient,
      bloemenPlanten,
      cluster,
      history,
      productclusterProductgroepen,
      setClustersOptimistically,
      setMustRefetchCluster,
      setMustRefetchClusters,
      t,
      userId,
    ]
  )

  useEffect(() => {
    // initialize the store
    if (ADDING) {
      return
    }

    if (!clusterFound || clusterFound?.productclusterID === 0) {
      return
    }
    const productgroepen = clusterFound?.productclusterProductgroepen || []
    setProductgroepen(productgroepen || [])
    setCluster(clusterFound)
    setOldCluster(clusterFound)
  }, [ADDING, clusterFound, setCluster, setOldCluster, setProductgroepen])

  useEffect(() => {
    !ADDING && setMustRefetchCluster(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FilterStack {...rest}>
      <FilterFieldsStack>
        <TextInput
          ariaLabel={'supplier cluster description'}
          label={t('common.description')}
          name={'clusterDescription'}
          onBlur={handleChangeClusterDescription}
          onChange={handleChangeClusterDescription}
          required
          value={cluster?.productclusterOmschrijving ?? ''}
          sx={{ mt: 1.5 }}
        />
        <BloemenPlantenSelect
          bloemenPlanten={
            (cluster?.bloemenPlantenCode as BloemenPlanten) ?? bloemenPlanten
          }
          disabled={productclusterProductgroepen?.length > 0}
          onChange={handleChangeBloemenPlanten}
        />
      </FilterFieldsStack>
      <FilterButtonsStack>
        <ActionButton
          variant='block--outlined'
          type='submit'
          onClick={handleGoBack}
        >
          {t('common.back')}
        </ActionButton>
        <ActionButton
          type='submit'
          disabled={shouldSaveBeDisabled}
          onClick={handleSaveCluster}
        >
          {ADDING ? t('common.create') : t('common.save')}
        </ActionButton>
      </FilterButtonsStack>
    </FilterStack>
  )
}
