import { useCallback, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'

import { useStore } from 'zustand'

import { Divider } from 'src/common/components/Divider'
import { initialState, refreshReducer } from 'src/common/lib/lib.reducers'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { IClient } from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'

import IrregularSuppliersFilter from '../components/IrregularSuppliersFilter'
import IrregularSuppliersGrid from '../components/IrregularSuppliersGrid'
import { byAanvoerderVestigingSort } from '../lib'
import { getIrregularSuppliersAsync } from '../services/irregularSuppliersService'
import { useIrregularSuppliersStore } from '../stores'

export default function IrregularSuppliers(): JSX.Element {
  const { t } = useTranslation()
  const { apiClient } = useApiClient()
  const {
    mustRefetchIrregularSuppliers,
    setIrregularSuppliers,
    setMustRefetchIrregularSuppliers,
  } = useIrregularSuppliersStore()
  const resetProductClusterStoreState = useStore(
    useIrregularSuppliersStore,
    state => state.resetIrregularSuppliersStoreState
  )
  const [state, dispatch] = useReducer(refreshReducer, initialState)

  const fetchData = useCallback(
    async (client: IClient): Promise<void> => {
      if (!client || state.loading) {
        return Promise.resolve()
      }
      try {
        dispatch({ type: 'PENDING' })
        const suppliers = await getIrregularSuppliersAsync(client)
        suppliers.sort(byAanvoerderVestigingSort)
        setIrregularSuppliers(suppliers)
      } catch (error: any) {
        snackbarUtils.error(String(error))
      } finally {
        dispatch({ type: 'RESOLVED' })
      }
    },
    [dispatch, setIrregularSuppliers, state.loading]
  )

  useEffect(() => {
    if (apiClient && mustRefetchIrregularSuppliers) {
      fetchData(apiClient)
      setMustRefetchIrregularSuppliers(false)
    }
  }, [
    apiClient,
    fetchData,
    mustRefetchIrregularSuppliers,
    setMustRefetchIrregularSuppliers,
  ])

  useEffect(() => {
    resetProductClusterStoreState()
    setMustRefetchIrregularSuppliers(true)
  }, [resetProductClusterStoreState, setMustRefetchIrregularSuppliers])

  return (
    <Container maxWidth='xl'>
      <Grid item xs={12} mt={4} mb={2}>
        <Typography variant={'h3'} sx={{ fontWeight: 'bold' }}>
          {t('common.titles.irregularSuppliers')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <IrregularSuppliersFilter />
      </Grid>
      <Divider {...{ marginTop: 16 }} />
      <Grid item xs={12}>
        <IrregularSuppliersGrid loading={state.loading} />
      </Grid>
    </Container>
  )
}
