import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'
import { DataGrid, LoadingIcon, RfhTypography as Typography } from '@rfh/ui'
import { Row } from '@rfh/ui/components/DataGrid/DataGrid'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { BooleanTagCentered as BooleanTag } from 'src/common/components'
import { StyledGridCard } from 'src/common/components/StyledGridCard'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { IAanvoerderProductRegelmatigView } from 'src/common/services/client'
import { formatDate } from 'src/common/utils/DateFunctions'

import { layout } from '../lib'

import { v4 as getRandomID } from 'uuid'

type ProductRegularityHistoryGridProps = {
  history: IAanvoerderProductRegelmatigView[]
  isLoading: boolean
}

export default function ProductRegularityHistoryGrid({
  history,
  isLoading,
}: Readonly<ProductRegularityHistoryGridProps>): JSX.Element {
  const { t } = useTranslation()

  const noResultsFallback: Row[] = [
    {
      values: [t('common.noResults')],
    },
  ]

  const headerRow: Row = {
    values: [
      t('regularity.datumEindeWeek'),
      t('overviews.auctionDays'),
      t('overviews.auctionDaysWeek'),
      t('overviews.weekLimit'),
      t('overviews.regularity'),
      t('overviews.exceptionWeeks'),
      t('regularity.exceptionWeek'),
      t('regularity.uitzonderingRedenOmschrijving'),
      t('regularity.uitzonderingRedenTekst'),
    ],
    header: true,
  }

  const regularityToRow = (item: IAanvoerderProductRegelmatigView): Row => ({
    values: [
      formatDate(new Date(item.datumEindeWeek), t('common.dateFormatShort')),
      item.aantalDagenGeveild ?? 0,
      item.aantalDagenGeveildWeek ?? 0,
      item.aantalWekenLimiet ?? 0,
      <BooleanTag value={item.regelmatig} key={getRandomID()} />,
      item.aantalWekenUitzondering ?? 0,
      <BooleanTag
        value={item.uitzonderingRedenOmschrijving !== ''}
        key={getRandomID()}
      />,
      item.uitzonderingRedenOmschrijving,
      item.uitzonderingRedenTekst,
    ],
  })

  const rows =
    history.length > 0 ? [...history].map(regularityToRow) : noResultsFallback
  rows.unshift(headerRow)

  return (
    <Grid
      container
      sx={{
        marginLeft: 0,
        marginTop: ThemeConfig.spacing.sm,
        width: '100%',
        '& .MuiGrid-root.MuiGrid-item': {
          flexGrow: 1,
          maxWidth: '100%',
          paddingLeft: 0,
        },
      }}
      spacing={2}
    >
      <Grid
        item
        xs={12}
        sx={{
          padding: '0 !important',
          my: ThemeConfig.spacing.sm,
        }}
      >
        <Typography variant={'h3'} sx={{ fontWeight: 'bold' }}>
          {t('common.titles.regularityHistory')}
        </Typography>
      </Grid>
      {!isLoading ? (
        <StyledGridCard lg={10} xs={18} aligns={layout.map(l => l.align)}>
          <DataGrid
            headColor={RfhColors.leafGreen}
            label={t('common.titles.regularityHistory')}
            rows={rows}
            layout={layout.map(l => l.ratio)}
          />
        </StyledGridCard>
      ) : (
        <LoadingIcon />
      )}
    </Grid>
  )
}
