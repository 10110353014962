import { ChangeEvent, ChangeEventHandler, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, SxProps, Typography } from '@mui/material'
import NativeSelect from '@mui/material/NativeSelect'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { ELEMENT_HEIGHT } from 'src/common/constants'
import { noOp } from 'src/common/lib'
import type { LocationType } from 'src/common/types'

import LocationsList from './LocationsList'

type LocationSelectProps = {
  disabled?: boolean
  addCatchAll?: boolean
  locations?: LocationType[]
  locationCode?: number
  onChange: ChangeEventHandler<HTMLSelectElement>
  customLabel?: (props: { children: ReactNode }) => JSX.Element
  sx?: SxProps
}

export default function LocationSelect({
  disabled = false,
  addCatchAll = false,
  locations,
  locationCode: initialCode = 0,
  onChange = noOp,
  customLabel,
  sx,
}: Readonly<LocationSelectProps>): JSX.Element {
  const { t } = useTranslation()
  const [locationCode, setLocationCode] = useState<number>(initialCode)

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setLocationCode(+event.target.value)
    onChange(event)
  }

  const LabelComponent =
    customLabel ??
    ((props: { children: ReactNode }) => (
      <Typography {...props} variant='subtitle1' />
    ))

  return (
    <FormControl>
      <LabelComponent>{t('common.auctionLocation')}</LabelComponent>
      <NativeSelect
        aria-label={'location'}
        disabled={disabled}
        onChange={handleChange}
        value={locationCode}
        sx={{
          minWidth: 150,
          mt: 1.5,
          px: 1,
          width: 180,
          height: ELEMENT_HEIGHT,
          bgcolor: RfhColors.white,
          'svg.MuiNativeSelect-icon': {
            pointerEvents: 'none',
          },
          ...sx,
        }}
      >
        {addCatchAll && <option value={0}></option>}
        <LocationsList locations={locations} />
      </NativeSelect>
    </FormControl>
  )
}
