import { HttpCode } from 'src/common/constants'

import { IApiClientListFilter } from '../interfaces'
import { toProductRegularityException } from '../lib'
import { ListAndCount } from '../types'
import {
  AanvoerderProductRegelmatigUitzondering,
  IAanvoerderProductRegelmatigUitzondering,
  IAanvoerderProductRegelmatigUitzonderingView,
  IClient,
} from './client'

export const getSupplierProductRegularityExceptions = async (
  apiClient: IClient,
  apiClientFilter: Partial<IApiClientListFilter>
): Promise<ListAndCount<IAanvoerderProductRegelmatigUitzonderingView>> => {
  const safeFilter =
    !apiClientFilter?.filter || apiClientFilter.filter === ''
      ? undefined
      : apiClientFilter.filter

  const result =
    // eslint-disable-next-line max-len
    (await apiClient.aanvoerderProductRegelmatigUitzonderingenViews_AanvoerderProductRegelmatigUitzonderingView_ListAanvoerderProductRegelmatigUitzonderingView(
      apiClientFilter.top,
      apiClientFilter.skip,
      undefined,
      safeFilter,
      apiClientFilter.count,
      apiClientFilter.orderby,
      undefined,
      undefined
    )) as any

  const records: IAanvoerderProductRegelmatigUitzonderingView[] =
    process.env.REACT_APP_MOCK_API === 'true'
      ? result?.records?.map(toProductRegularityException)
      : result?.cleanedResponse?.value
  const count =
    process.env.REACT_APP_MOCK_API === 'true'
      ? result.count
      : result?.['@odata.count'] ?? 0

  return { records, count }
}

export const addSupplierProductRegularityException = async (
  apiClient: IClient,
  newEntity: AanvoerderProductRegelmatigUitzondering
): Promise<number> => {
  const result: any =
    // eslint-disable-next-line max-len
    await apiClient.aanvoerderProductRegelmatigUitzonderingen_AanvoerderProductRegelmatigUitzondering_CreateAanvoerderProductRegelmatigUitzondering(
      newEntity
    )

  const cleanedResult: IAanvoerderProductRegelmatigUitzondering =
    process.env.REACT_APP_MOCK_API === 'true' ? result : result?.cleanedResponse

  if (cleanedResult?.aanvoerderProductRegelmatigUitzonderingId) {
    return HttpCode.CREATED
  }
}
