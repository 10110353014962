import { ChangeEvent, useCallback } from 'react'

import type { DebouncedFunc } from 'lodash'
import debounce from 'lodash.debounce'

type DebouncedInputReturn = {
  debounced: (
    fn: (event: ChangeEvent<HTMLInputElement>) => void,
    delay?: number
  ) => DebouncedFunc<(event: ChangeEvent<HTMLInputElement>) => void>
}

const useDebouncedInput = (): DebouncedInputReturn => {
  const debounced = useCallback(
    (fn: (event: ChangeEvent<HTMLInputElement>) => void, delay = 1000) =>
      debounce(fn, delay),
    //! maybe not necessry to include debounce
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debounce]
  )

  return { debounced }
}

export default useDebouncedInput
