/* eslint-disable @typescript-eslint/unbound-method */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { Box } from '@mui/material'
import { RfhAppBar, RfhPrimaryMenuProps } from '@rfh/ui'
import {
  BackgroundFlowerIcon,
  GridViewIcon,
  LogoutIcon,
  UserIcon,
} from '@rfh/ui/shared/floriday-icons/icons'
import { FlagDE, FlagGB, FlagNL } from '@rfh/ui/shared/icons'

import { useUser } from 'src/common/providers/UserProvider'
import { useDevToolsStore } from 'src/dev-tools'
import { FEATURES } from 'src/dev-tools/config'
import {
  AppLanguageCode,
  fallbackLng,
  locales,
  supportedLanguages,
} from 'src/i18n'

import { useAuthentication } from '../providers/AuthenticationProvider'

export default function AppBar(): JSX.Element {
  const { i18n, t } = useTranslation('translations')
  const history = useHistory()
  const user = useUser()
  const { login, logout } = useAuthentication()
  const [menuOpen, setMenuOpen] = useState(false)
  const { features } = useDevToolsStore()

  /*
    Add menuItem only when feature toggle is available by using following example:
    const { features } = useDevToolsStore()
    features?.[FEATURES.ONREGELMATIGE_AANVOERDERS] && menu.items.push(..)
  */

  /** useMemo makes it possible to change the language for this applications
   * if starting it via an url route from another domain, e.g. RFH Portal
   */
  const location = useLocation()
  const urlPart = new URLSearchParams(location.search).get('language')
  const queryStringLanguage = urlPart
    ? urlPart.toLowerCase().slice(0, 2)
    : fallbackLng

  useEffect(() => {
    if (locales.map(l => l.toString()).includes(queryStringLanguage)) {
      i18n.changeLanguage(queryStringLanguage)
    } else if (urlPart && supportedLanguages.includes(urlPart)) {
      i18n.changeLanguage(urlPart)
    } else if (queryStringLanguage) {
      console.warn(
        `Could not detect language from url, queryStringLanguage: ${queryStringLanguage}`
      )
    }
  }, [queryStringLanguage, i18n, urlPart])

  const onClickRedirectHandler = (path: string): void => {
    setMenuOpen(false)
    history.push(path)
  }

  const getLanguageItem = (lang: AppLanguageCode) => {
    let flag
    switch (lang) {
      case AppLanguageCode.de:
        flag = <FlagDE />
        break
      case AppLanguageCode.en:
        flag = <FlagGB />
        break
      case AppLanguageCode.nl:
      default:
        flag = <FlagNL />
    }
    return {
      flagIcon: flag,
      languageName: {
        de: t('appBar.languageNameDE'),
        en: t('appBar.languageNameEN'),
        nl: t('appBar.languageNameNL'),
      },
      text: t('appBar.changeLanguage'),
    }
  }

  const getLanguages = {
    de: getLanguageItem(AppLanguageCode.de),
    en: getLanguageItem(AppLanguageCode.en),
    nl: getLanguageItem(AppLanguageCode.nl),
  }

  const getButtons = (): RfhPrimaryMenuProps => {
    const menu: RfhPrimaryMenuProps = { items: [] }
    menu.items.push({
      onClick: () => onClickRedirectHandler('/dashboard'),
      text: t('common.titles.homePage'),
      icon: <BackgroundFlowerIcon />,
    })

    if (!user.isAuthenticated) {
      menu.items.push({
        onClick: () => login(),
        text: t('common.titles.login'),
        isFooter: true,
        icon: <LogoutIcon />,
      })
      return menu
    }

    menu.items.push({
      onClick: () =>
        onClickRedirectHandler('/dashboard/supplier-product-regularity'),
      text: t('common.titles.overviewsProductRegularityReadOnly'),
      icon: <GridViewIcon />,
    })
    menu.items.push({
      onClick: () =>
        onClickRedirectHandler('/dashboard/supplier-product-group-regularity'),
      text: t('common.titles.overviewsProductGroupRegularityReadOnly'),
      icon: <GridViewIcon />,
    })
    menu.items.push({
      onClick: () => onClickRedirectHandler('/dashboard/exceptions-product'),
      text: user.isAuctionCoordinator
        ? t('common.titles.exceptionsProduct')
        : t('common.titles.exceptionsProductReadOnly'),
      icon: <GridViewIcon />,
    })
    menu.items.push({
      onClick: () =>
        onClickRedirectHandler('/dashboard/exceptions-product-group'),
      text: user.isAuctionCoordinator
        ? t('common.titles.exceptionsProductGroup')
        : t('common.titles.exceptionsProductGroupReadOnly'),
      icon: <GridViewIcon />,
    })
    menu.items.push({
      onClick: () => onClickRedirectHandler('/dashboard/auction-group-counts'),
      text: t('common.titles.auctionDayCounts'),
      icon: <GridViewIcon />,
    })
    menu.items.push({
      onClick: () => onClickRedirectHandler('/dashboard/batches-status'),
      text: t('common.titles.searchBatchesStatus'),
      icon: <GridViewIcon />,
    })
    menu.items.push({
      onClick: () => onClickRedirectHandler('/dashboard/auction-group-rules'),
      text: user.isAuctionCoordinator
        ? t('auctionRule.title.manage')
        : t('common.titles.searchAuctionGroupRules'),
      icon: <GridViewIcon />,
    })
    menu.items.push({
      onClick: () => onClickRedirectHandler('/dashboard/supplier-clusters'),
      text: t('common.titles.supplierClusters'),
      icon: <GridViewIcon />,
    })
    menu.items.push({
      onClick: () => onClickRedirectHandler('/dashboard/product-clusters'),
      text: t('common.titles.productClusters'),
      icon: <GridViewIcon />,
    })
    menu.items.push({
      onClick: () =>
        onClickRedirectHandler('/dashboard/auction-group-allocations'),
      text: user.isAuctionCoordinator
        ? t('common.titles.manageAuctionGroupAllocations')
        : t('common.titles.auctionGroupAllocations'),
      icon: <GridViewIcon />,
    })
    menu.items.push({
      onClick: () => onClickRedirectHandler('/dashboard/irregular-suppliers'),
      text: t('common.titles.irregularSuppliers'),
      icon: <GridViewIcon />,
    })
    menu.items.push({
      onClick: () =>
        onClickRedirectHandler('/dashboard/supplier-promotion-characteristics'),
      text: user.isAuctionCoordinator
        ? t('common.titles.manageSupplierPromotionCharacteristics')
        : t('common.titles.supplierPromotionCharacteristics'),
      icon: <GridViewIcon />,
    })
    menu.items.push({
      onClick: () => onClickRedirectHandler('/dashboard/search-product'),
      text: t('common.titles.searchProduct'),
      icon: <GridViewIcon />,
    })
    features?.[FEATURES.KLOKSTATUS] &&
      menu.items.push({
        onClick: () => onClickRedirectHandler('/dashboard/clocks-status'),
        text: t('common.titles.clocksStatus'),
        icon: <GridViewIcon />,
      })
    features?.[FEATURES.DASHBOARD] &&
      menu.items.push({
        onClick: () => onClickRedirectHandler('/dashboard/logistics-dashboard'),
        text: t('common.titles.logisticsDashboard'),
        icon: <GridViewIcon />,
      })
    menu.items.push({
      isFooter: true,
      onClick: () => onClickRedirectHandler('/dashboard/profile'),
      text: t('common.titles.profilePage'),
      icon: <UserIcon />,
    })
    menu.items.push({
      onClick: () => logout(),
      text: t('common.titles.logout'),
      isFooter: true,
      icon: <LogoutIcon />,
    })

    return menu
  }

  /*
   * Render
   */
  return (
    <Box
      sx={{
        '& header.MuiAppBar-root + .MuiPaper-root': {
          position: 'fixed',
          overflowY: 'auto',
        },
      }}
    >
      <RfhAppBar
        mode={'menuItems'}
        menuOpen={menuOpen}
        onLogoClick={() => onClickRedirectHandler('/dashboard')}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => {
          setMenuOpen(false)
        }}
        activeLanguage={i18n.language.substring(0, 2)}
        changeLanguage={(lng?: string) => i18n.changeLanguage(lng)}
        customLanguageMenu={getLanguages}
        title={t('common.applicationName')}
        primaryMenu={getButtons()}
        sx={{
          position: 'fixed',
          '& .MuiToolbar-root.MuiToolbar-dense': {
            paddingY: 0,
          },
          '& .MuiButtonBase-root.MuiIconButton-root': {
            padding: 1.2,
          },
          '& .MuiTypography-root.MuiTypography-h1': {
            padding: 0,
          },
        }}
      />
    </Box>
  )
}
