import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import {
  IProductclusterVeilgroepRegelCode,
  IVeilgroepIndelingRegelView,
} from 'src/common/services/client'
import type { Item } from 'src/common/types'

import {
  CodeSoortFust,
  CodeSoortProduct,
  CodeSoortProductgroep,
  initialRule,
  initialRuleCodes,
} from '../constants'
import { concatCodes } from '../lib'
import type { CodeSoort } from '../types'

export type StoreState = {
  rule: IVeilgroepIndelingRegelView
  oldRule: IVeilgroepIndelingRegelView
  ruleCodes: Record<CodeSoort | 'VEILGROEPREGEL', Item<number>[]>
  ruleCodesToUpdate: IProductclusterVeilgroepRegelCode[]
  oldRuleCodesToUpdate: IProductclusterVeilgroepRegelCode[]
  mustRefetchReferenceData: boolean
}

type Actions = {
  /* store */
  resetRuleStoreState: () => void
  /* veilgroepRegel */
  setRule: (r: IVeilgroepIndelingRegelView) => void
  setOldRule: (or: IVeilgroepIndelingRegelView) => void
  /* regelcodes */
  setRuleCodes: (rcs: Item<number>[], codeSoort?: CodeSoort) => void
  resetRuleCodes: () => void
  setRuleCodesToUpdate: (rcs: IProductclusterVeilgroepRegelCode[]) => void
  setOldRuleCodesToUpdate: (orcs: IProductclusterVeilgroepRegelCode[]) => void
  upsertRuleCodeToUpdate: (rc: IProductclusterVeilgroepRegelCode) => void
  setMustRefetchReferenceData: (input: boolean) => void
  /* computed */
  concatCodesText: (codeSoort: CodeSoort) => string
  initialCodesText: (codeSoort: CodeSoort) => string
  getRuleCodes: (codeSoort: CodeSoort) => Item<number>[]
}

const storeName = 'RuleStore'

const initialStoreState: StoreState = {
  rule: initialRule,
  oldRule: initialRule,
  ruleCodes: initialRuleCodes,
  ruleCodesToUpdate: [] as IProductclusterVeilgroepRegelCode[],
  oldRuleCodesToUpdate: [] as IProductclusterVeilgroepRegelCode[],
  mustRefetchReferenceData: true, //! initially always refetch
}

const useRuleStore = create<StoreState & Actions>()(
  devtools(
    persist(
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetRuleStoreState: () =>
          set(() => initialStoreState, false, 'resetRuleStoreState'),
        /* veilgroepRegel */
        setRule: (r: IVeilgroepIndelingRegelView) => {
          set(
            () => ({
              rule: {
                ...r,
                ingangsDatum: new Date(r.ingangsDatum),
                eindDatum: new Date(r.eindDatum),
              },
            }),
            false,
            'setRule'
          )
        },
        setOldRule: (or: IVeilgroepIndelingRegelView) => {
          set(
            () => ({
              oldRule: {
                ...or,
                ingangsDatum: new Date(or.ingangsDatum),
                eindDatum: new Date(or.eindDatum),
              },
            }),
            false,
            'setOldRule'
          )
        },
        /* regelcodes */
        setRuleCodes: (rcs: Item<number>[], codeSoort?: CodeSoort) =>
          set(
            (state: StoreState) => ({
              ruleCodes: {
                ...state.ruleCodes, //! keep all other codesSoorts
                [codeSoort || 'VEILGROEPREGEL']: [...rcs],
              },
            }),
            false,
            'setRuleCodes'
          ),
        resetRuleCodes: () =>
          set(
            (state: StoreState) => ({
              ruleCodes: initialRuleCodes,
            }),
            false,
            'resetRuleCodes'
          ),
        setRuleCodesToUpdate: (rcs: IProductclusterVeilgroepRegelCode[]) =>
          set(
            (state: StoreState) => ({ ruleCodesToUpdate: [...rcs] }),
            false,
            'setRuleCodesToUpdate'
          ),
        setOldRuleCodesToUpdate: (orcs: IProductclusterVeilgroepRegelCode[]) =>
          set(
            (state: StoreState) => ({ oldRuleCodesToUpdate: [...orcs] }),
            false,
            'setOldRuleCodesToUpdate'
          ),
        upsertRuleCodeToUpdate: (rcu: IProductclusterVeilgroepRegelCode) =>
          set(
            (state: StoreState) => {
              const index = state.ruleCodesToUpdate.findIndex(
                rc => rc.code === rcu.code && rc.codeSoort === rcu.codeSoort
              )
              //! add if new
              if (index === -1) {
                rcu.productclusterVeilgroepRegelId =
                  state.rule.productclusterVeilgroepRegelId
                return {
                  ruleCodesToUpdate: [...state.ruleCodesToUpdate, rcu],
                }
              }
              //! update if exists
              return {
                ruleCodesToUpdate: [
                  ...state.ruleCodesToUpdate.slice(0, index),
                  rcu,
                  ...state.ruleCodesToUpdate.slice(index + 1),
                ],
              }
            },
            false,
            'upsertRuleCodeToUpdate'
          ),
        setMustRefetchReferenceData: (input: boolean) =>
          set(
            () => ({ mustRefetchReferenceData: input }),
            false,
            'setMustRefetchReferenceData'
          ),
        /* computed */
        concatCodesText: (codeSoort: CodeSoort): string =>
          concatCodes(get().ruleCodes[codeSoort]),
        initialCodesText: (codeSoort: CodeSoort): string => {
          switch (codeSoort) {
            case CodeSoortFust:
              return get().rule?.fustTekst || ''
            case CodeSoortProduct:
              return get().rule?.productTekst || ''
            case CodeSoortProductgroep:
              return get().rule?.productgroepTekst || ''
          }
        },
        getRuleCodes: (codeSoort: CodeSoort): Item<number>[] =>
          get().ruleCodes[codeSoort],
      }),
      {
        name: 'auction-group-rule-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)

export default useRuleStore
