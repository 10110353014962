import { ChangeEvent, ChangeEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@mui/material/FormControl'
import NativeSelect from '@mui/material/NativeSelect'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { ELEMENT_HEIGHT } from 'src/common/constants'
import { noOp } from 'src/common/lib'
import type { BloemenPlanten } from 'src/common/types'

type BloemenPlantenSelectProps = {
  bloemenPlanten?: BloemenPlanten
  disabled?: boolean
  onChange?: ChangeEventHandler<HTMLSelectElement>
}

export default function BloemenPlantenSelect({
  bloemenPlanten: initialBloemenPlanten = 'B',
  disabled = false,
  onChange = noOp,
}: Readonly<BloemenPlantenSelectProps>): JSX.Element {
  const { t } = useTranslation()
  // B = bloemen, P = planten
  const [bloemenPlanten, setBloemenPlanten] = useState<BloemenPlanten>(
    initialBloemenPlanten
  )

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setBloemenPlanten(event.target.value as BloemenPlanten)
    onChange(event)
  }

  return (
    <FormControl>
      <Typography variant={'subheader'}>
        {t('productCluster.flowersPlants')}
      </Typography>
      <NativeSelect
        aria-label={'flowers or plants selector'}
        disabled={disabled}
        onChange={handleChange}
        readOnly={disabled}
        value={bloemenPlanten as string}
        sx={{
          px: 1,
          mt: 1.5,
          height: ELEMENT_HEIGHT,
          minWidth: 150,
          maxWidth: 180,
          bgcolor: RfhColors.white,
          'svg.MuiNativeSelect-icon': {
            pointerEvents: 'none',
          },
        }}
      >
        <option value={'B'}>{t('productCluster.flowers')}</option>
        <option value={'P'}>{t('productCluster.plants')}</option>
      </NativeSelect>
    </FormControl>
  )
}
