import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { IAanvoerderclusterView } from 'src/common/services/client'

export type StoreState = {
  clusters: IAanvoerderclusterView[]
  mustRefetchClusters: boolean
}

type Actions = {
  /* store */
  resetSupplierClustersStoreState: () => void
  /* supplier clusters */
  addCluster: (c: IAanvoerderclusterView) => void
  removeCluster: (id: number) => void
  setClusters: (cs: IAanvoerderclusterView[]) => void
  setMustRefetchClusters: (input: boolean) => void
}

const storeName = 'SupplierClustersStore'

const initialStoreState: StoreState = {
  clusters: [],
  mustRefetchClusters: false,
}

export const useSupplierClustersStore = create<StoreState & Actions>()(
  devtools(
    persist(
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetSupplierClustersStoreState: () =>
          set(
            () => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetSupplierClustersStoreState'
          ),
        /* supplier clusters */
        addCluster: (c: IAanvoerderclusterView) =>
          set(
            (state: StoreState) => ({
              clusters: [...state.clusters, c],
            }),
            false,
            'addCluster'
          ),
        removeCluster: (id: number) =>
          set(
            (state: StoreState) => ({
              clusters: [
                ...state.clusters.filter(c => c?.aanvoerderclusterID !== id),
              ],
            }),
            false,
            'removeCluster'
          ),
        setClusters: (cs: IAanvoerderclusterView[]) =>
          set(() => ({ clusters: [...cs] }), false, 'setClusters'),
        setMustRefetchClusters: (input: boolean) =>
          set(
            () => ({
              mustRefetchClusters: input,
            }),
            false,
            'setMustRefetchClusters'
          ),
      }),
      {
        name: 'supplier-clusters-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
