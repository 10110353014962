import { MouseEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Grid } from '@mui/material'
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid-premium'
import { DeleteIcon } from '@rfh/ui/shared/floriday-icons'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import {
  ActionButton,
  ActionModal,
  ButtonsBlock,
  CustomDataGrid,
} from 'src/common/components'
import { GRID_MARGIN_TOP } from 'src/common/constants'
import { getStringValue } from 'src/common/lib'
import { useApiClient } from 'src/common/providers'
import type { IAanvoerderOnregelmatig } from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'

import { FEATURE_ROOT_PATH, IRREGULAR_SUPPLIERS_CACHE_KEY } from '../constants'
import { deleteIrregularSupplier } from '../services/irregularSuppliersService'
import { useIrregularSuppliersStore } from '../stores'

import i18next from 'i18next'

type IrregularSuppliersGridProps = {
  loading: boolean
}

const translationsKey = 'irregularSuppliers.deleteDialog'

export default function IrregularSuppliersGrid({
  loading = false,
  ...rest
}: Readonly<IrregularSuppliersGridProps>): JSX.Element {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { apiClient } = useApiClient()
  const [open, setOpen] = useState<boolean>(false)
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0)
  const {
    irregularSuppliers,
    supplierCodeFilter,
    locationCodeFilter,
    setMustRefetchIrregularSuppliers,
    removeIrregularSupplier,
  } = useIrregularSuppliersStore()

  const addIrregularSupplier = useCallback(() => {
    history.push(`/dashboard/${FEATURE_ROOT_PATH}/add`)
  }, [history])

  const openDeleteDialog = useCallback(
    (row: IAanvoerderOnregelmatig) =>
      (event: MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation() // to override onRowClick event
        setOpen(true)
        setIdToBeDeleted(row.aanvoerderOnregelmatigId)
      },
    [setOpen, setIdToBeDeleted]
  )

  const closeDeleteDialog = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleConfirmDelete = useCallback(async () => {
    try {
      closeDeleteDialog()
      await deleteIrregularSupplier(apiClient, idToBeDeleted)
      removeIrregularSupplier(idToBeDeleted)
      snackbarUtils.success(t(`${translationsKey}.deleteSuccessMessage`))
    } catch (error: any) {
      snackbarUtils.error(getStringValue(error))
    } finally {
      setMustRefetchIrregularSuppliers(true)
    }
  }, [
    apiClient,
    closeDeleteDialog,
    idToBeDeleted,
    removeIrregularSupplier,
    setMustRefetchIrregularSuppliers,
    t,
  ])

  const columns: GridColDef[] = [
    {
      field: 'vestigingNaam',
      headerName: i18next.t('common.auctionLocation'),
      flex: 1,
    },
    {
      field: 'aanvoerderNummer',
      headerName: i18next.t('overviews.supplierNumber'),
      flex: 1,
    },
    {
      field: 'aanvoerderKlokNaam',
      headerName: i18next.t('overviews.supplierName'),
      flex: 4,
    },
    {
      field: 'delete',
      type: 'actions',
      headerName: i18n.t('common.delete'),
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      hideable: false,
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      renderHeader: () => (
        <DeleteIcon
          sx={{
            color: RfhColors.fogWhite,
            width: '20px',
            height: '20px',
          }}
        />
      ),
      getActions: ({ id, row }) => [
        <GridActionsCellItem
          className='textPrimary'
          color='inherit'
          icon={
            <DeleteIcon
              sx={{
                cursor: 'pointer',
                color: RfhColors.darkGrey,
                width: '20px',
                height: '20px',
              }}
            />
          }
          key={id}
          label='Delete'
          onClick={openDeleteDialog(row)}
        />,
      ],
    },
  ]

  return (
    <Grid container marginTop={GRID_MARGIN_TOP} {...rest}>
      <Grid item xs={12} height={0} zIndex={1}>
        <ButtonsBlock marginRight={28}>
          <ActionButton onClick={addIrregularSupplier}>
            {t('common.add')}
          </ActionButton>
        </ButtonsBlock>
      </Grid>
      <Grid item xs={12}>
        <CustomDataGrid
          cacheKey={IRREGULAR_SUPPLIERS_CACHE_KEY}
          language={i18n.language}
          getRowId={row => row.aanvoerderOnregelmatigId}
          columns={columns}
          rows={irregularSuppliers.filter(
            (row: IAanvoerderOnregelmatig) =>
              (supplierCodeFilter === 0 ||
                row.aanvoerderNummer === supplierCodeFilter) &&
              (locationCodeFilter === 0 ||
                row.vestigingCode === locationCodeFilter)
          )}
          gridProps={{
            loading,
            onRowClick: (params: GridRowParams) => openDeleteDialog(params.row),
          }}
          pagination={false}
          paginationMode={'client'}
          sortingMode={'client'}
        />
      </Grid>
      <Grid item xs={12} height={0}>
        <ActionModal
          onCancel={closeDeleteDialog}
          onConfirm={handleConfirmDelete}
          open={open}
          translationsKey={translationsKey}
        />
      </Grid>
    </Grid>
  )
}
