import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormGroup,
  Grid,
} from '@mui/material'
import { RfhTypography, TextField } from '@rfh/ui'
import { Button } from '@rfh/ui/components/RfhButton'

import {
  AvailableReasonsInput,
  CustomDatePicker,
  StyledGrid,
  StyledRfhTypographyHeader,
  StyledRfhTypographyText,
} from 'src/common/components'
import { ThemeConfig } from 'src/common/config'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { updateRegularityException } from 'src/common/services'
import {
  AanvoerderProductRegelmatigUitzondering,
  IAanvoerderProductRegelmatigUitzonderingView,
} from 'src/common/services/client'
import {
  disableNonFridays,
  formatDate,
  getUTCDate,
  snackbarUtils,
} from 'src/common/utils'

type Values = {
  uitzonderingDatumVanaf: Date
  uitzonderingDatumTtm: Date
  uitzonderingRedenCode: number
  uitzonderingRedenTekst: string
}

type ProductRegularityExceptionEditProps = {
  regularityException: IAanvoerderProductRegelmatigUitzonderingView
  open: boolean
  onClose: () => void
  onCancel: () => void
  refresh: () => void
}

export default function ProductRegularityExceptionEdit({
  regularityException,
  open,
  onCancel,
  onClose,
  refresh,
}: Readonly<ProductRegularityExceptionEditProps>): JSX.Element {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues: Values = {
    uitzonderingDatumVanaf: undefined,
    uitzonderingDatumTtm: undefined,
    uitzonderingRedenCode: undefined,
    uitzonderingRedenTekst: undefined,
  }

  const [values, setValues] = useState<Values>(defaultValues)
  const { t } = useTranslation()
  const { apiClient } = useApiClient()
  const [inProgress, setInProgress] = useState(false)
  const user = useUser()

  const cancelAction = () => {
    setValues(defaultValues)
    onCancel()
  }

  const closeAction = () => {
    setValues(defaultValues)
    refresh()
    onClose()
  }

  const handleChange = (
    changedValues: Partial<IAanvoerderProductRegelmatigUitzonderingView>
  ): void => {
    const newValues = {
      ...values,
      ...changedValues,
    }
    setValues(newValues)
  }

  const handleInputChangeSelect = (name: string, value: any): void => {
    const newValue = { ...values, [name]: value }
    setValues(newValue)
  }

  const onSubmit = async () => {
    try {
      setInProgress(true)

      const {
        aanvoerderNaam, // not needed
        vestigingNaam, // not needed
        productNaam, // not needed
        uitzonderingRedenOmschrijving, // not needed
        ...exception
      } = regularityException

      const body = new AanvoerderProductRegelmatigUitzondering({
        ...exception,
        uitzonderingDatumTtm: values.uitzonderingDatumTtm,
        uitzonderingRedenCode: values.uitzonderingRedenCode,
        uitzonderingRedenTekst: values.uitzonderingRedenTekst,
        mutatieDatumTijd: new Date(),
        mutatieGebruiker: user.sub,
      })

      await updateRegularityException(apiClient, body)

      closeAction()
    } catch (error: any) {
      snackbarUtils.error(String(error))
    }
    setInProgress(false)
  }

  useEffect(() => {
    if (regularityException) {
      setValues({
        uitzonderingDatumVanaf: regularityException.uitzonderingDatumVanaf
          ? new Date(regularityException.uitzonderingDatumVanaf)
          : undefined,
        uitzonderingDatumTtm: regularityException.uitzonderingDatumTtm
          ? new Date(regularityException.uitzonderingDatumTtm)
          : undefined,
        uitzonderingRedenCode: regularityException.uitzonderingRedenCode,
        uitzonderingRedenTekst: regularityException.uitzonderingRedenTekst,
      })
    } else {
      setValues(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularityException])

  return (
    <Dialog open={open} onClose={cancelAction}>
      <DialogContent>
        <RfhTypography variant={'h5'} style={{ marginBottom: '16px' }}>
          {t('common.titles.editReqularityException')}
        </RfhTypography>

        <Grid container>
          <StyledGrid container>
            <Grid item xs={12} sm={4}>
              <RfhTypography variant={'subheader'}>
                {t('exceptions.supplierNumber')}
              </RfhTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledRfhTypographyText variant={'body-copy'}>
                {regularityException
                  ? `${regularityException.aanvoerderNummer} ${regularityException.aanvoerderNaam}`
                  : '-'}
              </StyledRfhTypographyText>
            </Grid>
          </StyledGrid>

          <StyledGrid container>
            <Grid item xs={12} sm={4}>
              <RfhTypography variant={'subheader'}>
                {t('common.auctionLocation')}
              </RfhTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledRfhTypographyText variant={'body-copy'}>
                {regularityException ? regularityException.vestigingNaam : '-'}
              </StyledRfhTypographyText>
            </Grid>
          </StyledGrid>

          <StyledGrid container>
            <Grid item xs={12} sm={4}>
              <RfhTypography variant={'subheader'}>
                {t('exceptions.productCode')}
              </RfhTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledRfhTypographyText variant={'body-copy'}>
                {regularityException
                  ? `${regularityException.productCode} ${regularityException.productNaam}`
                  : '-'}
              </StyledRfhTypographyText>
            </Grid>
          </StyledGrid>

          <StyledGrid container>
            <Grid item xs={12} sm={4}>
              <RfhTypography variant={'subheader'}>
                {t('exceptions.startDate')}
              </RfhTypography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledRfhTypographyText variant={'body-copy'}>
                {regularityException
                  ? formatDate(
                      regularityException.uitzonderingDatumVanaf,
                      t('common.dateFormatShort')
                    )
                  : '-'}
              </StyledRfhTypographyText>
            </Grid>
          </StyledGrid>
          <FormGroup
            sx={{
              width: '100%',
              marginTop: ThemeConfig.spacing.xs,
            }}
          >
            <Grid container sx={{ margin: 0 }}>
              <Grid item xs={12} sm={4} sx={{ verticalAlign: 'center' }}>
                <StyledRfhTypographyHeader variant='subheader'>
                  {t('exceptions.endDate')}
                </StyledRfhTypographyHeader>
              </Grid>

              <Grid item xs={12} sm={8}>
                <FormControl
                  sx={{
                    margin: 0,
                    width: '100%',
                  }}
                >
                  <StyledGrid>
                    {/* Workaroud because of missing functionallity in RfhUi datepicker (disablePast, shouldDisableDate, shouldDisableMonth, shouldDisableYear) */}
                    <CustomDatePicker
                      value={values.uitzonderingDatumTtm}
                      onChange={value => {
                        if (value) {
                          handleChange({
                            uitzonderingDatumTtm: getUTCDate(value),
                          })
                        }
                      }}
                      shouldDisableDate={disableNonFridays(
                        values.uitzonderingDatumVanaf
                      )}
                      disablePast
                    ></CustomDatePicker>
                  </StyledGrid>
                </FormControl>
              </Grid>
            </Grid>

            <StyledGrid container sx={{ margin: 0 }}>
              <Grid item xs={12} sm={4}>
                <StyledRfhTypographyHeader variant='subheader'>
                  {t('exceptions.reason')}
                </StyledRfhTypographyHeader>
              </Grid>
              <Grid item xs={12} sm={8} sx={{ margin: 0, padding: 0 }}>
                <FormControl
                  sx={{
                    margin: 0,
                    width: '100%',
                  }}
                >
                  <AvailableReasonsInput
                    input={values.uitzonderingRedenCode}
                    onChangeReason={handleInputChangeSelect}
                    marginTop={0}
                  />
                </FormControl>
              </Grid>
            </StyledGrid>

            <StyledGrid container>
              <Grid item xs={12} sm={4}>
                <RfhTypography variant={'subheader'}>
                  {t('exceptions.freeText')}
                </RfhTypography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl
                  sx={{
                    margin: 0,
                    width: '100%',
                    padding: 0,
                  }}
                >
                  <TextField
                    id='uitzonderingRedenTekst'
                    name='uitzonderingRedenTekst'
                    multiline
                    fullWidth
                    autoFocus
                    placeholder={t('exceptions.freeText')}
                    value={values.uitzonderingRedenTekst}
                    onChange={evnt => {
                      handleChange({
                        [evnt.target.name]: evnt.target.value,
                      })
                    }}
                    fieldColor='dark'
                  ></TextField>
                </FormControl>
              </Grid>
            </StyledGrid>
          </FormGroup>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='text--underlined' onClick={cancelAction}>
          {t('common.cancel')}
        </Button>
        <Button
          variant='text--underlined'
          onClick={onSubmit}
          isLoading={inProgress}
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
