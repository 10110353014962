import { useCallback, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'

import { Divider } from 'src/common/components'
import { usePathname } from 'src/common/hooks'
import { getStringValue, initialState, refreshReducer } from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { IClient } from 'src/common/services/client'
import snackbarUtils from 'src/common/utils/snackbar/SnackbarUtils'

import {
  AllocationRulesFilter,
  AllocationRulesGrid,
  AllocationRulesGridSkeleton,
} from '../components'
import { FEATURE_ROOT_PATH } from '../constants'
import { getAllocationRulesAsync } from '../services'
import { useAllocationStore, useRuleStore } from '../stores'

export default function ManageAllocation(): JSX.Element {
  const { t } = useTranslation()
  const { apiClient } = useApiClient()
  const { urlParam } = usePathname(FEATURE_ROOT_PATH)
  const isAuctionCoordinator = useUser().isAuctionCoordinator
  const {
    allocation,
    getNewOrderNumber,
    mustRefetchAllocationRules,
    setAllocationRules,
    setMustRefetchAllocationRules,
    setOldAllocationRules,
  } = useAllocationStore()
  const { rule, setOldRuleCodesToUpdate, setRule, setRuleCodesToUpdate } =
    useRuleStore()
  const [state, dispatch] = useReducer(refreshReducer, initialState)

  const refetchAllocationRules = useCallback(
    async (client: IClient) => {
      if (!allocation || state.loading) {
        return Promise.resolve()
      }

      try {
        dispatch({ type: 'PENDING' })
        const refetchedAllocationRules = await getAllocationRulesAsync(
          client,
          allocation.productclusterID,
          allocation.vestigingCode
        )
        setAllocationRules(refetchedAllocationRules)
        setOldAllocationRules(refetchedAllocationRules)
      } catch (error: any) {
        dispatch({ type: 'ERROR' })
        snackbarUtils.error(getStringValue(error))
      } finally {
        dispatch({ type: 'RESOLVED' })
      }
    },
    [allocation, setAllocationRules, setOldAllocationRules, state.loading]
  )

  useEffect(() => {
    if (apiClient && mustRefetchAllocationRules) {
      refetchAllocationRules(apiClient)
      setMustRefetchAllocationRules(false)
    }
  }, [
    apiClient,
    mustRefetchAllocationRules,
    refetchAllocationRules,
    setMustRefetchAllocationRules,
  ])

  useEffect(() => {
    // reset rule store state
    const productclusterID = urlParam[0]
    if (allocation?.vestigingCode) {
      setRule({
        ...rule,
        productclusterID,
        vestigingCode: allocation.vestigingCode,
        volgordeNummer: getNewOrderNumber(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocation?.vestigingCode, urlParam[0]])

  useEffect(() => {
    setRuleCodesToUpdate([])
    setOldRuleCodesToUpdate([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container maxWidth='xl'>
      <Grid item xs={12} mt={4} mb={2}>
        <Typography variant='h3' style={{ fontWeight: 'bold' }}>
          {t('common.titles.manageAuctionGroupAllocation')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AllocationRulesFilter data-testid={'allocation-filter'} />
      </Grid>
      <Grid item xs={12}>
        <Divider {...{ marginTop: 16 }} />
      </Grid>
      <Grid item xs={12}>
        {state.loading ? (
          <AllocationRulesGridSkeleton />
        ) : (
          <AllocationRulesGrid readonly={!isAuctionCoordinator} />
        )}
      </Grid>
    </Container>
  )
}
