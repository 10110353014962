import {
  FC,
  MouseEventHandler,
  ReactElement,
  cloneElement,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { CardHorizontalIcon } from '@rfh/ui'

import { StyledGridItem } from './MenuCard.styles'

type MenuCardProps = {
  icon?: ReactElement
  title: string
  subheader?: string
  uri?: string
  bodyText?: string
  visible?: boolean
  onClick?: MouseEventHandler<HTMLDivElement> | (() => void)
}

/**
 * MenuCard Component
 *
 * @component
 * @param {Object} props - The props object for configuring the MenuCard.
 * @param {JSX.Element} props.icon - The icon component.
 * @param {string} props.title - The title text.
 * @param {string} props.subheader - The subheader text.
 * @param {string} [props.uri] - The URI associated with the MenuCard (optional).
 * @param {string} [props.bodyText] - The body text (`optional`).
 * @param {boolean} props.visible - Should this MenuCard be rendered and visible (`optional`).
 * @param {function} [props.onClick] - The click event handler (`optional`).
 *
 * @description
 * You can provide the following props to customize the MenuCard:
 * - `icon`: The icon component to display, it accepts an optional sx property
 * - `title`: The title text should match the name of the property
 *                            in the `common.titles` block of `translations`.
 * - `subheader`: The subheader text should match the name of the property
 *                            in the `common.titleDescriptions` block of `translations`.
 * - `uri` (optional): The URI associated with the MenuCard, only the part after `/dashboard/`.
 * - `bodyText` (optional): Additional body text, te show e.g. the number of records on the card.
 * - `visible` (optional): if this property is not explicitly passed, the component is rendered.
 *                            if it is passed, then its value determines whether to render the component.
 * - `onClick` (optional): A click event handler for the MenuCard. If not present,
 *                            this will by default route to `uri` when clicked.
 * @returns {JSX.Element} Rendered MenuCard component.
 */
const MenuCard: FC<MenuCardProps> = ({
  title,
  subheader = '',
  uri = '',
  bodyText,
  icon: Icon,
  visible,
  onClick,
}) => {
  const cardHeight = '145px'
  const { t } = useTranslation()
  const history = useHistory()

  const onClickRedirectHandler = useCallback(
    (path: string) => (): void => {
      history.push(path)
    },
    [history]
  )

  const FinalIcon = Icon
    ? cloneElement(Icon, {
        sx: {
          fontSize: '0.9em !important',
          ...(Icon?.props?.sx || {}), // for evt. custom sx from outside
        },
      })
    : null

  // the "visible" prop is passed as "false" => nothing to do
  if (visible != null && !visible) {
    return null as JSX.Element
  }

  return (
    <StyledGridItem item xs={12} sm={6} lg={4} data-testid='card'>
      <CardHorizontalIcon
        icon={FinalIcon}
        title={t(`common.titles.${title}` as any)}
        subheader={t(`common.titleDescriptions.${subheader}` as any)}
        onClick={onClick ?? onClickRedirectHandler(`/dashboard/${uri}`)}
        bodyText={bodyText}
        linkText=''
        onActionClick={() => {
          // Needed to stop linkTo navigation
        }}
        sx={{
          height: cardHeight,
          '& .MuiCardHeader-root': {
            alignItems: 'flex-start',
            marginTop: '4px',
            '& .MuiCardHeader-avatar': { marginTop: '5px' },
          },
        }}
      />
    </StyledGridItem>
  )
}

export default MenuCard
