import { useCallback, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'
import Container from '@mui/material/Container'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'

import { Divider } from 'src/common/components'
import { usePathname } from 'src/common/hooks'
import { initialState, refreshReducer } from 'src/common/lib'
import { useApiClient } from 'src/common/providers'
import { IClient } from 'src/common/services/client'
import { getLocalItem, snackbarUtils } from 'src/common/utils'
import { getPromotionCharacteristicValuesAsync } from 'src/features/AuctionGroupRules/services'

import CharacteristicMutation from '../components/CharacteristicMutation'
import CharacteristicMutationFilter from '../components/CharacteristicMutationFilter'
import { CHARACTERISTICS_FILTER_CACHE, FEATURE_ROOT_PATH } from '../constants'
import { getSupplierPromotionCharacteristicAsync } from '../services/supplierPromotionCharacteristicsServices'
import { useSupplierPromotionCharacteristicStore } from '../stores'
import type { FilterInput } from '../types'

export default function ManageSupplierPromotionCharacteristic(): JSX.Element {
  const { t } = useTranslation()
  const { ADDING, urlParam } = usePathname(FEATURE_ROOT_PATH)
  const {
    characteristicValues,
    setCharacteristic,
    setcharacteristicValues,
    setOldCharacteristic,
  } = useSupplierPromotionCharacteristicStore()
  const { apiClient } = useApiClient()
  const [state, dispatch] = useReducer(refreshReducer, initialState)

  const fetchCharacteristicValues = useCallback(async () => {
    if (!apiClient || state.loading) {
      return Promise.resolve()
    }

    try {
      dispatch({ type: 'PENDING' })
      const fetchedcharacteristicValues =
        await getPromotionCharacteristicValuesAsync(apiClient)
      setcharacteristicValues(fetchedcharacteristicValues)
    } catch (error) {
      dispatch({ type: 'ERROR' })
      snackbarUtils.error(`${error}`)
    } finally {
      dispatch({ type: 'RESOLVED' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, setcharacteristicValues])

  const ensurePreFilterdData = useCallback(
    async (client: IClient) => {
      if (!client || state.loading) return Promise.resolve()

      try {
        dispatch({ type: 'PENDING' })
        if (ADDING) {
          const filterInput = getLocalItem<FilterInput>(
            CHARACTERISTICS_FILTER_CACHE
          )
          if (
            filterInput?.aanvoerdernummer > 0 ||
            filterInput?.productCode > 0
          ) {
            setCharacteristic({
              aanvoerderNummer: filterInput?.aanvoerdernummer,
              productCode: filterInput?.productCode,
            })
          }
        } else {
          const fetchedCharacteristic =
            await getSupplierPromotionCharacteristicAsync(
              client,
              Number(urlParam)
            )
          setCharacteristic(fetchedCharacteristic)
          setOldCharacteristic(fetchedCharacteristic)
        }
      } catch (error) {
        dispatch({ type: 'ERROR' })
        snackbarUtils.error(`${error}`)
      } finally {
        dispatch({ type: 'RESOLVED' })
      }
    },
    //! don't add state.loading to deps!!! it will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ADDING, setCharacteristic, setOldCharacteristic, urlParam]
  )

  useEffect(() => {
    ensurePreFilterdData(apiClient)
  }, [apiClient, ensurePreFilterdData])

  useEffect(() => {
    if (!characteristicValues?.length) {
      fetchCharacteristicValues()
    }
  }, [characteristicValues?.length, fetchCharacteristicValues])

  return (
    <Container maxWidth='md'>
      <Grid item xs={12} mt={4} mb={2}>
        <Typography variant='h3' style={{ fontWeight: 'bold' }}>
          {t('common.titles.manageSupplierPromotionCharacteristics')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CharacteristicMutationFilter />
      </Grid>
      <Grid item xs={12}>
        <Divider {...{ marginTop: 16 }} />
      </Grid>
      <Grid item xs={12}>
        <CharacteristicMutation />
      </Grid>
    </Container>
  )
}
