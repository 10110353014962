import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'

import { Divider } from 'src/common/components'

import { RuleMutation, RuleMutationFilter } from '../components'
import { useRuleStore } from '../stores'

export default function ManageRuleMutation(): JSX.Element {
  const { t } = useTranslation()
  const { mustRefetchReferenceData, setMustRefetchReferenceData } =
    useRuleStore()

  useEffect(() => {
    if (mustRefetchReferenceData) {
      setMustRefetchReferenceData(false)
    }
  }, [mustRefetchReferenceData, setMustRefetchReferenceData])

  return (
    <Container maxWidth='xl'>
      <Grid item xs={12} mt={4} mb={2}>
        <Typography variant='h3' style={{ fontWeight: 'bold' }}>
          {t('common.titles.manageAuctionGroupRuleMutation')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RuleMutationFilter data-testid={'rule-mutation-filter'} />
      </Grid>
      <Grid item xs={12}>
        <Divider {...{ marginTop: 16 }} />
      </Grid>
      <Grid item xs={12}>
        <RuleMutation data-testid={'rule-mutation'} />
      </Grid>
    </Container>
  )
}
