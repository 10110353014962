// eslint-disable-next-line
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@mui/material'
import { CardTag } from '@rfh/ui'

type BooleanTagProps = {
  value: boolean
  inverseColors?: boolean
}

const BooleanTag: FC<BooleanTagProps> = ({
  value,
  inverseColors = false,
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        maxWidth: '50px',
        minWidth: '50px',
      }}
      data-testid='tag'
    >
      <CardTag
        tagColor={
          (value && inverseColors) || (!value && !inverseColors)
            ? 'inProgress'
            : 'registered'
        }
        data-testid={'card-tag'}
      >
        {value ? t('common.yes') : t('common.no')}
      </CardTag>
    </Box>
  )
}

export default BooleanTag
