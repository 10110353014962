import { FC, createContext, useEffect, useMemo } from 'react'

import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { IClient, IKenmerk } from 'src/common/services/client'

import { getAllCharacteristics } from '../services'
import { useAuctionGroupRuleStore } from '../stores'

type СharacteristicsContextType = {
  characteristics: IKenmerk[]
}

export const CharacteristicsContext =
  createContext<СharacteristicsContextType>(null)
CharacteristicsContext.displayName = 'CharacteristicsContext'

export const CharacteristicsProvider: FC = ({ children }) => {
  const { characteristics, setCharacteristics } = useAuctionGroupRuleStore()
  const { apiClient } = useApiClient()

  useEffect(() => {
    const ensureData = async (client: IClient) => {
      if (!client) {
        return Promise.resolve()
      }
      const result = await getAllCharacteristics(client)
      setCharacteristics(result)
    }
    ensureData(apiClient)
  }, [apiClient, setCharacteristics])

  const value = useMemo(() => ({ characteristics }), [characteristics])

  return (
    <CharacteristicsContext.Provider value={value}>
      {children}
    </CharacteristicsContext.Provider>
  )
}
