import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { TABLE_CELL_STYLE } from 'src/common/constants'
import type { Item } from 'src/common/types'

import LookupItem from './LookupItem'

type LookupGridProps = {
  items: Item<number>[]
  selectItem?: (item: Item<number>) => void
}

export default function LookupGrid({
  items = [] as Item<number>[],
  selectItem,
}: Readonly<LookupGridProps>): JSX.Element {
  const { t } = useTranslation()

  function handleClick(event: MouseEvent<HTMLTableRowElement>): void {
    const clickedRow = event.currentTarget
    const code = Number(clickedRow.cells[0].textContent)
    const name = clickedRow.cells[1].textContent.trim()
    const item = items.find(i => i.code === code)
    const id = item?.id
    const selected = item?.selected
    const rowItem: Item<number> = { code, name, id, selected }
    selectItem(rowItem)
  }

  return (
    <Table
      sx={{
        minHeight: '100px',
        marginTop: '7em',
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell sx={TABLE_CELL_STYLE}>{t('common.code')}</TableCell>
          <TableCell sx={TABLE_CELL_STYLE}>{t('common.name')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!items.length && (
          <TableRow>
            <TableCell colSpan={2}>{t('common.noResults')}</TableCell>
          </TableRow>
        )}
        {(items ?? []).map((item: Item<number>) => (
          <LookupItem key={item.code} item={item} onClick={handleClick} />
        ))}
      </TableBody>
    </Table>
  )
}
