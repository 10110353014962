/* eslint-disable @typescript-eslint/require-await */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useHistory } from 'react-router-dom'

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'

import { Config } from 'src/common/config/Config'

/**
 * This component is used to manage everything Okta related. By keeping it in one provider we are able to
 * keep is maintainable. The AuthenticationProvider is the only exception since it needs to be a child of the Okta Component
 */
export const OktaProvider = ({ children }: any): JSX.Element => {
  const history = useHistory()
  const oktaConfig = new OktaAuth({
    devMode: false,
    issuer: Config.okta.issuer,
    clientId: Config.okta.clientId,
    redirectUri: Config.okta.redirectUri,
    scopes: Config.okta.scopes,
    pkce: Config.okta.pkce,
  })

  /**
   * When the LoginCallback component from Okta is done verifying the login attempt this function will overwrite
   * the default redirect to the root page. We use it to get back to the point where we left of.
   */
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    history.push(
      toRelativeUrl(originalUri || '/dashboard', window.location.origin)
    )
  }

  return (
    <Security oktaAuth={oktaConfig} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  )
}
