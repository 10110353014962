import { ChangeEvent } from 'react'

import {
  BloemenPlantenSelect,
  FilterFieldsStack,
  FilterStack,
} from 'src/common/components'
import type { BloemenPlanten } from 'src/common/types'

import { useProductClustersStore } from '../stores'

export default function ClustersFilter({
  ...rest
}: Readonly<{ [x: string]: any }>): JSX.Element {
  // B = bloemen, P = planten
  const { bloemenPlanten, setBloemenPlanten } = useProductClustersStore()

  const handleChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    setBloemenPlanten(event.target.value as BloemenPlanten)
  }

  return (
    <FilterStack {...rest}>
      <FilterFieldsStack>
        <BloemenPlantenSelect
          bloemenPlanten={bloemenPlanten}
          onChange={handleChange}
        />
      </FilterFieldsStack>
    </FilterStack>
  )
}
