import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import {
  IProductclusterProductgroep,
  IProductclusterView,
} from 'src/common/services/client'

type StoreState = {
  cluster: IProductclusterView
  deletedProductgroepen: IProductclusterProductgroep[]
  mustRefetchCluster: boolean
  oldCluster: IProductclusterView
  productclusterProductgroepen: IProductclusterProductgroep[]
  productgroepenHaveChanged: boolean
}

type Actions = {
  /* store */
  resetProductClusterStoreState: () => void
  /* product cluster */
  setCluster: (c: IProductclusterView) => void
  setMustRefetchCluster: (input: boolean) => void
  setOldCluster: (oc: IProductclusterView) => void
  updateCluster: (uc: Partial<IProductclusterView>) => void
  /* their product groups */
  removeProductgroep: (id: number) => void
  setDeletedProductgroepen: (dpgs: IProductclusterProductgroep[]) => void
  setProductgroepen: (pgs: IProductclusterProductgroep[]) => void
  setProductgroepenHaveChanged: (changed: boolean) => void
}

const storeName = 'ProductClusterStore'

const initialStoreState: StoreState = {
  cluster: null,
  deletedProductgroepen: [] as IProductclusterProductgroep[],
  mustRefetchCluster: false,
  oldCluster: null,
  productclusterProductgroepen: [] as IProductclusterProductgroep[],
  productgroepenHaveChanged: false,
}

export const useProductClusterStore = create<StoreState & Actions>()(
  devtools(
    persist(
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetProductClusterStoreState: () =>
          set(
            () => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetProductClusterStoreState'
          ),
        /* product cluster */
        setCluster: (pcv: IProductclusterView) =>
          set(
            () => ({
              cluster: {
                ...pcv,
                productclusterProductgroepen:
                  [] as IProductclusterProductgroep[],
              },
            }),
            false,
            'setCluster'
          ),
        setMustRefetchCluster: (mustRefetch: boolean) =>
          set(
            () => ({ mustRefetchCluster: mustRefetch }),
            false,
            'setMustRefetchCluster'
          ),
        setOldCluster: (pcv: IProductclusterView) =>
          set(
            () => ({
              oldCluster: {
                ...pcv,
                productclusterProductgroepen:
                  [] as IProductclusterProductgroep[],
              },
            }),
            false,
            'setOldCluster'
          ),
        updateCluster: (pcv: Partial<IProductclusterView>) =>
          set(
            (state: StoreState) => ({
              cluster: {
                ...state.cluster,
                ...pcv,
                productclusterProductgroepen:
                  [] as IProductclusterProductgroep[],
              },
            }),
            false,
            'updateCluster'
          ),
        /* their product groups */
        removeProductgroep: (code: number) =>
          set(
            (state: StoreState) => {
              const toBeDeleted = state.productclusterProductgroepen.find(
                pcpg => pcpg?.productgroepCode === code
              )
              const cloneArray = [...state.deletedProductgroepen]
              if (!cloneArray.find(ca => ca.productgroepCode === code)) {
                cloneArray.push(toBeDeleted)
              }
              return {
                ...state,
                deletedProductgroepen: [...cloneArray],
                productclusterProductgroepen:
                  state.productclusterProductgroepen.filter(
                    pcpg => pcpg?.productgroepCode !== code
                  ),
              }
            },
            false,
            'removeProductgroep'
          ),
        setDeletedProductgroepen: (dpgs: IProductclusterProductgroep[]) =>
          set(
            () => ({ deletedProductgroepen: [...dpgs] }),
            false,
            'setDeletedProductgroepen'
          ),
        setProductgroepen: (pgs: IProductclusterProductgroep[]) =>
          set(
            () => ({ productclusterProductgroepen: [...pgs] }),
            false,
            'setProductgroepen'
          ),
        setProductgroepenHaveChanged: (changed: boolean) =>
          set(
            () => ({ productgroepenHaveChanged: changed }),
            false,
            'setProductgroepenHaveChanged'
          ),
      }),
      {
        name: 'product-cluster-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
