import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@mui/material'
import { LoadingIcon, RfhTypography } from '@rfh/ui'
import { CloseIcon } from '@rfh/ui/shared/floriday-icons'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { ThemeConfig } from '../config/SpacingConfig'

interface IProps {
  isError?: boolean
}

const Spinner: FC<IProps> = (props: IProps): ReactElement => {
  const { isError } = props
  const { t } = useTranslation()

  return isError ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <CloseIcon
        sx={{
          height: '100%',
          color: `${RfhColors.alertRed}`,
          marginTop: ThemeConfig.spacing.s,
          paddingBottom: ThemeConfig.spacing.s,
        }}
      />
      <RfhTypography
        variant='helpertext-error'
        sx={{
          color: `${RfhColors.alertRed}`,
        }}
      >
        {t('common.genericErrorShort')}
      </RfhTypography>
    </Box>
  ) : (
    <LoadingIcon />
  )
}

export default Spinner
