import { ReactNode } from 'react'

import { FormLabel, formLabelClasses } from '@mui/material'

type CustomLabelProps = {
  children: ReactNode
}

export default function CustomLabel({
  children,
  ...rest
}: Readonly<CustomLabelProps>): JSX.Element {
  return (
    <FormLabel
      sx={{
        [`&.${formLabelClasses.root}`]: {
          fontWeight: 'bold',
          color: 'common.black',
        },
      }}
      {...rest}
    >
      {children}
    </FormLabel>
  )
}
