import { FC, ReactNode } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { SxProps } from '@mui/system'

import { BUTTONS_GAP, ELEMENT_HEIGHT } from '../constants'

type ButtonsBlockProps = {
  gap?: number
  marginLeft?: number
  marginRight?: number
  marginBottom?: number
  marginTop?: number
  placeButtons?: 'left' | 'right' | 'center'
  children: ReactNode
  sx?: SxProps
}

/**
 * Renders a stack of buttons (with absolute positioning).
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {marginLeft} [props.marginLeft=0] - Margin left.
 * @param {marginRight} [props.marginRight=] - Margin right.
 * @param {marginBottom} [props.marginBottom=] - Margin bottom.
 * @param {marginTop} [props.marginTop=] - Margin top.
 * @param {'left' | 'right' | 'center'} [props.placeButtons='right'] - Position of the buttons block.
 * @param {ReactNode} props.children - The child elements to render inside the button stack.
 *
 * @returns {JSX.Element} - The rendered component.
 */
const ButtonsBlock: FC<ButtonsBlockProps> = ({
  gap = BUTTONS_GAP,
  marginLeft = 0,
  marginRight = 0,
  marginBottom = 0,
  marginTop = 0,
  placeButtons = 'right',
  children,
  sx,
}) => {
  let justify: string
  switch (placeButtons) {
    case 'left':
      justify = 'flex-start'
      break
    case 'center':
      justify = 'center'
      break
    case 'right':
      justify = 'flex-end'
      break
    default:
      throw new Error(`Invalid placeButtons value: ${placeButtons}`)
  }

  return (
    <Grid
      alignItems={'bottom'}
      container
      direction={'row'}
      justifyContent={justify}
      minHeight={ELEMENT_HEIGHT}
      position={'relative'}
      sx={{ pointerEvents: 'none', ...sx }}
    >
      <Box
        position='absolute'
        marginBottom={marginBottom}
        marginTop={marginTop}
        marginLeft={marginLeft}
        marginRight={marginRight}
      >
        <Stack
          alignItems={'end'}
          direction={'row'}
          gap={gap}
          justifyContent={justify}
        >
          {children}
        </Stack>
      </Box>
    </Grid>
  )
}

export default ButtonsBlock
