import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import {
  GridColDef,
  GridRenderCellParams,
  GridRowOrderChangeParams,
  GridRowParams,
  MuiEvent,
} from '@mui/x-data-grid-premium'
import DeleteIcon from '@rfh/ui/shared/floriday-icons/icons/DeleteIcon'
import EditIcon from '@rfh/ui/shared/floriday-icons/icons/EditIcon'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import {
  ActionButton,
  ActionModal,
  ButtonsBlock,
  CustomDataGrid,
} from 'src/common/components'
import { GRID_MARGIN_TOP } from 'src/common/constants'
import { usePathname } from 'src/common/hooks'
import { getStringValue } from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { IVeilgroepIndelingRegelView } from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'

import {
  ALLOCATION_VEILGROEP_REGELS_CACHE_KEY,
  FEATURE_ROOT_PATH,
} from '../constants'
import { useMutation } from '../hooks'
import {
  calcMarginRight,
  getMainAllocationRulesGridColumns,
  reorderRules,
} from '../lib'
import { deleteAllocationRuleAsync } from '../services'
import { useAllocationStore, useRuleStore } from '../stores'

type AllocationRulesGridProps = {
  loading?: boolean
  readonly?: boolean
}

export default function AllocationRulesGrid({
  loading = false,
  readonly = true,
  ...rest
}: Readonly<AllocationRulesGridProps>): JSX.Element {
  const marginRight = calcMarginRight()
  const translationsKey = 'auctionGroupAllocation.deleteDialog'
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const { apiClient } = useApiClient()
  const { newRule } = useMutation()
  const { urlParam } = usePathname(FEATURE_ROOT_PATH)
  const productclusterID = urlParam[0]
  const {
    allocationRules,
    removeAllocationRule,
    setAllocationRules,
    setMustRefetchAllocationRules,
  } = useAllocationStore()
  const { resetRuleCodes, setRule, setOldRule } = useRuleStore()
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0)
  const [open, setOpen] = useState<boolean>(() => false)

  const getRowId = useCallback(
    (row: IVeilgroepIndelingRegelView) => row.productclusterVeilgroepRegelId,
    []
  )

  const gotoRuleDetails = useCallback(
    (rule: IVeilgroepIndelingRegelView) => {
      setRule(rule)
      setOldRule(rule)
      resetRuleCodes()
      const affix =
        rule.productclusterVeilgroepRegelId > 0
          ? String(rule.productclusterVeilgroepRegelId)
          : 'add'
      history.push(
        `/dashboard/${FEATURE_ROOT_PATH}/${productclusterID}/${affix}`
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, productclusterID]
  )

  const addNewRule = useCallback(() => {
    gotoRuleDetails(newRule)
  }, [gotoRuleDetails, newRule])

  const selectRule = useCallback(
    (params: GridRowParams, event: MuiEvent): void => {
      const rule: IVeilgroepIndelingRegelView = params.row
      if (event.defaultMuiPrevented) {
        return
      }
      // Continue with the navigation logic for non-checkbox clicks
      gotoRuleDetails(rule)
    },
    [gotoRuleDetails]
  )

  const editRule = useCallback(
    (rule: IVeilgroepIndelingRegelView) => (): void => {
      gotoRuleDetails(rule)
    },
    [gotoRuleDetails]
  )

  const openDeleteDialog = useCallback(
    (row: IVeilgroepIndelingRegelView) =>
      (event: React.MouseEvent<HTMLDivElement>): void => {
        event.stopPropagation() // to override onRowClick event
        setOpen(true)
        setIdToBeDeleted(row.productclusterVeilgroepRegelId)
      },
    [setOpen, setIdToBeDeleted]
  )

  const closeDeleteDialog = useCallback((): void => {
    setOpen(false)
    setIdToBeDeleted(0)
  }, [setOpen, setIdToBeDeleted])

  const deleteRule = useCallback(async () => {
    if (!apiClient) {
      Promise.resolve()
    }

    try {
      closeDeleteDialog()
      await deleteAllocationRuleAsync(apiClient, idToBeDeleted)
      removeAllocationRule(idToBeDeleted)
      snackbarUtils.success(t(`${translationsKey}.deleteSuccessMessage`))
    } catch (error: any) {
      snackbarUtils.error(getStringValue(error))
    } finally {
      setMustRefetchAllocationRules(true)
    }
  }, [
    apiClient,
    closeDeleteDialog,
    idToBeDeleted,
    removeAllocationRule,
    setMustRefetchAllocationRules,
    t,
  ])

  const updateActive = useCallback(
    (row: IVeilgroepIndelingRegelView) =>
      (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation() // to override onRowClick event
        const index = allocationRules.findIndex(
          rule =>
            rule.productclusterVeilgroepRegelId ===
            row.productclusterVeilgroepRegelId
        )
        const tempRules = [...allocationRules]
        const updatedRule = {
          ...tempRules[index],
          actiefIndicatie: !row.actiefIndicatie,
        }
        tempRules[index] = updatedRule

        setAllocationRules(tempRules)
      },
    [allocationRules, setAllocationRules]
  )

  const changeRowOrder = (params: GridRowOrderChangeParams) => {
    const start = params.oldIndex
    const end = params.targetIndex
    if (end === start) return

    const reorderedRules = reorderRules(allocationRules, start, end)
    setAllocationRules(reorderedRules)
  }

  const columns = useMemo((): GridColDef[] => {
    const columnsSet = getMainAllocationRulesGridColumns(updateActive)
    if (readonly) return columnsSet

    // EDIT icon
    columnsSet.push(
      {
        field: 'edit',
        headerName: i18n.t('common.edit'),
        headerAlign: 'center',
        align: 'center',
        flex: 0.5,
        disableColumnMenu: true,
        type: 'actions',
        hideable: false,
        sortable: false,
        disableExport: true,
        renderHeader: () => (
          <EditIcon
            sx={{
              color: RfhColors.fogWhite,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams) => (
          <EditIcon
            onClick={editRule(params.row)}
            sx={{
              cursor: 'pointer',
              color: RfhColors.darkGrey,
              width: '20px',
              height: '20px',
            }}
          />
        ),
      },
      {
        field: 'delete',
        type: 'actions',
        headerName: i18n.t('common.delete'),
        headerAlign: 'center',
        align: 'center',
        flex: 0.5,
        hideable: false,
        sortable: false,
        disableColumnMenu: true,
        disableExport: true,
        renderHeader: () => (
          <DeleteIcon
            sx={{
              color: RfhColors.fogWhite,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams) => (
          <DeleteIcon
            onClick={openDeleteDialog(params.row)}
            sx={{
              cursor: 'pointer',
              color: RfhColors.darkGrey,
              width: '20px',
              height: '20px',
            }}
          />
        ),
      }
    )

    return columnsSet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, editRule, openDeleteDialog, readonly, updateActive])

  return (
    <Grid container marginTop={GRID_MARGIN_TOP} {...rest}>
      <Grid item xs={12} height={0} zIndex={1}>
        <ButtonsBlock marginRight={marginRight}>
          <ActionButton marginRight={0.65} onClick={addNewRule}>
            {t('common.add')}
          </ActionButton>
        </ButtonsBlock>
      </Grid>
      <Grid item xs={12}>
        <CustomDataGrid
          cacheKey={ALLOCATION_VEILGROEP_REGELS_CACHE_KEY}
          language={i18n.language}
          getRowId={getRowId}
          columns={columns}
          rows={allocationRules}
          gridProps={{
            loading,
            onRowClick: (params: GridRowParams, event: MuiEvent) =>
              selectRule(params, event),
            onRowOrderChange: changeRowOrder,
            rowReordering: !readonly,
            sx: {
              '& .checkbox.MuiBox-root input:checked': {
                backgroundColor: 'red !important',
              },
              zIndex: 100,
            },
          }}
          pagination={false}
          paginationMode={'client'}
          sortingMode={'client'}
        />
      </Grid>
      <Grid item xs={12}>
        <ActionModal
          onCancel={closeDeleteDialog}
          onConfirm={deleteRule}
          open={open}
          translationsKey={translationsKey}
        />
      </Grid>
    </Grid>
  )
}
