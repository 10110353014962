import { Stack } from '@mui/material'

import { BUTTONS_GAP } from 'src/common/constants'

export default function FilterFieldsStack({
  children,
}: Readonly<{ children: React.ReactNode }>): JSX.Element {
  return (
    <Stack
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'start'}
      gap={BUTTONS_GAP}
      marginBottom={'2em'}
    >
      {children}
    </Stack>
  )
}
