import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import {
  AanvoerderclusterAanvoerder,
  IAanvoerderclusterAanvoerder,
  IAanvoerderclusterView,
} from 'src/common/services/client'

import { emptyCluster } from '../constants'

type StoreState = {
  cluster: IAanvoerderclusterView
  clusterAanvoerders: AanvoerderclusterAanvoerder[]
  mustRefetchCluster: boolean
  oldCluster: IAanvoerderclusterView
  orderedChanged: boolean
  ordered: boolean
}

type Actions = {
  /* store */
  resetSupplierClusterStoreState: () => void
  /* supplier cluster */
  setCluster: (c: IAanvoerderclusterView) => void
  setMustRefetchCluster: (input: boolean) => void
  setOldCluster: (oc: IAanvoerderclusterView) => void
  updateCluster: (uc: Partial<IAanvoerderclusterView>) => void
  /* their suppliers */
  addClusterAanvoerder: (cs: IAanvoerderclusterAanvoerder) => void
  removeClusterAanvoerder: (id: number) => void
  renumber: (checked?: boolean) => void
  setClusterAanvoerders: (css: IAanvoerderclusterAanvoerder[]) => void
  setOrdered: (input: boolean) => void
  setOrderedChanged: (input: boolean) => void
}

const storeName = 'SupplierClusterStore'

const initialStoreState: StoreState = {
  cluster: emptyCluster,
  clusterAanvoerders: [] as AanvoerderclusterAanvoerder[],
  mustRefetchCluster: false,
  oldCluster: emptyCluster,
  ordered: false,
  orderedChanged: false,
}

export const useSupplierClusterStore = create<StoreState & Actions>()(
  devtools(
    persist(
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetSupplierClusterStoreState: () =>
          set(
            () => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetSupplierClusterStoreState'
          ),
        /* supplier cluster */
        setCluster: (c: IAanvoerderclusterView) =>
          set(
            () => {
              const newValue = { ...c }
              newValue.aanvoerderclusterAanvoerders &&
                delete newValue.aanvoerderclusterAanvoerders
              return { cluster: newValue }
            },
            false,
            'setCluster'
          ),
        setMustRefetchCluster: (input: boolean) =>
          set(
            () => ({ mustRefetchCluster: input }),
            false,
            'setMustRefetchCluster'
          ),
        setOldCluster: (oc: IAanvoerderclusterView) =>
          set(
            () => {
              const newValue = { ...oc }
              newValue.aanvoerderclusterAanvoerders &&
                delete newValue.aanvoerderclusterAanvoerders
              return { oldCluster: newValue }
            },
            false,
            'setOldCluster'
          ),
        updateCluster: (uc: Partial<IAanvoerderclusterView>) =>
          set(
            (state: StoreState) => {
              const newValue = { ...state.cluster, ...uc }
              newValue.aanvoerderclusterAanvoerders &&
                delete newValue.aanvoerderclusterAanvoerders
              return { cluster: newValue }
            },
            false,
            'updateCluster'
          ),
        /* their suppliers */
        renumber: (checked?: boolean) =>
          set(
            (state: StoreState) => {
              if (state.clusterAanvoerders.length === 0) {
                return
              }

              checked ?? state.ordered
                ? get().setClusterAanvoerders(
                    state.clusterAanvoerders.map((css, index) => ({
                      ...css,
                      volgordeNummer: index + 1,
                    }))
                  )
                : get().setClusterAanvoerders(
                    state.clusterAanvoerders.map(css => ({
                      ...css,
                      volgordeNummer: 0,
                    }))
                  )
            },
            false,
            'renumber'
          ),
        addClusterAanvoerder: (cs: IAanvoerderclusterAanvoerder) =>
          set(
            (state: StoreState) => ({
              ...state,
              clusterAanvoerders: [...state.clusterAanvoerders, cs],
            }),
            false,
            'addClusterAanvoerder'
          ),
        removeClusterAanvoerder: (id: number) =>
          set(
            (state: StoreState) => ({
              ...state,
              clusterAanvoerders: [
                ...state.clusterAanvoerders.filter(
                  cs => cs?.aanvoerderclusterAanvoerderId !== id
                ),
              ],
            }),
            false,
            'removeClusterAanvoerder'
          ),
        setClusterAanvoerders: (css: IAanvoerderclusterAanvoerder[]) =>
          set(
            () => ({ clusterAanvoerders: [...css] }),
            false,
            'setClusterAanvoerders'
          ),
        setOrdered: (input: boolean) =>
          set(() => ({ ordered: input }), false, 'setOrdered'),
        setOrderedChanged: (input: boolean) =>
          set(() => ({ orderedChanged: input }), false, 'setOrderedChanged'),
      }),
      {
        name: 'supplier-cluster-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
