import {
  GridPaginationInitialState,
  GridSortModel,
} from '@mui/x-data-grid-premium'

import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { IAanvoerderPromotieKenmerk } from 'src/common/services/client'

type StoreState = {
  characteristics: IAanvoerderPromotieKenmerk[]
  mustRefetchCharacteristics: boolean
  numberOfCharacticeristics: number
  filter: string
  pagination: GridPaginationInitialState
  sorting: GridSortModel
}

type Actions = {
  /* store */
  resetCharacteristicsStoreState: () => void
  /* supplierPromotionCharacteristics */
  addCharacteristic: (c: IAanvoerderPromotieKenmerk) => void
  removeCharacteristic: (id: number) => void
  setCharacteristics: (cs: IAanvoerderPromotieKenmerk[]) => void
  setNumberOfCharacticeristics: (input: number) => void
  setMustRefetchCharacteristics: (input: boolean) => void
  setFilter: (input: string) => void
  setPagination: (input: GridPaginationInitialState) => void
  setSorting: (input: GridSortModel) => void
}

const storeName = 'SupplierPromotionCharacteristicsStore'
const initialStoreState: StoreState = {
  characteristics: [],
  mustRefetchCharacteristics: false,
  numberOfCharacticeristics: 0,
  filter: undefined,
  pagination: undefined,
  sorting: undefined,
}

export const useSupplierPromotionCharacteristicsStore = create<
  StoreState & Actions
>()(
  devtools(
    persist(
      (set, get: () => any): StoreState & Actions => ({
        ...initialStoreState,
        /* store */
        resetCharacteristicsStoreState: () =>
          set(
            () => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetCharacteristicsStoreState'
          ),
        addCharacteristic: (c: IAanvoerderPromotieKenmerk) =>
          set(
            (state: StoreState) => ({
              characteristics: [...state.characteristics, c],
            }),
            false,
            'addCharacteristic'
          ),
        removeCharacteristic: (id: number) =>
          set(
            (state: StoreState) => ({
              characteristics: [
                ...state.characteristics.filter(
                  c => c.aanvoerderPromotieKenmerkId !== id
                ),
              ],
            }),
            false,
            'removeCharacteristic'
          ),
        setCharacteristics: (cs: IAanvoerderPromotieKenmerk[]) =>
          set(
            () => ({
              characteristics: [...cs],
            }),
            false, // replace or not replace - that is the question!
            'setCharacteristics'
          ),
        setNumberOfCharacticeristics: (input: number) =>
          set(
            () => ({
              numberOfCharacticeristics: input,
            }),
            false,
            'setNumberOfCharacticeristics'
          ),
        setMustRefetchCharacteristics: (input: boolean) =>
          set(
            () => ({
              mustRefetchCharacteristics: input,
            }),
            false,
            'setMustRefetchCharacteristics'
          ),
        setFilter: (input: string) =>
          set(
            () => ({
              filter: input,
            }),
            false,
            'setFilter'
          ),
        setPagination: (input: GridPaginationInitialState) =>
          set(
            () => ({
              pagination: input,
            }),
            false,
            'setPagination'
          ),
        setSorting: (input: GridSortModel) =>
          set(
            () => ({
              sorting: input,
            }),
            false,
            'setSorting'
          ),
      }),
      {
        name: 'product-clusters-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
