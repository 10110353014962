import { ChangeEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material'
import TextField from '@rfh/ui/components/Inputs/TextField'
import { Button } from '@rfh/ui/components/RfhButton'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'
import { SearchIcon } from '@rfh/ui/shared/floriday-icons'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { ThemeConfig } from 'src/common/config'
import { fromProductGroupToItem } from 'src/common/lib'
import { IClient, IProductgroep } from 'src/common/services/client'
import { getProductGroupsAsync } from 'src/common/services/productGroupService'
import { Item } from 'src/common/types'
import { snackbarUtils } from 'src/common/utils'

import { Divider } from '../Divider'
import LookupGrid from '../LookupDialog/LookupGrid'

type AvailableProductGroupsDialogProps = {
  client: IClient
  onChange: (productGroup: IProductgroep) => void
}

export default function AvailableProductGroupsDialog({
  client,
  onChange,
}: Readonly<AvailableProductGroupsDialogProps>): JSX.Element {
  /*
   * Hooks
   */
  const { t } = useTranslation()
  const { handleSubmit } = useForm()
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [productGroups, setProductGroups] = useState<IProductgroep[]>([])
  const [filterString, setFilterString] = useState<string>('')

  /*
   * Methods
   */
  const openDialog = () => {
    setFilterString('')
    setOpen(true)
    fetchData()
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const itemSelected = (evt: Item<number>) => {
    const selectedProductgroepCode = evt.code
    const productGroup: IProductgroep = {
      productgroepCode: selectedProductgroepCode,
      productgroepNaam: productGroups.find(
        pg => pg.productgroepCode === selectedProductgroepCode
      ).productgroepNaam,
    }
    onChange(productGroup)
    closeDialog()
  }

  const handleUserKeyPress = (evt: any) => {
    if (evt.key === 'Enter' && !evt.shiftKey) {
      evt.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = () => {
    fetchData()
  }

  const handleFilterProductGroupName = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setFilterString(event.target.value)
  }

  const fetchData = async () => {
    if (inProgress) {
      return Promise.resolve()
    }

    try {
      setInProgress(true)

      const filter = filterString
        ? `contains(productgroepnaam, '${filterString}')`
        : undefined

      const records = await getProductGroupsAsync(client, {
        filter,
      })
      setProductGroups(records)
    } catch (error: any) {
      snackbarUtils.error(String(error))
    } finally {
      setInProgress(false)
    }
  }

  /**
   * Render
   */
  return (
    <>
      <IconButton onClick={openDialog} size='large'>
        <SearchIcon sx={{ color: RfhColors.darkOrange, height: 20, py: 0 }} />
      </IconButton>
      <Dialog open={open} onClose={closeDialog}>
        <DialogContent
          sx={{
            width: '600px',
            maxWidth: '600px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              left: '1.5em',
              right: '2em',
              clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
              backgroundColor: 'white',
              paddingTop: '2em',
              zIndex: 1,
            }}
          >
            <Typography style={{ marginBottom: '16px' }} variant={'h5'}>
              {t('exceptions.addExceptions.searchProductGroups')}
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <TextField
                autoFocus
                fieldColor='dark'
                fullWidth
                id='productGroupName'
                name='productGroupName'
                onChange={handleFilterProductGroupName}
                onKeyPress={handleUserKeyPress}
                placeholder={t('exceptions.addExceptions.productGroupName')}
              />
              <Button
                variant='block--contained'
                onClick={handleSubmit(onSubmit)}
                isLoading={inProgress}
              >
                {t('common.search')}
              </Button>
            </form>
            <Divider
              {...{
                marginTop: '1em',
                marginBottom: 0,
              }}
            />
          </Box>
          {/*GRID WITH LOOKUP DATA*/}
          <LookupGrid
            items={productGroups.map(fromProductGroupToItem)}
            selectItem={itemSelected}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant='text--underlined'
            onClick={closeDialog}
            sx={{
              marginBottom: ThemeConfig.spacing.xs * 2,
              marginRight: ThemeConfig.spacing.m + ThemeConfig.spacing.xs,
            }}
          >
            {t('common.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
