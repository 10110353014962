import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomDataGrid } from 'src/common/components'
import { Entity, useSearchCounts } from 'src/common/hooks'

import { getStaticColumns, initialState } from '../constants'
import {
  byClockSelection,
  byKlokVeilgroep,
  byLadingdragerType,
  byLocationCodeClockName,
  getConcernLocationClocks,
  toAuctionGroupCount,
} from '../lib'
import { useFilterStore } from '../stores'

import { v4 as getRandomID } from 'uuid'

export default function AuctionGroupCountsGrid(): JSX.Element {
  const CACHE_KEY = 'auction-group-counts-datagrid'
  const { i18n } = useTranslation()
  const {
    clockPool,
    filter,
    getLocationClocks,
    locationConcernClocks,
    setLocationConcernClocks,
  } = useFilterStore()

  const {
    data: { records: stats },
    isFetching,
    refetch,
  } = useSearchCounts(filter)

  const getFilteredCounts = useCallback(
    (reports: (typeof Entity.AuctionGroupCount)[]) => {
      const counts = reports
        .filter(byLadingdragerType(filter.trolleyType))
        .filter(
          byClockSelection(
            filter.clockNumberFrom,
            filter.clockNumberTo,
            getLocationClocks()
          )
        )
      counts.sort(byKlokVeilgroep(getLocationClocks()))
      return counts.map(toAuctionGroupCount)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filter.trolleyType,
      filter.clockNumberFrom,
      filter.clockNumberTo,
      locationConcernClocks,
    ]
  )

  const rows = useMemo(
    () => getFilteredCounts(stats),
    [getFilteredCounts, stats]
  )
  const totalInStoreCount = rows.length

  const columns = useMemo(
    () => getStaticColumns(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getStaticColumns, i18n.language]
  )

  useEffect(() => {
    const refetchId = setInterval(() => {
      refetch() // every minute
    }, 60000)

    return () => clearInterval(refetchId) // Clear the interval when the component is unmounted
  }, [refetch])

  useEffect(() => {
    const concernClocks = getConcernLocationClocks(
      filter.locationCode,
      stats,
      clockPool
    )
    concernClocks.sort(byLocationCodeClockName)
    setLocationConcernClocks(concernClocks)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.locationCode, stats, setLocationConcernClocks, clockPool])

  return (
    <CustomDataGrid
      cacheKey={CACHE_KEY}
      columns={columns}
      disableAggregation={false}
      getRowId={() => getRandomID()}
      gridProps={{
        headerHeight: 80,
        experimentalFeatures: { aggregation: true },
        rowCount: totalInStoreCount,
        pagination: false,
        paginationMode: 'client',
        sortingMode: 'client',
        loading: isFetching,
        sx: {
          '& .MuiDataGrid-aggregationColumnHeader .MuiDataGrid-aggregationColumnHeaderLabel':
            {
              display: 'none',
            },
        },
      }}
      initialState={initialState}
      language={i18n.language}
      rows={isFetching ? [] : rows}
      sortingMode={'client'}
    />
  )
}
