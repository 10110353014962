/* eslint-disable max-len */

/* eslint-disable id-blacklist */
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Grid } from '@mui/material'
import {
  GridPaginationInitialState,
  GridSortModel,
} from '@mui/x-data-grid-premium'
import { RfhTypography as Typography } from '@rfh/ui'

import { Divider } from 'src/common/components'
import { Entity, useFetchMany } from 'src/common/hooks'
import { useUser } from 'src/common/providers/UserProvider'
import {
  getDefaultPagination,
  getDefaultSortModel,
  getOrderBy,
  getSkip,
  getTop,
} from 'src/common/utils'

import { OverviewsFilter, ProductRegularityGrid } from './components'
import { defaultSortModel } from './lib'

export default function ProductRegularity(): JSX.Element {
  const CACHE_KEY = 'product-regularity-datagrid'
  const { t } = useTranslation()
  const user = useUser()
  const [filterString, setFilterString] = useState<string | undefined>(
    undefined
  )
  const [paginationState, setPaginationState] =
    useState<GridPaginationInitialState>(() => getDefaultPagination(CACHE_KEY))
  const [sortModel, setSortModel] = useState<GridSortModel>(() =>
    getDefaultSortModel(CACHE_KEY, defaultSortModel)
  )
  const changeSortModel = (value: GridSortModel) => {
    setSortModel(value?.length > 0 ? value : defaultSortModel)
  }

  const updateFilterString = useCallback(
    (value?: string) => {
      setPaginationState({
        ...getDefaultPagination(CACHE_KEY),
        paginationModel: { page: 0 },
      })
      setFilterString(value ?? undefined)
    },
    [setFilterString, setPaginationState]
  )

  const {
    data: regularityData,
    isLoading,
    refetch,
  } = useFetchMany<typeof Entity.ProductRegelmatig>(
    {
      top: getTop(paginationState),
      skip: getSkip(paginationState),
      filter: filterString,
      count: true,
      orderby: getOrderBy(sortModel),
    },
    Entity.ProductRegelmatig
  )

  const resetSearch = useCallback(() => {
    setFilterString(undefined)
    setPaginationState({
      ...getDefaultPagination(CACHE_KEY),
      paginationModel: { page: 0 },
    })
    setSortModel(defaultSortModel)
  }, [setPaginationState, setFilterString])

  useEffect(() => {
    setPaginationState(getDefaultPagination(CACHE_KEY))
  }, [])

  return (
    <Container maxWidth='xl'>
      <Grid item xs={12} mt={4} mb={2}>
        <Typography variant={'h3'} sx={{ fontWeight: 'bold' }}>
          {user.isAuctionCoordinator
            ? t('common.titles.overviewsProductRegularity')
            : t('common.titles.overviewsProductRegularityReadOnly')}
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <OverviewsFilter
          changeFilterString={updateFilterString}
          resetSearch={resetSearch}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider {...{ marginTop: 16 }} />
      </Grid>
      <Grid item sm={12}>
        <ProductRegularityGrid
          cacheKey={CACHE_KEY}
          rows={regularityData?.records ?? []}
          isLoading={isLoading}
          paginationStateChange={setPaginationState}
          sortModelChange={changeSortModel}
          refresh={refetch}
          rowCount={regularityData?.count ?? 0}
        />
      </Grid>
    </Container>
  )
}
