import { FC, useCallback, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import {
  GridPaginationInitialState,
  GridSortModel,
} from '@mui/x-data-grid-premium'
import { RfhTypography as Typography } from '@rfh/ui/components/RfhTypography'

import { useStore } from 'zustand'

import { Divider } from 'src/common/components'
import { DEFAULT_PAGE_SIZE, MAX_NUMBER_COLS_SORTED } from 'src/common/constants'
import { IApiClientListFilter } from 'src/common/interfaces'
import { initialState, refreshReducer } from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { IClient } from 'src/common/services/client'
import { getOrderBy, snackbarUtils } from 'src/common/utils'
import {
  getDefaultPagination,
  getDefaultSortModel,
} from 'src/common/utils/DataGridFunctions'

import { RulesGrid } from '../components'
import { CACHE_KEY } from '../constants'
import { getAllAuctionGroupRulesAsync } from '../services'
import { useAuctionGroupRuleStore, useAuctionGroupRulesStore } from '../stores'

import isEqual from 'lodash/isEqual'

export const AuctionGroupRules: FC = () => {
  const { t } = useTranslation()
  const { apiClient } = useApiClient()
  const isAuctionCoordinator = useUser().isAuctionCoordinator
  const {
    mustRefetchRules,
    resetAuctionGroupRulesStoreState,
    rules,
    setAuctionGroupRules,
    setCount,
    setMustRefetchAuctionGroupRules,
  } = useAuctionGroupRulesStore()
  const resetAuctionGroupRuleStoreState = useStore(
    useAuctionGroupRuleStore,
    state => state.resetAuctionGroupRuleStoreState
  )
  const [state, dispatch] = useReducer(refreshReducer, initialState)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultSortModel: GridSortModel = [
    {
      sort: 'asc',
      field: 'veilgroepRegelNummer',
    },
  ]

  const [paginationState, setPaginationState] =
    useState<GridPaginationInitialState>(() => getDefaultPagination(CACHE_KEY))
  const [sortModel, setSortModel] = useState<GridSortModel>(
    getDefaultSortModel(CACHE_KEY, defaultSortModel)
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiClientFilter: Partial<IApiClientListFilter> = {
    skip:
      paginationState?.paginationModel?.page &&
      paginationState?.paginationModel?.pageSize
        ? paginationState.paginationModel.page *
          paginationState.paginationModel.pageSize
        : 0,
    top: paginationState?.paginationModel?.pageSize ?? DEFAULT_PAGE_SIZE,
    orderby:
      sortModel.length > 0
        ? getOrderBy(sortModel)
        : getOrderBy(defaultSortModel),
    count: true,
  }

  const changePaginationState = useCallback(
    (value: GridPaginationInitialState) => {
      if (isEqual(value, paginationState)) return
      setPaginationState(value)
      setMustRefetchAuctionGroupRules(true)
    },
    [paginationState, setMustRefetchAuctionGroupRules]
  )

  const changeLocalStorageSortingModel = useCallback(
    (value: GridSortModel) => {
      if (isEqual(value, sortModel)) return
      if (value?.length <= MAX_NUMBER_COLS_SORTED) {
        setSortModel(value?.length > 0 ? value : defaultSortModel)
        setMustRefetchAuctionGroupRules(true)
      } else {
        // To prevent abuse the maximum number of sorted columns is set to five in the backend
        // we catch this so we can show a friendlier message
        snackbarUtils.warning(t('overviews.maximumSorting'))
      }
    },
    [defaultSortModel, setMustRefetchAuctionGroupRules, sortModel, t]
  )

  const fetchData = useCallback(
    async (client: IClient) => {
      if (!(client && paginationState) || state.loading) {
        return Promise.resolve()
      }

      try {
        dispatch({ type: 'PENDING' })
        const result = await getAllAuctionGroupRulesAsync(
          client,
          apiClientFilter
        )
        if (result.records.length > 0) {
          setAuctionGroupRules(result.records)
          setCount(result.count)
        }
      } catch (error: any) {
        snackbarUtils.error(String(error))
      } finally {
        dispatch({ type: 'RESOLVED' })
      }
    },
    [
      apiClientFilter,
      paginationState,
      setAuctionGroupRules,
      setCount,
      state.loading,
    ]
  )

  useEffect(() => {
    if (apiClient && mustRefetchRules) {
      fetchData(apiClient)
      setMustRefetchAuctionGroupRules(false)
    }
  }, [apiClient, fetchData, mustRefetchRules, setMustRefetchAuctionGroupRules])

  useEffect(() => {
    resetAuctionGroupRuleStoreState()
    if (rules?.length === 0) {
      resetAuctionGroupRulesStoreState()
      setMustRefetchAuctionGroupRules(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container maxWidth='xl'>
      <Box mt={4} mb={2}>
        <Typography variant={'h3'} style={{ fontWeight: 'bold' }}>
          {isAuctionCoordinator
            ? t('auctionRule.title.manage')
            : t('common.titles.searchAuctionGroupRules')}
        </Typography>
      </Box>
      <Divider {...{ marginTop: 16 }} />
      <Grid item xs={12}>
        <RulesGrid
          loading={state.loading}
          setPagination={changePaginationState}
          setSortModel={changeLocalStorageSortingModel}
          readonly={!isAuctionCoordinator}
        />
      </Grid>
    </Container>
  )
}
