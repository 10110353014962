/* eslint-disable max-len */
import {
  IClient,
  IProductclusterView,
  IProductgroepenReport,
  Productcluster,
  _expand,
} from 'src/common/services/client'
import { IClientExtra } from 'src/common/services/clientExtra'
import type { BloemenPlanten } from 'src/common/types'
import snackbarUtils from 'src/common/utils/snackbar/SnackbarUtils'

/*
 * PRODUCTCLUSTERS
 */

/**
 * getClustersAsync returns all clusters both for bloemen and planten
 * @param apiClient
 * @returns Promise<IProductclusterView[]>
 */
export const getClustersAsync = async (
  apiClient: IClient
): Promise<IProductclusterView[]> => {
  const isMock =
    process.env.REACT_APP_MOCK_API === 'true' &&
    process.env.NODE_ENV === 'development'

  const result: any =
    await apiClient.productclusterViews_ProductclusterView_ListProductclusterView(
      300,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
      undefined
    )
  const count = isMock ? result?.count ?? 0 : result?.['@odata.count'] ?? 0
  const records: IProductclusterView[] = isMock
    ? result?.records || []
    : result?.cleanedResponse?.value || []

  if (count > 300) {
    snackbarUtils.warning(
      'Er zijn er meer dan 300 productclusters. Waarschuw de IT-afdeling'
    )
  }

  return records || []
}

export const getClusterAsync = async (
  apiClient: IClient,
  key?: number
): Promise<IProductclusterView> => {
  const isMock =
    process.env.REACT_APP_MOCK_API === 'true' &&
    process.env.NODE_ENV === 'development'

  const result =
    (await apiClient.productclusterViews_ProductclusterView_GetProductclusterView(
      key,
      undefined,
      ['productclusterProductgroepen' as _expand]
    )) as any

  const record: IProductclusterView = isMock
    ? result?.record
    : result?.cleanedResponse || null

  return record
}

export const addClusterAsync = async (
  apiClient: IClient,
  body: Productcluster
): Promise<IProductclusterView> => {
  const isMock =
    process.env.REACT_APP_MOCK_API === 'true' &&
    process.env.NODE_ENV === 'development'

  const result: any =
    await apiClient.productclusters_Productcluster_CreateProductcluster(body)
  const cleanedResult: IProductclusterView = isMock
    ? result
    : result?.cleanedResponse
  return cleanedResult
}

export const updateClusterAsync = async (
  apiClient: IClientExtra,
  productcluster: Productcluster
): Promise<void> => {
  const version = '1'
  await apiClient.updateProductcluster(version, { productcluster })
}

export const deleteClusterAsync = async (
  apiClient: IClient,
  idToBeDeleted: number
): Promise<void> => {
  await apiClient.productclusters_Productcluster_DeleteProductcluster2(
    idToBeDeleted,
    '*' //Fix: IF-Match header Bug, alle resources mogen nu matchen.
  )
}

/*
 * PRODUCTGROEPEN
 */

export const getAllowedProductGroupsAsync = async (
  apiClient: IClient,
  bloemenPlanten: BloemenPlanten
): Promise<IProductgroepenReport[]> => {
  const isMock =
    process.env.REACT_APP_MOCK_API === 'true' &&
    process.env.NODE_ENV === 'development'

  const result = (await apiClient.productclusters_GetToegestaneProductgroepen(
    `'${bloemenPlanten}'` // required for the OData endpoint
  )) as any
  const records: IProductgroepenReport[] = isMock
    ? result?.records
    : result?.cleanedResponse?.value

  return records || ([] as IProductgroepenReport[])
}

export const magProductgroepWordenVerwijderd = async (
  apiClient: IClient,
  productclusterId: number,
  productgroepCode: number
): Promise<string[]> => {
  const isMock =
    process.env.REACT_APP_MOCK_API === 'true' &&
    process.env.NODE_ENV === 'development'

  const result =
    (await apiClient.productclusters_MagProductgroepWordenVerwijderd(
      productclusterId,
      productgroepCode
    )) as any
  const records: string[] = isMock
    ? result?.records
    : result?.cleanedResponse?.value

  return records || []
}
