import { CSSProperties } from 'react'

import { DIVIDER_STYLE } from '../constants'

export const Divider = (props: CSSProperties): JSX.Element => {
  const hrStyle = {
    borderLeft: DIVIDER_STYLE,
    borderRight: DIVIDER_STYLE,
    borderTop: DIVIDER_STYLE,
    borderBottom: DIVIDER_STYLE,
    width: '100%',
  }

  return <hr style={{ ...hrStyle, ...props }} />
}
