import { SxProps } from '@mui/material'

import { ThemeConfig } from '../config'
import { PageSize } from '../enums'
import { LocationType } from '../types'

export const MAX_NUMBER_COLS_SORTED = 5 as const
export const DEFAULT_PAGE_SIZE = 10 as const
export const NO_DATA_ROWS = 1 as const
export const DEFAULT_TAKE = 100 as const
export const ADD_PATH = 'add' as const
export const DIVIDER_STYLE = '1px solid rgba(220, 220, 220, 1)' as const
export const BUTTONS_GAP = 0.75 as const
export const ELEMENT_HEIGHT = ThemeConfig.spacing.xl * 4.8
export const GRID_MARGIN_TOP = ThemeConfig.spacing.xs * 4
export const MAX_DATE = new Date('2099-12-31')
export const ROW_PADDING = 0.8 as const
export const TABLE_CELL_STYLE: SxProps = {
  fontWeight: '700 !important',
  paddingY: ROW_PADDING,
  textAlign: 'left',
  verticalAlign: 'top',
} as const

export const rowsPerPageOptions = [
  PageSize.sm,
  PageSize.md,
  PageSize.lg,
  PageSize.xl,
]
export const MONDAY = 1
export const FRIDAY = 5
export const HttpCode = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
}
export const MAX_VALID_VALUE = 99999990 as const
export const gridHeaderStyles = {
  '& .MuiDataGrid-columnHeaders': {
    maxHeight: '6rem !important',
  },
  '& .MuiDataGrid-columnHeadersInner': {
    paddingBottom: '0.5rem',
    paddingTop: '0.25rem',
  },
  '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    flexDirection: 'row',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    // marginBottom: '0.25rem',
    paddingBottom: '0.25rem',
    lineHeight: 1.2,
    textAlign: 'center',
    textOverflow: 'clip',
    whiteSpace: 'break-spaces',
  },
  '& .MuiDataGrid-toolbarContainer': {
    gap: 0,
  },
}

export const allLocations: LocationType[] = [
  { code: 1, name: 'Aalsmeer' },
  { code: 2, name: 'Naaldwijk' },
  { code: 3, name: 'Rijnsburg' },
  { code: 7, name: 'Eelde' },
  { code: 24, name: 'Rhein-Maas' },
  { code: 25, name: 'RFH Concern' },
]
